import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Cookies from 'js-cookie';
import {Redirect,BrowserRouter, Route, Link,withRouter,HashRouter } from "react-router-dom";
import Cases from './cases';
import Dashboard from './dashboard';
import CaseDetails from './casedetails';
import Report from './report';
import TelephonyReport from './telephonyReports';
import TelephonyReportMonthly from './telephonyReportsMonthly';
import rsaReports from './rsaReports';
import rsaReportsMonthly from './rsaReportsMonthly';
import incidentLocationReports from './incidentLocationReports';
import incidentLocationReportsMonthly from './incidentLocationReportsMonthly';
import towingDestinationReports from './TowingDestinationReports';
import towingDestinationReportsMonthly from './TowingDestinationReportsMonthly';
// test pipeline
// test pipeline
// test pipeline

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        
    };
    
  }
  componentDidMount() {
    const usercookie = Cookies.get('user');   
      if (!usercookie) {
        let text = window.location.href;
        let parts = text.split("/");
        let len = parts.length;
        if (len > 2 && text.includes("/cases/")) {
            if (!isNaN(parts[len - 1])) {
                console.log("Checking clipboard redirect", "Found case detail path: " + `${parts[len - 2]}/${parts[len - 1]}`);
                window.localStorage.setItem("caseDetailRedirectUrl", text);
            }
        }
      
        window.location.href="/login"                
    }    
  }
  render() {
    
    return (
      <>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/cases" component={Cases} />
        <Route exact path="/cases/:id" component={CaseDetails} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/telephony-report" component={TelephonyReport} />
        <Route exact path="/telephony-report-monthly" component={TelephonyReportMonthly} />
        <Route exact path="/rsa-report" component={rsaReports} />
        <Route exact path="/rsa-report-monthly" component={rsaReportsMonthly} />
        <Route exact path="/incidentLocation-report" component={incidentLocationReports} />
        <Route exact path="/incidentLocation-report-monthly" component={incidentLocationReportsMonthly} />
        <Route exact path="/towingDestination-report" component={towingDestinationReports} />
        <Route exact path="/towingDestination-report-monthly" component={towingDestinationReportsMonthly} />
      </>
  
  
    );
  }
}
/* const App = (props) => {
  useEffect(() => {
    const usercookie = Cookies.get('user');
    let userDescription = 'No user authenticated';
    if (usercookie) {
      const user = JSON.parse(usercookie);
      this.props.history.push(`/`)
      userDescription = `Welcome ${JSON.stringify(user.userInfo.name)}. User_id: ${user.userInfo.sub}`;
    }
    else {
      this.props.history.push(`/`)
    }
  }, []);
  return (
    <BrowserRouter>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/cases" component={Cases} />
      <Route exact path="/cases/:id" component={CaseDetails} />
      <Route exact path="/report" component={Report} />
      <Route exact path="/telephony-report" component={TelephonyReport} />
      <Route exact path="/telephony-report-monthly" component={TelephonyReportMonthly} />
      <Route exact path="/rsa-report" component={rsaReports} />
      <Route exact path="/rsa-report-monthly" component={rsaReportsMonthly} />
      <Route exact path="/incidentLocation-report" component={incidentLocationReports} />
      <Route exact path="/incidentLocation-report-monthly" component={incidentLocationReportsMonthly} />
      <Route exact path="/towingDestination-report" component={towingDestinationReports} />
      <Route exact path="/towingDestination-report-monthly" component={towingDestinationReportsMonthly} />
    </BrowserRouter>


  );

} */

export default App;
