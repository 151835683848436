import React from 'react';
import MyService from "./services/my-service";
import Moment from 'moment';
import { Line} from 'react-chartjs-2';

class SlaReport extends React.Component {
    constructor(props) {
        super(props)       
    }
    formatNumber(num) {
        let value = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (value === "NaN") {
            return 0;
        }
        return value;
    }
    render() {
        const serviceLevelTarget = (canvas) => {
            const ctx = canvas.getContext("2d")
             const gradient = ctx.createLinearGradient(0, 0, 0, 300);
             gradient.addColorStop(0, "rgba(10, 36, 143, 1)");
             gradient.addColorStop(0.05, "rgba(10, 36, 143, 1)");
             gradient.addColorStop(0.5, "rgba(207, 143, 223, 0.5)");
             gradient.addColorStop(1, "rgba(255, 255, 255, 0)");
   
             let labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
             let data = [];
             let currentMonth = Moment(new Date()).format("M");
             let currentYear = Moment(new Date()).format("YYYY");
             if (currentYear == this.props.selectedYear) {
                 let i = 0;
                 let startingPoint = 0; // null
                 for (i = 0; i < currentMonth; i++) {
                     let value = this.props.serviceLevelTarget[i];
                     let toUseValue = 0; // null
                     if (startingPoint === null && value > 0) {
                         startingPoint = value;
                     }
                     if (value > 0 || startingPoint !== null) {
                         toUseValue = value;
                     }
                     data.push(toUseValue);
                 }
             }
             else {
                 let i = 0;
                 let startingPoint = 0; // null
                 for (i = 0; i < 12; i++) {
                     let value = this.props.serviceLevelTarget[i];
                     let toUseValue = 0; // null
                     if (startingPoint === null && value > 0) {
                         startingPoint = value;
                     }
                     if (value > 0 || startingPoint !== null) {
                         toUseValue = value;
                     }
                     data.push(toUseValue);
                 }
             }
            return {
               labels: labels,
               datasets: [                  
                  {
                     label: "Compliance Rate",
                     borderColor: "#00008F",
                     fillColor: "rgba(220,220,220,0.2)",
                     strokeColor: "rgba(220,220,220,1)",
                     pointColor: "rgba(220,220,220,1)",
                     pointStrokeColor: "#fff",
                     pointHighlightFill: "#fff",
                     backgroundColor: gradient,
                     data: data
                  },
                  {
                     label: "Performance Target",
                      borderColor: "#3DD598",
                      backgroundColor: "#00000000",
                     data: this.props.targetPerformance
                  }
   
               ]
            }
         }
        return (
         <div class="modal-body">                              
            <div class="row">
                <div class="col-md-3 text-center">
                <div className="sla-logo-container">
                    {
                        this.props.partnerId == 'LIBERTY_MY' ?
                        <img className="sla-logo-image" style={{ width: "120px" }} src={MyService.getClientDetailFromCode(this.props.partnerId).logo} />
                        : 
                        <img className="sla-logo-image" style={{ width: "100px" }} src={MyService.getClientDetailFromCode(this.props.partnerId).logo} />
                                                       
                    }
                    
                    <div className="sla-partnerid-name">
                        {
                            MyService.getClientDetailFromCode(this.props.partnerId).name
                        }
                        <hr />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-8 text-left">
                        <div className="sla-total-calloffered">
                            Total # of Calls Offered
                        </div>
                        <div className="sla-varianty">
                            Variation of Y vs Y-1
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <hr className="ml-3" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <img class="img-fluid sla-call-height" src="/static/images/SLA_Call Answered.png"/>
                    </div>
                    <div class="col-md-8 text-left">
                        <div className="sla-call-title">
                            Calls Answered
                        </div>
                        <div className="sla-call-subtitle">
                        {
                            !this.props.toggle || !this.props.toggle.slaLabels || !this.props.toggle.slaLabels.ansBefore ? "Calls Answered in 20s" : this.props.toggle.slaLabels.ansBefore
                        }
                        </div>
                        <div className="sla-call-subtitle">
                        {
                            !this.props.toggle || !this.props.toggle.slaLabels || !this.props.toggle.slaLabels.ansAfter ? "Calls Answered after 20s" : this.props.toggle.slaLabels.ansAfter
                        }
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <hr className="ml-20px" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <img class="img-fluid sla-call-height" src="/static/images/SLA_Call Abandoned.png" />
                    </div>
                    <div class="col-md-8 text-left">
                        <div className="sla-call-title">
                            Calls Abandoned
                        </div>
                        <div className="sla-call-subtitle">
                        {
                            !this.props.toggle || !this.props.toggle.slaLabels || !this.props.toggle.slaLabels.abandBefore ? "Calls Abandoned in 20s" : this.props.toggle.slaLabels.abandBefore
                        }
                        </div>
                        {
                            this.props.toggle.callsAbandAfter20s ?
                            <div className="sla-call-subtitle">
                                {
                                    !this.props.toggle || !this.props.toggle.slaLabels || !this.props.toggle.slaLabels.abandAfter ? "Calls Abandoned after 20s" : this.props.toggle.slaLabels.abandAfter
                                }
                            </div>
                            : null 
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-3px">
                        <hr className="ml-20px" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 text-right">
                        <div className="mb-3 mr-3" >
                            <img class="img-fluid sla-small-icon" src="/static/images/Service_gray@3x.png"/>
                        </div>
                        <div className="mb-3 mr-3">
                            <img class="img-fluid sla-small-icon" src="/static/images/CallAbandoned_gray@3x.png" />
                        </div>

                    </div>
                    <div class="col-md-8 text-left">
                        <div className="mt-7px">
                            <div className="sla-call-subtitle mb-4">
                            Service Levels
                        </div>
                            <div className="sla-call-subtitle">
                            Abandon Rates
                        </div>
                        </div>
                    </div>
                </div>                                    
                </div>
                <div class="col-md-5">
                <div class="row text-left mlr">
                    <div className="sla-calloffered-title_current">
                        Calls Offered in {Moment(this.props.selectDate).format("YYYY")}
                    </div>
                    <div class="col-md-12">
                        <div class="row sla-calloffered-th_current">
                            <div class="col-md-4">
                            1st Jan - 31st Dec
                            </div>
                            <div class="col-md-4">
                            1st Jan - Today
                            </div>
                            <div class="col-md-4">
                            <nobr>Filtered Month <br />({Moment(this.props.selectDate).format("MMM")})</nobr>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-left mlr">
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallCurrentYear.totalCallsOffered))
                            }
                            </div>
                            {
                            this.props.wholeYearCallCurrentYear.totalCallsOffered > 0
                                ?
                                this.props.wholeYearCallLastYear.totalCallsOffered > 0 ?
                                    <div style={{ color: (this.props.wholeYearCallCurrentYear.totalCallsOffered - this.props.wholeYearCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12 font-medium">
                                        <span>
                                        {(((this.props.wholeYearCallCurrentYear.totalCallsOffered - this.props.wholeYearCallLastYear.totalCallsOffered) / this.props.wholeYearCallLastYear.totalCallsOffered) * 100).toFixed(1)}%
                                        {
                                            (this.props.wholeYearCallCurrentYear.totalCallsOffered - this.props.wholeYearCallLastYear.totalCallsOffered) < 0
                                                ?
                                                <img class="img-fluid sla-activity_image" src="/static/images/Activity_Down@3x.png"  />
                                                :
                                                <img class="img-fluid sla-activity_image" src="/static/images/Activity_Up@3x.png" />
                                        }
                                        </span>
                                    </div>
                                    :
                                    <div style={{ color: (this.props.wholeYearCallCurrentYear.totalCallsOffered - this.props.wholeYearCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598" }} className="Source-SansPro-Regular font-size-12" >

                                    </div>
                                :
                                <div style={{ color: (this.props.wholeYearCallCurrentYear.totalCallsOffered - this.props.wholeYearCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                    <span>
                                        0
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.totalCallsOffered))
                            }
                            </div>
                            {
                            this.props.untilNowCallCurrentYear.totalCallsOffered > 0
                                ?
                                this.props.untilNowCallLastYear.totalCallsOffered > 0 ?
                                    <div style={{ color: (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                        <span>
                                        <div style={{ color: (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                            <span>
                                                {(((this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) / this.props.untilNowCallLastYear.totalCallsOffered) * 100).toFixed(1)}%
                                                {
                                                    (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0
                                                    ?
                                                    <img class="img-fluid sla-activity_image" src="/static/images/Activity_Down@3x.png"  />
                                                    :
                                                    <img class="img-fluid sla-activity_image" src="/static/images/Activity_Up@3x.png" />
                                                }
                                            </span>
                                        </div>
                                        </span>
                                    </div>
                                    :
                                    <div style={{ color: (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12 font-medium">

                                    </div>
                                :
                                <div style={{ color: (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12 font-medium">
                                    <span>
                                        <div style={{ color: (this.props.untilNowCallCurrentYear.totalCallsOffered - this.props.untilNowCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12 font-medium">
                                        <span>
                                            0
                                        </span>
                                        </div>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.totalCallsOffered))
                            }
                            </div>
                            {
                            this.props.thisMonthCallCurrentYear.totalCallsOffered > 0
                                ?
                                this.props.thisMonthCallLastYear.totalCallsOffered > 0 ?
                                    <div style={{ color: (this.props.thisMonthCallCurrentYear.totalCallsOffered - this.props.thisMonthCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                        <span>
                                        {(((this.props.thisMonthCallCurrentYear.totalCallsOffered - this.props.thisMonthCallLastYear.totalCallsOffered) / this.props.thisMonthCallLastYear.totalCallsOffered) * 100).toFixed(1)}%
                                            {
                                            (this.props.thisMonthCallCurrentYear.totalCallsOffered - this.props.thisMonthCallLastYear.totalCallsOffered) < 0
                                                ?
                                                <img class="img-fluid sla-activity_image" src="/static/images/Activity_Down@3x.png" />
                                                :
                                                <img class="img-fluid sla-activity_image" src="/static/images/Activity_Up@3x.png" />
                                            }
                                        </span>
                                    </div>
                                    :
                                    <div style={{ color: (this.props.thisMonthCallCurrentYear.totalCallsOffered - this.props.thisMonthCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                        &nbsp;
                                    </div>
                                :
                                <div style={{ color: (this.props.thisMonthCallCurrentYear.totalCallsOffered - this.props.thisMonthCallLastYear.totalCallsOffered) < 0 ? "#FC4C4C" : "#3DD598"}} className="Source-SansPro-Regular font-size-12">
                                    <span >
                                    &nbsp;
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <hr />
                    </div>
                </div>
                <div class="row text-left mlr">
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt( this.props.wholeYearCallCurrentYear.callsAnswered))
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt( this.props.wholeYearCallCurrentYear.callsAnsweredIn20s)) 
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallCurrentYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAnswered))
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAnsweredIn20s))
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.callsAnswered))
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.callsAnsweredIn20s))
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-20px">
                        <hr />
                    </div>
                </div>
                <div class="row text-left mlr">
                    <div class="col-md-4">
                        <div className="mt-4px">                                             
                            {
                                this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt( this.props.wholeYearCallCurrentYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">                                                
                                &nbsp;                                                
                            </div>
                            }                                             
                            {
                                this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerin20s-content">
                                {
                                    this.formatNumber(parseInt( this.props.wholeYearCallCurrentYear.callsAbandonedIn20s))
                                }
                            </div>
                            :
                            <div className="sla-callabandonin20s-content">
                            {
                                this.formatNumber(parseInt( this.props.wholeYearCallCurrentYear.callsAbandonedIn20s))
                            }
                            </div>
                            }                                             
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                    this.formatNumber(parseInt(this.props.wholeYearCallCurrentYear.callsAbandonedAfter20s))
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            {
                                this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">
                            
                                &nbsp;
                            
                            </div>
                            }
                        {
                            this.props.toggle.callsAbandAfter20s ?
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAbandonedIn20s))
                            }
                        </div>
                        :
                        <div className="sla-callabandonin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAbandonedIn20s))
                            }
                            </div>
                        }                                             
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                        this.formatNumber(parseInt(this.props.untilNowCallCurrentYear.callsAbandonedAfter20s))
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            {
                                this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">
                            
                                &nbsp;
                            
                            </div>
                            }
                            {
                                this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerin20s-content">
                                {
                                this.formatNumber(parseInt( this.props.thisMonthCallCurrentYear.callsAbandonedIn20s))
                                }
                            </div>
                            :
                            <div className="sla-callabandonin20s-content">
                            {
                                this.formatNumber(parseInt( this.props.thisMonthCallCurrentYear.callsAbandonedIn20s))
                            }
                            </div>
                            }                                             
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                    this.formatNumber(parseInt(this.props.thisMonthCallCurrentYear.callsAbandonedAfter20s)) 
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-20px">
                        <hr />
                    </div>
                </div>
                <div class="row text-left mlr">
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.wholeYearCallCurrentYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.wholeYearCallCurrentYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.untilNowCallCurrentYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.untilNowCallCurrentYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.thisMonthCallCurrentYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.thisMonthCallCurrentYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="col-md-4 white-background-color">
                <div class="row text-left mlr-10">
                    <div className="sla-calloffered-title_last">
                        Calls Offered in {Moment(this.props.selectDate).subtract(1, "year").format("YYYY")}
                    </div>
                    <div class="col-md-12">
                        <div class="row sla-calloffered-th_last" >
                            <div class="col-md-4">
                            1st Jan - 31st Dec
                            </div>
                            <div class="col-md-4">
                            1st Jan - Today
                            </div>
                            <div class="col-md-4">
                            <nobr>Filtered Month <br />({Moment(this.props.selectDate).format("MMM")})</nobr>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-left ml-10px">
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content" className="sla-black-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallLastYear.totalCallsOffered)) 
                            }
                            </div>
                            <div className="sla-totalcalloffered-last">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content">
                            {
                                this.formatNumber(parseInt( this.props.untilNowCallLastYear.totalCallsOffered))
                            }
                            </div>
                            <div className="sla-totalcalloffered-last">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-18px">
                            <div className="sla-black-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.totalCallsOffered))
                            }
                            </div>
                            <div className="sla-totalcalloffered-last">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <hr />
                    </div>
                </div>
                <div class="row text-left ml-10px">
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAnswered))
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAnsweredIn20s))
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAnswered))
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAnsweredIn20s))
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAnswered)) 
                            }
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAnsweredIn20s))
                            }
                            </div>
                            <div className="sla-callanswerafter20s-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAnsweredAfter20s))
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-20px">
                        <hr />
                    </div>
                </div>
                <div class="row text-left ml-10px">
                    <div class="col-md-4">
                        <div className="mt-4px">
                            {
                            this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">                                             
                                &nbsp;                                                
                            </div>
                            }
                            {
                            this.props.toggle.callsAbandAfter20s ?
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAbandonedIn20s))
                            }
                            </div>
                            :<div className="sla-callabandonin20s-content">
                            {
                            this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAbandonedIn20s))
                            }
                            </div>
                            }                                                                                          
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                        this.formatNumber(parseInt(this.props.wholeYearCallLastYear.callsAbandonedAfter20s))
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            {
                            this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">                                                
                                &nbsp;                                                
                            </div>
                            }
                            {
                            this.props.toggle.callsAbandAfter20s ?
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAbandonedIn20s))
                            }
                            </div>
                            :
                            <div style={{ marginBottom: "1px", color: "#2B2D3D", fontFamily: "SourceSansPro-Regular", fontSize: "12px" }}>
                                {
                                    this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAbandonedIn20s))
                                }
                            </div>
                            }                                             
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                        this.formatNumber(parseInt(this.props.untilNowCallLastYear.callsAbandonedAfter20s))
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                        {
                            this.props.toggle.callsAband ?
                                <div className="sla-callanswer-content">
                                {
                                    this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAbandoned))
                                }
                                </div>
                                :
                                <div className="sla-callanswer-content">                                                
                                &nbsp;                                                
                            </div>
                            }
                        {
                            this.props.toggle.callsAbandAfter20s ?
                            <div className="sla-callanswerin20s-content">
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAbandonedIn20s))
                            }
                        </div>
                        :
                        <div style={{ marginBottom: "1px", color: "#2B2D3D", fontFamily: "SourceSansPro-Regular", fontSize: "12px" }}>
                            {
                                this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAbandonedIn20s))
                            }
                            </div>
                        }                                             
                            {
                            this.props.toggle.callsAbandAfter20s ?
                                <div className="sla-callanswerafter20s-content">
                                    {
                                        this.formatNumber(parseInt(this.props.thisMonthCallLastYear.callsAbandonedAfter20s))
                                    }
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center mt-20px">
                        <hr />
                    </div>
                </div>
                <div class="row text-left ml-10px">
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.wholeYearCallLastYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.wholeYearCallLastYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.untilNowCallLastYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.untilNowCallLastYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="mt-4px">
                            <div className="sla-callanswer-content">
                            {
                                this.props.thisMonthCallLastYear.serviceLevel
                            }%
                            </div>
                            <div className="sla-callanswerin20s-content">
                            {
                                this.props.thisMonthCallLastYear.abandonRates
                            }%
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-40px">
                <div class="card sla-chart">
                    <div class="card-body">
                        <div class="row" >
                            <div class="col-md-6 text-left sla-chart-title">
                                Number of Calls Offered Per Month
                            </div>
                            <div class="col-md-6 text-right sla-chart-indicator">
                            <span className="sla-chart-currentyear"> </span>  Current Year
                        </div>
                        </div>
                        {/* SLA Chart */}
                        <div class="chart mt-30px">
                            <Line
                            height={100}
                            data={this.props.dataNumberOfCall}
                            options={this.props.options}
                            />
                        </div>
                        {/*  */}
                    </div>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-20px">
                <div class="card sla-chart">
                    <div class="card-body">
                        <div class="row mb-10px">
                            <div class="col-md-6 text-left sla-chart-title">
                                Service Level
                            </div>
                            <div class="col-md-2 text-left">
                            </div>
                            <div class="col-md-2 text-left sla-chart-indicator">
                            <span className="sla-currentyear-indicator"> </span>   Current Year
                            </div>
                            <div class="col-md-2 text-right sla-chart-indicator">
                                <span className="sla-target-indicator"> </span>   Target Performance
                            </div>
                        </div>
                        {/* SLA Chart */}
                        <div class="chart mt-30px">
                            <Line
                            height={100}
                            data={serviceLevelTarget}
                            options={this.props.optionsSla}
                            />
                        </div>
                        {/*  */}
                    </div>
                </div>
                </div>
            </div>                              
        </div>
        )
    }
}


export default SlaReport

