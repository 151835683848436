
import React from 'react';
import Layout from './layout';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import MyService from "./services/my-service";
import { Carousel } from 'react-responsive-carousel';
import 'font-awesome/css/font-awesome.min.css';
import Cookies from 'js-cookie';
import TopNavigation from "./topnavigation";
import BreadCrumbs from "./breadcrumbs";

const DashLine = {
    margin: '5px 0',
    height: '2px',
    background: 'repeating-linear-gradient(to right,gray 0,gray 10px,transparent 10px,transparent 12px)'
};

const DivDashLine = () => (
    <div style={DashLine}></div>
);

class CaseDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hideDropDownMenu: true,
            hideDropDownMenuReport: true,
            name: "claims",
            subid: "",
            avatarLetter: "",
            role: "",
            profile: {},
            isOpenLogout: false,
            isDownload: false,
            claimDetails: {},
            papeSize: 10,
            currentPage: 1 || props.router.query.page,
            pageCount: [
                {}
            ],
            photoUrl: [],
            documents: [],
            isOpenPhotos: false,
            activeButton: false,
            selectedBox: false,
            services: {},
            cause: {},
            current_location: {},
            customer: {},
            passengers: {},
            policy_holder: {},
            status: {},
            vehicle: {},
            destinationAddress: {},
            contact_phone: {},
            authozied: false,
            isLoading: false,
            reload: false,
            selectedSlide: 0,
            partnerId: "",
            partnerId1: "",
            partnerIds: [],
            partnerIndex: 0,
            dataError: false,
            loadingImages: false,
            carProblem: "",
            assistanceLocation: "",
            serviceArrivalDate: "",
            providerActivationDate: "",
            serviceProvided: "",
            workshopLocation: "",
            serviceArrivalTime: "",
            providerActivationTime: "",
            overall: "",
            tatArrival: "",
            tatMoreThan45Min: "",
            tatMoreThan45MinReason: "",
            howLikely: "",
            feedback: ""
        }
        this.setWrapperRefLogOut = this.setWrapperRefLogOut.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRefLogOut(node) {
        this.wrapperRefLogOut = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRefLogOut && !this.wrapperRefLogOut.contains(event.target)) {
            this.setState({
                hideDropDownMenu: true
            })
        }
    }

    hideDropDownLogout() {
        if (this.state.hideDropDownMenu) {
            this.setState({ hideDropDownMenu: false })
        }
        else {
            this.setState({ hideDropDownMenu: true })
        }
    }

    hideDropDownReport() {
        if (this.state.hideDropDownMenuReport) {
            this.setState({ hideDropDownMenuReport: false })
        }
        else {
            this.setState({ hideDropDownMenuReport: true })
        }
    }

    toggleModalLogOut() {
        let isOpenLogout = !this.state.isOpenLogout
        this.setState({
            isOpenLogout: isOpenLogout
        })
    }

    findIndex(arr, text) {
        let index = 0;
        for (let i = 0; i < arr.length; i++) {
            if (text == arr[i]) {
                index = i;
            }
        }
        return index;
    }

    checkForInvalidSession(data) {
        if (data.message === "invalid_user_session" || data.error_code === "invalid_user_session" || data.error_code === "invalid_refresh_token") {
            MyService.logoutForSession();
        }
    }

    getImageUrls(case_id) {
        this.setState({ loadingImages: true });
        const url = `${window.location.origin}/api/cases/${case_id}/attachments/generate_public_urls`;
        const options = MyService.getHeader("POST", this.state.partnerIndex, false);
        fetch(url, options)
            .then(response => response.json())
            .then(
                data => {
                    const urls = JSON.stringify(data);
                    let photoUrl = [];
                    if (Array.isArray(data)) {
                        photoUrl = data;
                    } else {
                        photoUrl = [];
                    }
                    this.setState({ photoUrl: photoUrl, loadingImages: false });
                    this.checkForInvalidSession(data);
                }).catch(error => {
                    if (error.response) {
                        console.log('Error', error.message);
                    }
                    this.setState({ loadingImages: false })
                });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const usercookie = Cookies.get('user');
        const user = JSON.parse(usercookie);
        console.log("User", user)
        let name = user.userInfo.name;
        let avatarLetter = name.substring(0, 1).toUpperCase();
        this.state.subid = this.props.match.params.id
        if (!user.userInfo["https://asia-assistance.com/partnerIds"] || user.userInfo["https://asia-assistance.com/partnerIds"] === null) {
            user.userInfo["https://asia-assistance.com/partnerIds"] = [user.userInfo["https://asia-assistance.com/partnerId"]];
        }
        this.setState({ partnerIds: user.userInfo["https://asia-assistance.com/partnerIds"], avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
        if (usercookie) {
            let partner = {};
            partner = JSON.parse(window.localStorage.getItem("partner"));
            if (partner != null) {
                this.state.partnerIndex = partner.partnerIndex;
                this.state.partnerId = partner.partnerId
                this.state.partnerId1 = partner.partnerId1
            }
            this.setState({ documents: [{ documentName: "FNOL" }], reload: true })
            this.callCaseDetailsApi(this.props.match.params.id)        
        }
        else {
            window.location.href = "/login"
        }
    }

    callCaseDetailsApi(case_id) {
        this.setState({ isLoading: true })
        const url = `${window.location.origin}/api/cases/${case_id}`;
        const options = MyService.getHeader("GET", this.state.partnerIndex, false);
        fetch(url, options)
            .then(response => response.json())
            .then(
                data => {
                    const casedetails = JSON.stringify(data);
                    if (data.error == "Internal Server Error") {
                        this.setState({ isLoading: false, dataError: true })
                    }
                    else {
                        this.checkForInvalidSession(data);
                        this.setState({ isLoading: false, dataError: false, isZurich: data.isZurich, isPorsche: data.isPorsche, authozied: data.services[0].destination.is_approved_by_partner, contact_phone: data.customer.contact_phone, destinationAddress: data.services[0].destination.address, cause: data.cause, policy_holder: data.policy_holder, status: data.status, vehicle: data.vehicle, passengers: data.passengers, customer: data.customer, current_location: data.current_location.address, claimDetails: data, services: data.services[0], howLikely: data.howLikely, feedback: data.feedback, quality : data.quality, reason: data.reason, qcStat: data.qcStat, voiceMessage: data.voiceMessage, notApplicable: data.notApplicable, smsSurveySent: data.SmsSurveySent, closed:data.closed, replacementCarDays: data.replacementCarDays, replacementCarBrand: data.replacementCarBrand, replacementCarType: data.replacementCarType, serviceArray: data.serviceProvidedList })
                    }
                }).catch(error => {
                    if (error.response) {
                        console.log('Error', error.message);
                    }
                    this.setState({ loading: false })
                }).finally(() => {
                    this.getImageUrls(this.props.match.params.id);                   
                });


    }

    downloadBinary(response, filename) {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response]))
        a.download = filename
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }

    downloadFiles(case_id) {
        this.setState({ isDownload: true })
        const url = `${window.location.origin}/api/reports/${case_id}`;
        const options = MyService.getHeader("GET", this.state.partnerIndex, false);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.blob()
                    this.checkForInvalidSession(data);
                    let filename = response.headers.get('content-disposition').split("; ")
                    let filename1 = filename[1].split("=")
                    let name = filename1[1];
                    name = name.replaceAll('"', '');
                    this.downloadBinary(data, name)
                    this.setState({ isDownload: false })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ isDownload: false })
            });
    }

    toggleModalPhotos() {
        let isOpenPhotos = !this.state.isOpenPhotos
        this.setState({
            isOpenPhotos: isOpenPhotos
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.reload ?
                        <>
                            {/* Modal */}
                            <Modal
                                centered
                                size="lg"
                                isOpen={this.state.isOpenPhotos}
                                toggle={() => this.toggleModalPhotos()}>
                                <ModalHeader
                                    toggle={() => this.toggleModalPhotos()}>
                                    <div className="modal-title modal-title-photos"></div>
                                </ModalHeader>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-2">
                                        </div>
                                        <div class="col-md-8">
                                            {
                                                this.state.photoUrl.length > 0 ? <Carousel selectedItem={this.state.selectedSlide || 0} >
                                                    {
                                                        this.state.photoUrl.map((ev, index) => {
                                                            return (
                                                                <div>
                                                                    <img className="fit_image_container" src={ev.attachment_url} alt="Photo" />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </Carousel> : null
                                            }

                                        </div>
                                        <div class="col-md-2">
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Layout title="Claim Details" name="claims" subid={this.props.match.params.id}>
                                {/* Nav */}
                                <div class="fixed-top">
                                    <TopNavigation
                                        name="claims"
                                        hidesubId={true}
                                        profile={this.state.profile}
                                        partnerIds={this.state.partnerIds}
                                        partnerId={this.state.partnerId}
                                        partnerId1={this.state.partnerId1}
                                        history={this.props.history}
                                        role={this.state.role}
                                        avatarLetter={this.state.avatarLetter}
                                        switchPartnerId={() => { }}
                                        hideDropDownMenu={this.state.hideDropDownMenu}
                                        hideDropDownMenuReport={this.state.hideDropDownMenuReport}
                                        setWrapperRefLogOut={this.setWrapperRefLogOut}
                                        hideDropDownLogout={() => this.hideDropDownLogout()}
                                        hideDropDownReport={() => this.hideDropDownReport()}
                                    />
                                    {/*  */}
                                    <BreadCrumbs name={this.state.name} subid={this.state.subid} history={this.props.history} />
                                </div>
                                {
                                    this.state.isLoading ?
                                        <div class="row loader height-100"></div>
                                        :
                                        this.state.dataError ?
                                            <div class="row empty-section">
                                                <div class="col-md-12 text-center">
                                                    <div className="br-22px">
                                                        <div class="text-center case-main-infor">
                                                            Case not found.
                                                        </div>
                                                        <div class="text-center case-sub-info">
                                                            The case you are looking is not found.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div class="row mb-30px">
                                                <div class="col-md-1 text-left">
                                                    <div class="card shadow-none height-400px" >
                                                    </div>
                                                </div>
                                                <div class="col-md-10 text-left">
                                                    <div class="row">
                                                        <div class="col-md-6 text-left">
                                                            <div className="casedetails-client-container">
                                                                <span className="casedetails-logoname">
                                                                    {
                                                                        this.state.partnerId == 'LIBERTY_MY' ?
                                                                            <img src={MyService.getClientDetailFromCode(this.state.partnerId).logo} style={{ width: "50px" }} className="icon-size32" />
                                                                        :
                                                                            null
                                                                    }
                                                                    
                                                                    {
                                                                        this.state.partnerId == 'PERODUA' ?
                                                                            <img src={MyService.getClientDetailFromCode(this.state.partnerId).logo} style={{ height: "50px", width: "80px" }} className="icon-size32" />
                                                                        :
                                                                            null
                                                                    }
                                                                    
                                                                    {
                                                                        this.state.partnerId != 'PERODUA' && this.state.partnerId != 'LIBERTY_MY' ?
                                                                            <img src={MyService.getClientDetailFromCode(this.state.partnerId).logo} className="icon-size32" />
                                                                        :
                                                                            null
                                                                    }
                                                                    <span className="casedetails-clientname">
                                                                        {
                                                                            MyService.getClientDetailFromCode(this.state.partnerId).name
                                                                        }
                                                                    </span>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;<span className="casedetails-program">
                                                                    {
                                                                        MyService.getClientDetailFromCode(this.state.partnerId).program
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <div className="casedetails-status-container">
                                                                <span className="casedetails-status">
                                                                    {
                                                                        this.state.status.code == "OPEN" ?
                                                                            <span className="Source-SansPro-Bold font-size-12" style={{ color: "#3DD598" }}>
                                                                                <img src="/static/images/AssetsAA2/CaseStatus_Open@3x.png" className="casedetails-status-icon" />
                                                                                {this.state.status.code}
                                                                            </span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.status.code == "CLOSED" ?
                                                                            <span className="Source-SansPro-Bold font-size-12" style={{ color: "#0A248F" }}>
                                                                                <img src="/static/images/AssetsAA2/CaseStatus_Closed@3x.png" className="casedetails-status-icon" />
                                                                                {this.state.status.code}
                                                                            </span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.status.code == "CANCELLED" ?
                                                                            <span className="Source-SansPro-Bold font-size-12" style={{ color: "#FC5A5A" }}>
                                                                                <img src="/static/images/AssetsAA2/CaseStatus_Canceled@3x.png" className="casedetails-status-icon" />
                                                                                {this.state.status.code}
                                                                            </span>
                                                                            : null
                                                                    }
                                                                    {
                                                                        this.state.status.code == "UNVERIFIED" ?
                                                                            <span className="Source-SansPro-Bold font-size-12" style={{ color: "#FF974A" }}>
                                                                                <img src="/static/images/AssetsAA2/CaseStatus_Pending@3x.png" className="casedetails-status-icon" />
                                                                                {this.state.status.code}
                                                                            </span>
                                                                            : null
                                                                    }
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;<span className="Source-SansPro-Bold font-size-12">Case ID:{this.state.claimDetails.case_id}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card casedetails-towingcard" style={{cursor: "default"}}>
                                                        <div class="card-body towingcard-body">
                                                            <div class="row" >
                                                                <div class="col-md-9">
                                                                    {
                                                                        this.state.isLoading ?
                                                                            null
                                                                            :
                                                                            this.state.services.service_id == "TOW" ?
                                                                                this.state.authozied ?
                                                                                    <span className="Source-SansPro-Regular font-size-18" style={{ color: "#3DD598" }}>
                                                                                        <img src="/static/images/Towing_Authorized.png" className="towing-authorized-icon" />
                                                                                        <span className="STIX-General-Bold font-size-14">{this.state.services.service_code}</span> <br />
                                                                                        <div className="casedetails-authorize">
                                                                                            Panel / Authorized
                                                                                        </div>
                                                                                        <div className="ml-35px">
                                                                                            <table className="casedetails-locationtable">
                                                                                                <tr>
                                                                                                    <td className="casedetails-locationth">Location</td>
                                                                                                    <td className="space-td"></td>
                                                                                                    <td className="casedetails-locationdetails">
                                                                                                        <div className="street_address">
                                                                                                            {this.state.current_location.street_address}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.locality}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.state}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.country == "-" ? "-" : null}
                                                                                                            {this.state.current_location.country == "MY" ? "MALAYSIA" : null}
                                                                                                            {this.state.current_location.country == "SG" ? "SINGAPORE" : null}
                                                                                                            {this.state.current_location.country == "TH" ? "THAILAND" : null}
                                                                                                            {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                            {this.state.current_location.country == "OS" ? "OVERSEA" : null}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="space-td1"></td>
                                                                                                    <td class="text-left top-vertical-align">
                                                                                                        {
                                                                                                            this.state.destinationAddress.street_address ?
                                                                                                                <img src="/static/images/ArrowRight_gray.png" className="arrowright-icon" />
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </td>
                                                                                                    {
                                                                                                        this.state.destinationAddress.street_address ?
                                                                                                            <td className="casedetails-locationdetails">
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.street_address}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.locality}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.state}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.country == "-" ? "-" : null}
                                                                                                                    {this.state.destinationAddress.country == "MY" ? "MALAYSIA" : null}
                                                                                                                    {this.state.destinationAddress.country == "SG" ? "SINGAPORE" : null}
                                                                                                                    {this.state.destinationAddress.country == "TH" ? "THAILAND" : null}
                                                                                                                    {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                                    {this.state.destinationAddress.country == "OS" ? "OVERSEA" : null}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            :
                                                                                                            <td className="space-td-destination">
                                                                                                            </td>
                                                                                                    }
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="casedetails-distance">Distance</td>
                                                                                                    <td className="space-td2"></td>
                                                                                                    <td className="distance-details">
                                                                                                        {
                                                                                                            this.state.claimDetails.current_location.additional_information ? this.state.claimDetails.current_location.additional_information : "Not specified"
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="casedetails-distance">Reason</td>
                                                                                                    <td className="space-td2"></td>
                                                                                                    <td className="distance-details">
                                                                                                        {
                                                                                                            this.state.claimDetails.status.reason ? this.state.claimDetails.status.reason : "Not specified"
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </span>
                                                                                    :
                                                                                    <span className="Source-SansPro-Regular font-size-18" style={{ color: "#3DD598" }}>
                                                                                        <img src="/static/images/Towing_Authorized.png" className="towing-authorized-icon" />
                                                                                        <span className="STIX-General-Bold font-size-14">{this.state.services.service_code}</span> <br />
                                                                                        <div className="casedetails-authorize">
                                                                                            Panel / Authorized
                                                                                        </div>
                                                                                        <div className="ml-35px">
                                                                                            <table className="casedetails-locationtable">
                                                                                                <tr>
                                                                                                    <td className="casedetails-locationth">Location</td>
                                                                                                    <td className="space-td"></td>
                                                                                                    <td className="casedetails-locationdetails">
                                                                                                        <div className="street_address">
                                                                                                            {this.state.current_location.street_address}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.locality}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.state}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.current_location.country == "-" ? "-" : null}
                                                                                                            {this.state.current_location.country == "MY" ? "MALAYSIA" : null}
                                                                                                            {this.state.current_location.country == "SG" ? "SINGAPORE" : null}
                                                                                                            {this.state.current_location.country == "TH" ? "THAILAND" : null}
                                                                                                            {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                            {this.state.current_location.country == "OS" ? "OVERSEA" : null}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="space-td1"></td>
                                                                                                    <td class="text-left top-vertical-align">
                                                                                                        {
                                                                                                            this.state.destinationAddress.street_address ?
                                                                                                                <img src="/static/images/ArrowRight_gray.png" className="arrowright-icon" />
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </td>
                                                                                                    {
                                                                                                        this.state.destinationAddress.street_address ?
                                                                                                            <td className="casedetails-locationdetails">
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.street_address}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.locality}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.state}
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {this.state.destinationAddress.country == "-" ? "-" : null}
                                                                                                                    {this.state.destinationAddress.country == "MY" ? "MALAYSIA" : null}
                                                                                                                    {this.state.destinationAddress.country == "SG" ? "SINGAPORE" : null}
                                                                                                                    {this.state.destinationAddress.country == "TH" ? "THAILAND" : null}
                                                                                                                    {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                                    {this.state.destinationAddress.country == "OS" ? "OVERSEA" : null}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            :
                                                                                                            <td className="space-td-destination">
                                                                                                            </td>
                                                                                                    }
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="casedetails-distance">Distance</td>
                                                                                                    <td className="space-td2"></td>
                                                                                                    <td className="distance-details">
                                                                                                        {
                                                                                                            this.state.claimDetails.current_location.additional_information ? this.state.claimDetails.current_location.additional_information : "Not specified"
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="casedetails-distance">Reason</td>
                                                                                                    <td className="space-td2"></td>
                                                                                                    <td className="distance-details">
                                                                                                        {
                                                                                                            this.state.claimDetails.status.reason ? this.state.claimDetails.status.reason : "Not specified"
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </span>
                                                                                : <div className="ml-35px">
                                                                                    <table className="casedetails-locationtable">
                                                                                        <tr>
                                                                                            <td className="casedetails-locationth">Location</td>
                                                                                            <td className="space-td"></td>
                                                                                            <td className="casedetails-locationdetails">
                                                                                                <div className="street_address">
                                                                                                    {this.state.current_location.street_address}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {this.state.current_location.locality}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {this.state.current_location.state}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {this.state.current_location.country == "-" ? "-" : null}
                                                                                                    {this.state.current_location.country == "MY" ? "MALAYSIA" : null}
                                                                                                    {this.state.current_location.country == "SG" ? "SINGAPORE" : null}
                                                                                                    {this.state.current_location.country == "TH" ? "THAILAND" : null}
                                                                                                    {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                    {this.state.current_location.country == "OS" ? "OVERSEA" : null}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="space-td1"></td>
                                                                                            <td class="text-left top-vertical-align">
                                                                                                {
                                                                                                    this.state.destinationAddress.street_address ?
                                                                                                        <img src="/static/images/ArrowRight_gray.png" className="arrowright-icon" />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </td>
                                                                                            {
                                                                                                this.state.destinationAddress.street_address ?
                                                                                                    <td className="casedetails-locationdetails">
                                                                                                        <div>
                                                                                                            {this.state.destinationAddress.street_address}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.destinationAddress.locality}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.destinationAddress.state}
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {this.state.destinationAddress.country == "-" ? "-" : null}
                                                                                                            {this.state.destinationAddress.country == "MY" ? "MALAYSIA" : null}
                                                                                                            {this.state.destinationAddress.country == "SG" ? "SINGAPORE" : null}
                                                                                                            {this.state.destinationAddress.country == "TH" ? "THAILAND" : null}
                                                                                                            {this.state.current_location.country == "ID" ? "INDONESIA" : null}
                                                                                                            {this.state.destinationAddress.country == "OS" ? "OVERSEA" : null}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    :
                                                                                                    <td className="space-td-destination">
                                                                                                    </td>
                                                                                            }
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="casedetails-distance">Distance</td>
                                                                                            <td className="space-td2"></td>
                                                                                            <td className="distance-details">
                                                                                                {
                                                                                                    this.state.claimDetails.current_location.additional_information ? this.state.claimDetails.current_location.additional_information : "Not specified"
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="casedetails-distance">Reason</td>
                                                                                            <td className="space-td2"></td>
                                                                                            <td className="distance-details">
                                                                                                {
                                                                                                    this.state.claimDetails.status.reason ? this.state.claimDetails.status.reason : "Not specified"
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>
                                                                    }
                                                                </div>
                                                                <div class="col-md-3">
                                                                    {
                                                                        this.state.cause.description == 'Breakdown' || this.state.cause.description == 'Accident' ?
                                                                            <div class="card casedetails-fnol-container">
                                                                                <div class="card-body text-center">
                                                                                    <span>
                                                                                        <img className="thumb_fnol2-icon" src="/static/images/thumb_fnol2.png" />
                                                                                    </span>
                                                                                </div>
                                                                                <div class="card-footer text-center">
                                                                                    {
                                                                                        this.state.isDownload == false ?
                                                                                            <button class="btn btn-block fnol-download-button" onClick={() => this.downloadFiles(this.props.match.params.id)} >
                                                                                                <span>
                                                                                                    <img className="ic_attachment-icon" src="/static/images/ic_Attachment@3x.png" />
                                                                                                    <span className="fnol_text">FNOL.pdf</span>
                                                                                                    <img className="download_white-icon" src="/static/images/Download_white@3x.png" />
                                                                                                </span>
                                                                                            </button>
                                                                                            :
                                                                                            <button class="btn btn-block fnol-downloading-button">
                                                                                                <i class="fa fa-spinner fa-spin mr-20px font-size-22 "></i>
                                                                                                Downloading....
                                                                                            </button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                    <div class="card carddetails-container" style={{cursor: "default"}}>
                                                        <div class="card-body carddetails-body">
                                                            <span>
                                                                <img src="/static/images/Detail.png" className="details-icon" />
                                                                <span className="case-details-title"> Case Details </span>  <span className="case-details-id"> {this.state.claimDetails.case_id.toUpperCase()} </span>
                                                            </span>
                                                            <div class="row casedetails-row">
                                                                <div class="col-md-2 casedetails-label">Vehicle Reg No.</div>
                                                                <div class="col-md-4 text-left casedetails-input" >
                                                                    <div className="padding-5px"> {this.state.vehicle.registration_number ? this.state.vehicle.registration_number.toUpperCase() : "---"}</div>
                                                                </div>
                                                                <div class="col-md-2 text-left casedetails-label">
                                                                    <div className="bold-title"> Case Category </div>
                                                                </div>
                                                                <div class="col-md-4 casedetails-input" >
                                                                    <div className="padding-5px">{this.state.cause.description ? this.state.cause.description.toUpperCase() : "---"}</div>
                                                                </div>
                                                            </div>
                                                            <div class="row casedetails-row" >
                                                                <div class="col-md-2 casedetails-label" >Vehicle Model</div>
                                                                <div class="col-md-4 text-left casedetails-input">
                                                                    <div className="padding-5px">{this.state.vehicle.model ? this.state.vehicle.model.toUpperCase() : "---"}</div>
                                                                </div>
                                                                <div class="col-md-2 text-left casedetails-label">
                                                                    <div className="bold-title"> Check in Date </div>
                                                                </div>
                                                                <div class="col-md-4 casedetails-input">
                                                                    <div className="padding-5px">
                                                                        {Moment(this.state.claimDetails.opened_at).format("ddd, DD MMM yyyy").toUpperCase()}<br />{Moment(this.state.claimDetails.opened_at).format("hh:mm a").toUpperCase()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row casedetails-row">
                                                                <div class="col-md-2 casedetails-label" >Customer Name</div>
                                                                <div class="col-md-4 text-left casedetails-input" >
                                                                    <div className="padding-5px"> {this.state.customer.first_name} {this.state.customer.last_name} </div>
                                                                </div>
                                                                <div class="col-md-2 text-left casedetails-label">
                                                                    <div className="bold-title"> Expected check-out</div>
                                                                </div>
                                                                <div class="col-md-4 casedetails-input">
                                                                    <div className="padding-5px">NOT AVAILABLE</div>
                                                                </div>
                                                            </div>
                                                            <div class="row casedetails-row">
                                                                <div class="col-md-2 casedetails-label">Customer Phone No.</div>
                                                                <div class="col-md-4 text-left casedetails-input">
                                                                    <div className="padding-5px"> {this.state.contact_phone.number ? this.state.contact_phone.number : "Not provided"} </div>
                                                                </div>
                                                                <div class="col-md-2 text-left casedetails-label">
                                                                    <div className="bold-title">Product Name</div>
                                                                </div>
                                                                <div class="col-md-4 casedetails-input">
                                                                    <div className="padding-5px">{this.state.claimDetails.product_name ? this.state.claimDetails.product_name : "--"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    {/* Service Provided */}
                                                        {
                                                            this.state.serviceArray.length > 0 ?
                                                            <div class="card carddetails-container" style={{cursor: "default"}}>
                                                                <div class="card-body carddetails-body">
                                                                    <span>
                                                                        <img src="/static/images/Detail.png" className="details-icon" />
                                                                        <span className="case-details-title"> Services Provided Details </span>  <span className="case-details-id"> {this.state.claimDetails.case_id.toUpperCase()} </span>
                                                                    </span>
                                                                    {
                                                                        this.state.serviceArray.map((ev, index) => {
                                                                            return (
                                                                                <> 
                                                                                    {
                                                                                        index > 0 ? <DivDashLine /> : null
                                                                                    }
                                                                                    
                                                                                    <div class="row casedetails-row">
                                                                                        <div class="col-md-2 casedetails-label">Car Problem</div>
                                                                                        <div class="col-md-4 text-left casedetails-input" >
                                                                                            <div className="padding-5px"> {ev.problem ? ev.problem.toUpperCase() : "---"}</div>
                                                                                        </div>
                                                                                        <div class="col-md-2 text-left casedetails-label">
                                                                                            <div className="bold-title"> Service Provided </div>
                                                                                        </div>
                                                                                        <div class="col-md-4 casedetails-input" >
                                                                                            <div className="padding-5px">{ev.serviceProvided ? ev.serviceProvided.toUpperCase() : "---"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row casedetails-row" >
                                                                                        <div class="col-md-2 casedetails-label" >Assistance Location</div>
                                                                                        <div class="col-md-4 text-left casedetails-input">
                                                                                            <div className="padding-5px">{ev.assistanceLocation ? ev.assistanceLocation.toUpperCase() : "---"}</div>
                                                                                        </div>
                                                                                        <div class="col-md-2 text-left casedetails-label">
                                                                                            <div className="bold-title"> Workshop Location </div>
                                                                                        </div>
                                                                                        <div class="col-md-4 casedetails-input">
                                                                                            <div className="padding-5px">{ev.workshopLocation ? ev.workshopLocation.toUpperCase() : "---"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row casedetails-row">
                                                                                        <div class="col-md-2 casedetails-label" >Service Arrival Date & Time</div>
                                                                                        <div class="col-md-4 text-left casedetails-input" >
                                                                                            <div className="padding-5px"> {ev.serviceArrivalDate ? ev.serviceArrivalDate : "---"} {ev.serviceArrivalTime ? ev.serviceArrivalTime : "---"} </div>
                                                                                        </div>
                                                                                        <div class="col-md-2 text-left casedetails-label">
                                                                                            <div className="bold-title"> Provider Activation Date & Time</div>
                                                                                        </div>
                                                                                        <div class="col-md-4 casedetails-input">
                                                                                            <div className="padding-5px">{ev.providerActivationDate ? ev.providerActivationDate : "---"} {ev.providerActivationTime ? ev.providerActivationTime : "---"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div class="row casedetails-row">
                                                                                        <div class="col-md-2 casedetails-label">ROS Completion Time</div>
                                                                                        <div class="col-md-4 text-left casedetails-input">
                                                                                            <div className="padding-5px"> {ev.RodCompletionTime ? ev.RodCompletionTime : "---"} </div>
                                                                                        </div>
                                                                                        <div class="col-md-2 text-left casedetails-label">
                                                                                            <div className="bold-title"> TAT Arrival </div>
                                                                                        </div>
                                                                                        <div class="col-md-4 casedetails-input">
                                                                                            <div className="padding-5px">{ev.tatArrival ? ev.tatArrival : "---"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row casedetails-row">
                                                                                        <div class="col-md-2 casedetails-label" >TAT > 45 minutes</div>
                                                                                        <div class="col-md-4 text-left casedetails-input" >
                                                                                            <div className="padding-5px"> {ev.tatMoreThan45Min ? ev.tatMoreThan45Min : "---"} </div>
                                                                                        </div>
                                                                                        <div class="col-md-2 text-left casedetails-label">
                                                                                            <div className="bold-title"> TAT more than 45 minutes reasons</div>
                                                                                        </div>
                                                                                        <div class="col-md-4 casedetails-input">
                                                                                            <div className="padding-5px">{ev.tatMoreThan45MinReason ? ev.tatMoreThan45MinReason : "---"}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    {/* Customer Feedback */}
                                                    <div class="card carddetails-container" style={{cursor: "default"}}>
                                                        <div class="card-body carddetails-body">
                                                            <span>
                                                                <span className="case-details-title"> Customer Feedback </span>
                                                            </span>
                                                            <div class="row casedetails-row">
                                                                <div class="col-md-2 casedetails-label">Customer Satisfaction</div>
                                                                <div class="col-md-4 text-left casedetails-input" >
                                                                    <div className="padding-5px"> {this.state.overall ? this.state.overall : "---"}</div>
                                                                </div>
                                                                <div class="col-md-2 text-left casedetails-label">
                                                                    <div className="bold-title"> Not Applicable for Enquiry Case </div>
                                                                </div>
                                                                <div class="col-md-4 casedetails-input">
                                                                    <div className="padding-5px">{this.state.notApplicable ? this.state.notApplicable : "---"}</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.isZurich == true ?
                                                                <div class="row casedetails-row" >
                                                                    <div class="col-md-2 casedetails-label" >Net Promoter Score</div>
                                                                    <div class="col-md-4 text-left casedetails-input">
                                                                        <div className="padding-5px">{this.state.howLikely ? this.state.howLikely : "---"}</div>
                                                                    </div>

                                                                    <div class="col-md-2 text-left casedetails-label">
                                                                        <div className="bold-title"> Closed</div>
                                                                    </div>
                                                                    <div class="col-md-4 casedetails-input">
                                                                        <div className="padding-5px">{this.state.closed ? this.state.closed : "---"}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div class="row casedetails-row" >
                                                                    <div class="col-md-2 casedetails-label" >Net Promoter Score</div>
                                                                    <div class="col-md-4 text-left casedetails-input">
                                                                        <div className="padding-5px">{this.state.howLikely ? this.state.howLikely : "---"}</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.isZurich == true ?
                                                                    <div class="row casedetails-row" >
                                                                        <div class="col-md-2 casedetails-label" > Open Feedback</div>
                                                                        <div class="col-md-4 text-left casedetails-input">
                                                                            <div className="padding-5px">{this.state.feedback ? this.state.feedback : "---"}</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                    {/* Vehicle Replacement Details */}
                                                    {
                                                    this.state.isPorsche == true ?
                                                        <div class="card carddetails-container" style={{cursor: "default"}}>
                                                            <div class="card-body carddetails-body">
                                                                <span>
                                                                    <span className="case-details-title"> Vehicle Replacement Details </span>
                                                                </span>
                                                                <div class="row casedetails-row">
                                                                    <div class="col-md-2 casedetails-label">Replacement Car Days</div>
                                                                    <div class="col-md-4 text-left casedetails-input" >
                                                                        <div className="padding-5px"> {this.state.replacementCarDays ? this.state.replacementCarDays : "---"}</div>
                                                                    </div>
                                                                    <div class="col-md-2 text-left casedetails-label">
                                                                        <div className="bold-title"> Replacement Car Brand </div>
                                                                    </div>
                                                                    <div class="col-md-4 casedetails-input">
                                                                        <div className="padding-5px">{this.state.replacementCarBrand ? this.state.replacementCarBrand : "---"}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="row casedetails-row" >
                                                                    <div class="col-md-2 casedetails-label" >Replacement Car Model</div>
                                                                    <div class="col-md-4 text-left casedetails-input">
                                                                        <div className="padding-5px">{this.state.replacementCarType ? this.state.replacementCarType : "---"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    
                                                    {/* Photos */}
                                                    {
                                                        this.state.loadingImages ?
                                                            <div className="d-flex justify-content-center">
                                                                <div>
                                                                    <img className="searching_icon" src="/static/images/search-file.gif" />
                                                                </div>
                                                                <div className="text-center searching_image_message">
                                                                    Searching for images
                                                                </div>

                                                            </div>
                                                            :
                                                            this.state.photoUrl.length > 0 ?
                                                                <div class="card" >
                                                                    <div class="card-body mb-10px margin-top-10">
                                                                        <div class="row mb-10px">
                                                                            <div class="col-md-2">
                                                                                <span className="photos-card-header">
                                                                                    <img className="photos-icon" src="/static/images/Photos.png" />
                                                                                    Photos
                                                                                </span>
                                                                            </div>
                                                                            <div class="col-md-8">
                                                                            </div>
                                                                            <div class="col-md-2 text-right" onClick={() => {
                                                                                this.setState({ isOpenPhotos: true })
                                                                            }}>
                                                                                <div className="photos-seeall"> SEE All </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mb-3">
                                                                            <div class="col-sm-4 text-center middle-vertical-align"   >
                                                                                {
                                                                                    <img onClick={() => {
                                                                                        this.setState({ isOpenPhotos: true, selectedSlide: 0 })
                                                                                    }} class="cropped_vertical" style={{ height: "400px" }} src={this.state.photoUrl[0].attachment_url} width="100%" alt="Photo" />
                                                                                }
                                                                            </div>
                                                                            <div class="col-sm-6 text-center middle-vertical-align">
                                                                                <div class="row mb-3">
                                                                                    <div class="col-sm-8">
                                                                                        {
                                                                                            this.state.photoUrl.length > 1
                                                                                                ?
                                                                                                <img onClick={() => {
                                                                                                    this.setState({ isOpenPhotos: true, selectedSlide: 1 })
                                                                                                }} class="cropped_hoziontal" style={{ height: "192px" }} height="192px" src={this.state.photoUrl[1].attachment_url} alt="Photo" />

                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    <div class="col-sm-4">
                                                                                        {
                                                                                            this.state.photoUrl.length > 2
                                                                                                ?
                                                                                                <img onClick={() => {
                                                                                                    this.setState({ isOpenPhotos: true, selectedSlide: 2 })
                                                                                                }} class="cropped_hoziontal" style={{ height: "192px" }} height="100%" width="100%" src={this.state.photoUrl[2].attachment_url} alt="Photo" />
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row mb-3">
                                                                                    <div class="col-sm-4">
                                                                                        {
                                                                                            this.state.photoUrl.length > 3
                                                                                                ?
                                                                                                <img onClick={() => {
                                                                                                    this.setState({ isOpenPhotos: true, selectedSlide: 3 })
                                                                                                }} class="cropped_hoziontal" style={{ height: "192px" }} height="100%" width="100%" src={this.state.photoUrl[3].attachment_url} alt="Photo" />
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    <div class="col-sm-8">
                                                                                        {
                                                                                            this.state.photoUrl.length > 4
                                                                                                ?
                                                                                                <img onClick={() => {
                                                                                                    this.setState({ isOpenPhotos: true, selectedSlide: 4 })
                                                                                                }} class="cropped_hoziontal" style={{ height: "192px" }} height="100%" width="100%" src={this.state.photoUrl[4].attachment_url} alt="Photo" />
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-2 text-center middle-vertical-align" >
                                                                                {
                                                                                    this.state.photoUrl.length > 5 ?
                                                                                        <img onClick={() => {
                                                                                            this.setState({ isOpenPhotos: true, selectedSlide: 5 })
                                                                                        }} class="cropped_vertical" style={{ height: "400px" }} width="100%" src={this.state.photoUrl[5].attachment_url} alt="Photo" />
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="text-center empty_image_message">
                                                                    No images available for this case.
                                                                </div>
                                                    }

                                                    {/* End Photos */}
                                                </div>
                                                <div class="col-md-1 text-left">
                                                </div>
                                            </div>
                                }
                            </Layout>
                        </>
                        : null
                }
            </div>
        );
    }
}
export default withRouter(CaseDetails)
