import React from 'react';

class BreadCrumbs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        };
    }
    onMouseHandle(id, action) {
        let element_id = document.getElementById(id)
        element_id.style.color = action == "over" ? "#0A248F" : "#2B2D3D";
        element_id.style.fontWeight = action == "over" ? "bold" : null
        element_id.style.textDecoration = action == "over" ? "underline" : null;
    }

    render() {
        return (
            <div  className="mt_minis_40px">
                {
                    this.props.name == 'dashboard' ?
                        <ol class="breadcrumb  height-35">
                            <li class="breadcrumb-item" onClick={(e) => {
                            }}><a className="breadcrumb-active ml-10px">Home</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'claims' && this.props.subid == "" ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }} ><a id="home" onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li onClick={(e) => {
                                this.props.history.push(`/cases`)
                            }} class="breadcrumb-item " aria-current="page"><a className="breadcrumb-active mt-5px" >Cases</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'claims' && this.props.subid ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/cases`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li onClick={(e) => {
                                this.props.history.push(`/cases`)
                            }} class="breadcrumb-item" aria-current="page"><a
                                id="cases"
                                onMouseOver={(e) => this.onMouseHandle("cases", "over")}
                                onMouseOut={(e) => this.onMouseHandle("cases", "out")}
                                className="breadcrumb-non-active mt-5px">Cases</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Case Detail {this.props.subid}</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'report' ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/report`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li  class="breadcrumb-item" aria-current="page"><a
                                id="report" className="breadcrumb-non-active mt-5px">Reporting</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Case Activity Report (AAR)</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'telephonyReports' || this.props.name == 'telephonyReportsMonthly'?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/telephony-report`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li  class="breadcrumb-item" aria-current="page"><a
                                id="report" className="breadcrumb-non-active mt-5px">Reporting</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Telephony Service Levels Report</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'rsaReports' || this.props.name == 'rsaReportsMonthly' ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/rsa-report`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li  class="breadcrumb-item" aria-current="page"><a
                                id="report" className="breadcrumb-non-active mt-5px">Reporting</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Case Summary Report</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'incidentLocationReports' || this.props.name == 'incidentLocationReportsMonthly' ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/incidentLocation-report`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li  class="breadcrumb-item" aria-current="page"><a
                                id="report" className="breadcrumb-non-active mt-5px">Reporting</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Incident Location Report</a></li>
                        </ol>
                        :
                        null
                }
                {
                    this.props.name == 'TowingDestinationReports' || this.props.name == 'TowingDestinationReportsMonthly' ?
                        <ol class="breadcrumb mt-28px">
                            <li onClick={(e) => {
                                this.props.history.push(`/towingDestination-report`)
                            }}>
                                <img className="arrow-image" src="/static/images/ArrowBack_Highlight.png" />
                            </li>
                            <li class="breadcrumb-item" onClick={(e) => {
                                this.props.history.push(`/`)
                            }}><a id="home"
                                onMouseOver={(e) => this.onMouseHandle("home", "over")}
                                onMouseOut={(e) => this.onMouseHandle("home", "out")}
                                className="breadcrumb-non-active mt-5px">Home</a></li>
                            <li  class="breadcrumb-item" aria-current="page"><a
                                id="report" className="breadcrumb-non-active mt-5px">Reporting</a></li>
                            <li class="breadcrumb-item" aria-current="page"><a className="breadcrumb-active mt-5px">Towing Destination Report</a></li>
                        </ol>
                        :
                        null
                }
            </div>

        )
    }
}


export default BreadCrumbs

