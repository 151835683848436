import React from 'react';
import Cookies from 'js-cookie';
import './adminlte.css';
import './axa-style.css';
import './react-datepicker.css';
import './carousel.css';

import { withRouter } from 'react-router-dom';
class Layout extends React.Component {

   constructor(props) {
      super(props)
      this.state = {
         error: null,
         success: null,
         profile: {},
         role: "",
         partnerId: "",
         avatarLetter: '',
      };
   }
  
   componentDidMount() {      
      // Get profile data     
      const usercookie = Cookies.get('user');
      if (usercookie) {
         const user = JSON.parse(usercookie);
         let name = user.userInfo.name;
         let avatarLetter = name.substring(0, 1).toUpperCase();
         this.setState({ avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"], partnerId: user.userInfo["https://asia-assistance.com/partnerId"] })
      }

   }
   resetPassword() {
      const url = process.env.REACT_APP_RESET_PASSWORD_LINK;
      const headers = new Headers();
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      var postData = {
         client_id: process.env.REACT_APP_CLIENT_ID,
         email: this.state.profile.name,
         connection: 'Username-Password-Authentication'
      }
      const options = {
         method: 'POST',
         headers,
         credentials: "same-origin",
         body: JSON.stringify(postData)
      };
      fetch(url, options)
         .then(
            async (response) => {
               console.log("Response", response)
               this.setState({ success: "Reset email link has been sent to your email!" })
            })
         .catch(error => {
            if (error.response) {
               if (error.response.status >= 400 && error.response.status < 500) {
                  const invalidTokenMsg = 'Access denied due to invalid token or session expired, logout now';
                  setTimeout(() => {
                     window.location.href = "/login"
                     window.localStorage.clear()
                  }, 1000
                  )
               }
               else {
                  console.log('Error', error.message);
               }
            }
            this.setState({ error: "There was an error for reset password" })
         });
   }
   render() {
      return (
         <>
            <body className="hold-transition sidebar-mini body-padding">
               <div className="body-content">
                  <section className="content refresh_sec">
                     <div className="container-fluid">
                        {this.props.children}
                        <div className="bottom-section">
                           <div className="row">
                              <div className="col-md-3">
                              </div>
                              <div className="col-md-6 text-center">
                                 <div className="text-center margin-top-10 bottom-content">
                                    <div className="text-center margin-top-20 margin-bottom-10">
                                       <b>
                                          &copy;2023 Europ Assistance. All rights reserved.
                                       </b><br />
                                       <b>
                                          <a href="https://asia-assistance.com/privacy-policy/" target="_blank"> EA Privacy Statement </a> |<a href="https://asia-assistance.com/dasar-privasi/" target="_blank"> EA Pernyataan Privasi </a>| <a href="https://asia-assistance.com/terms-of-use/" target="_blank" > Terms of Use </a>
                                       </b>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-md-3">
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
            </body>
         </>
      )
   }
}


export default withRouter(Layout)

