import React from 'react';
class ServiceCenterPerformance extends React.Component {
    constructor(props) {
        super(props)
    }

    formatNumber(num) {
        let value = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (value === "NaN") {
            return 0;
        }
        return value;
    }
    render() {
        return (
            <>
                {
                    this.props.serviceCenterPerformance.length > 0 ?
                        <div class="row">
                            <div class="col-md-12" >
                                <div class="card performance-card">
                                    <div class="card-header card-header-background">
                                        <div className="card-header-title">
                                            Service Center Performance
                                        </div>
                                    </div>
                                    <div class="card-body" >
                                        <table class="table no-border tableBodyScroll performance-table" >
                                            <thead className="location_thead-background">
                                                <tr className="location_tr">
                                                    <th class="sticktop" width="25%" ><nobr>TOWING DESTINATION/ GARAGE</nobr></th>
                                                    <th class="sticktop" width="35%" ></th>
                                                    <th width="20%" onClick={(e) => {
                                                        if (this.state.descAccident) {
                                                            this.state.descAccident = false;
                                                            this.props.serviceCenterPerformance = this.props.serviceCenterPerformance.sort((a, b) => new Date(a.accidentCase) - new Date(b.accidentCase))
                                                        }
                                                        else {
                                                            this.state.descAccident = true;
                                                            this.props.serviceCenterPerformance = this.props.serviceCenterPerformance.sort((a, b) => new Date(b.accidentCase) - new Date(a.accidentCase))
                                                        }

                                                    }} class="sticktop"><nobr>
                                                            <div>
                                                                ACCIDENT CASE
                                                            </div>
                                                        </nobr></th>
                                                    <th width="20%" onClick={(e) => {
                                                        if (this.state.descBreakdown) {
                                                            this.state.descBreakdown = false;
                                                            this.props.serviceCenterPerformance = this.props.serviceCenterPerformance.sort((a, b) => new Date(a.breakdownCase) - new Date(b.breakdownCase))
                                                        }
                                                        else {
                                                            this.state.descBreakdown = true;
                                                            this.props.serviceCenterPerformance = this.props.serviceCenterPerformance.sort((a, b) => new Date(b.breakdownCase) - new Date(a.breakdownCase))
                                                        }
                                                    }}
                                                        class="sticktop"><nobr>
                                                            <div>
                                                                BREAKDOWN CASE
                                                            </div>
                                                        </nobr></th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {
                                                    this.props.serviceCenterPerformance.map((ev, index) => {
                                                        return (
                                                            <tr className="location_tr">
                                                                <td className="garage-content">
                                                                    {ev.garage}
                                                                </td>
                                                                <td className="destination-content">
                                                                    {ev.towingDestination}
                                                                </td>
                                                                <td className="accidentcase-content">
                                                                    <div class="row" >
                                                                        <div class="col-md-12 text-left">
                                                                            {
                                                                                this.formatNumber(parseInt(ev.accidentCase))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="breakcase-content">
                                                                    <div class="row">
                                                                        <div class="col-md-12 text-left">
                                                                            {
                                                                                this.formatNumber(parseInt(ev.breakdownCase))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </>
        )
    }
}


export default ServiceCenterPerformance

