import Cookies from 'js-cookie';
class MyService {

    static GE = {
        name: "Great Eastern Life Assurance",
        code: "GE",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/great eastern@3x.png",
        padding: "80px"
    };

    static GET = {
        name: "Great Eastern Takaful",
        code: "GET",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/great eastern@3x.png",
        padding: "80px"
    };

    static ZIMB = {
        name: "Zurich Insurance",
        code: "ZIMB",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/zurich@3x.png",
        padding: "80px"
    };

    static ZTMB = {
        name: "Zurich Takaful",
        code: "ZTMB",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/zurich@3x.png",
        padding: "80px"
    };

    static PAP_MY = {
        name: "Porsche Asia Pacific (PAP) Malaysia",
        code: "PAP_MY",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/porschelogo.png",
        padding: "120px"
    };

    static PAP_SG = {
        name: "Porsche Asia Pacific (PAP) Singapore",
        code: "PAP_SG",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/porschelogo.png",
        padding: "120px"
    };

    static VOLVO = {
        name: "Volvo Cars Malaysia",
        code: "VOLVO",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/aa_volvo_logo.png",
        padding: "80px"
    };

    static AAD = {
        name: "AAN Demo",
        code: "AAD",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/AADemo.png",
        padding: "0px"
    };

    static CHUBB = {
        name: "Chubb",
        code: "ACE_JERNEH",
        program: "Chubb Roadside Assist",
        logo: "/static/images/AXAAssets/Prototyping Asset/chubb_logo.png",
        padding: "0px"
    }

    static FWD = {
        name: "FWD INSURANCE RSA & HOME",
        code: "FWD",
        program: "FWD Motor",
        logo: "/static/images/AXAAssets/Prototyping Asset/fwd_logo.png",
        padding: "80px"
    }

    static PERODUA = {
        name: "Perodua",
        code: "PERODUA",
        program: "Perodua Auto Assist",
        logo: "/static/images/AXAAssets/Prototyping Asset/perodua_logo_new.png",
        padding: "0px"
    }

    static MERCEDES = {
        name: "MERCEDES-BENZ",
        code: "MBM",
        program: "Mercedes Benz Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/mercedes_logo.png",
        padding: "0px"
    }

    static AXA_SG = {
        name: "AXA INSURANCE SINGAPORE",
        code: "AIS",
        program: "AXA SmartDrive",
        logo: "/static/images/AXAAssets/Prototyping Asset/axa_sg_logo.png",
        padding: "80px"
    }

    static AUDI_MY = {
        name: "AUDI MY RSA",
        code: "AUDI_MY",
        program: "Audi Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/audi_logo.png",
        padding: "0px"
    }

    static AUDI_SG = {
        name: "AUDI SG CONNECT",
        code: "AUDI_SG",
        program: "Audi CallConnect",
        logo: "/static/images/AXAAssets/Prototyping Asset/audi_logo.png",
        padding: "0px"
    }

    static NTUC = {
        name: "NTUC",
        code: "NTUC",
        program: "NTUC Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/ntuc_logo.png",
        padding: "0px"
    }

    static BMW = {
        name: "BMW",
        code: "BMW",
        program: "BMW Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/bmw_logo.png",
        padding: "0px"
    }

    static UNKNOWN = {
        name: "Unknown Client",
        code: "UNKNOWN",
        program: "Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/AADemo.png",
        padding: "0px"
    };

    static VPCM = {
        name: "Volkswagen Passenger Cars Malaysia",
        code: "VPCM",
        program: "Volkswagen Roadside Assistance Program",
        logo: "/static/images/AXAAssets/Prototyping Asset/volkswagen_logo.png",
        padding: "0px"
    };
    
    static LIBERTY_MY = {
        name: "Liberty Insurance Berhad",
        code: "LIBERTY_MY",
        program: "Liberty Insurance Roadside Assistance",
        logo: "/static/images/AXAAssets/Prototyping Asset/liberty_1.png",
        padding: "0px"
    };
    
    static AIG_MY = {
        name: "AIG Malaysia Insurance Berhad",
        code: "AIG_MY",
        program: "AIG Smart Auto Assist Program",
        logo: "/static/images/AXAAssets/Prototyping Asset/aig_my.png",
        padding: "0px"
    };

    static getClientDetailFromCode(code) {
        if (code == "GE") return this.GE;
        if (code == "GET") return this.GET;
        if (code == "ZIMB") return this.ZIMB;
        if (code == "ZTMB") return this.ZTMB;
        if (code == "PAP_MY") return this.PAP_MY;
        if (code == "PAP_SG") return this.PAP_SG;
        if (code == "VOLVO") return this.VOLVO;
        if (code == "AAD") return this.AAD;
        if (code == "ACE_JERNEH") return this.CHUBB;
        if (code == "FWD") return this.FWD;
        if (code == "PERODUA") return this.PERODUA;
        if (code == "MBM") return this.MERCEDES;
        if (code == "AIS") return this.AXA_SG;
        if (code == "AUDI_MY") return this.AUDI_MY;
        if (code == "AUDI_SG") return this.AUDI_SG;
        if (code == "NTUC") return this.NTUC;
        if (code == "BMW") return this.BMW;
        if (code == "VPCM") return this.VPCM;
        if (code == "LIBERTY_MY") return this.LIBERTY_MY;
        if (code == "AIG_MY") return this.AIG_MY;

        //Should not reach here, please make sure you've added the respective client mapping
        return this.UNKNOWN;
    }

    static getHeader(method,partnerIndex,credentials) {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("X-Requested-With", "XMLHttpRequest");
        headers.append("X-Tenant-Id", process.env.REACT_APP_TENANT_ID);
        headers.append("partnerIndex",partnerIndex);
        if(credentials)
        {
            return {
                method: method,
                headers,
                credentials: "same-origin"
             };
        } else {
            return {
                method: method,
                headers,                
             };
        }
     }

     static logoutForSession() {
        window.localStorage.clear();
        Cookies.remove("user");
        Cookies.remove("session");
        window.location.href = "/logout";
    }

    static redirectToAuth0() {
        return process.env.REACT_APP_DELEGATED_ADMIN_CONSOLE_LINK;
    } 
    
    static printObj() {
        return process.env.REACT_APP_TENANT_ID;
    }
    
    static getAllCases()
    {
        const allCases=[
            {
                id:"AllCases",
                name:"All Cases",
                value:"",                
            },
            {
                id:"OpenedCases",
                name:"Opened Cases",
                value:"OPEN",                
            },
            {
                id:"UnverifiedCases",
                name:"Unverified Cases",
                value:"UNVERIFIED",                
            },
            {
                id:"CancelledCases",
                name:"Cancelled Cases",
                value:"CANCELLED",                
            },
            {
                id:"ClosedCases",
                name:"Closed Cases",
                value:"CLOSED",                
            },
        ];
        return allCases;
    }

    static getAllCasesType()
    {
        const allCasesType=[
            {
                id:"AllCaseType",
                name:"All Cases Type",
                value:"",                
            },
            {
                id:"TowingAccident",
                name:"Towing",
                value:"TOWING",                
            },
            {
                id:"Repaironside",
                name:"Repair on spot",
                value:"REPAIR_ON_SPOT",                
            },
            {
                id:"Delivery",
                name:"Delivery",
                value:"DELIVERY",                
            },
            {
                id:"Storage",
                name:"Storage",
                value:"STORAGE",                
            },
            
        ];
        return allCasesType;
    }

    static getAllIncomingCallsType()
    {
        const allIncomingCallsType=[
            {
                id:"AllIncomingCallsType",
                name:"All Incoming Calls Type",
                value:""           
            },
            {
                id:"AppType",
                name:"App",
                value:"APP"
            },
            {
                id:"BCallType",
                name:"BCall",
                value:"BCALL"
            },
            {
                id:"CallType",
                name:"Call",
                value:"CALL"
            },
            {
                id:"ECallType",
                name:"ECall",
                value:"ECALL"
            },
            {
                id:"EmailType",
                name:"Email",
                value:"EMAIL"
            },
            {
                id:"PortalType",
                name:"Portal",
                value:"PORTAL"
            },
            {
                id:"WhatsappType",
                name:"Whatsapp",
                value:"WHATSAPP"
            },
            
        ];
        return allIncomingCallsType;
    }

    static getSearchOptions()
    {
        const searchOptions=[
            {
                id:"PlateNo",
                name:"Search by Car Plate No.",                
                placeholder:"Enter Car Plate No."                
            },
            {
                id:"PolicyNo",
                name:"Search by VIN No.",                
                placeholder:"Enter VIN No."                
            },
            {
                id:"CaseId",
                name:"Search by Case ID.",                
                placeholder:"Enter Case ID."                
            }
        ];
        return searchOptions;
    }
    
    static getModeOptions()
    {
        const modeOptions=[
            {
                id:"Daily",
                name:"Daily",                
                value:"Daily"                
            },
            {
                id:"Monthly",
                name:"Monthly",                
                value:"Monthly"                
            }
        ];
        return modeOptions;
    }
    
    static getModeOptionsMonthly()
    {
        const modeOptions=[
            {
                id:"Monthly",
                name:"Monthly",                
                value:"Monthly"                
            }
        ];
        return modeOptions;
    }
    
    static getMonthlyOptions()
    {
        const monthlyOptions=[
            {
                id:"January",
                name:"January",                
                value:"January"                
            },
            {
                id:"February",
                name:"February",                
                value:"February"                
            },
            {
                id:"March",
                name:"March",                
                value:"March"                
            },
            {
                id:"April",
                name:"April",                
                value:"April"                
            },
            {
                id:"May",
                name:"May",                
                value:"May"                
            },
            {
                id:"June",
                name:"June",                
                value:"June"                
            },
            {
                id:"July",
                name:"July",                
                value:"July"                
            },
            {
                id:"August",
                name:"August",                
                value:"August"                
            },
            {
                id:"September",
                name:"September",                
                value:"September"                
            },
            {
                id:"October",
                name:"October",                
                value:"October"                
            },
            {
                id:"November",
                name:"November",                
                value:"November"                
            },
            {
                id:"December",
                name:"December",                
                value:"December"                
            }
        ];
        return monthlyOptions;
    }
}

export default MyService