import React from 'react';
import MyService from "./services/my-service";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class TopNavigation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpenLogout: false,
            avatarLetter: ""
        };
        

    }

    toggleModalLogout() {
        let isOpenLogout = !this.state.isOpenLogout
        this.setState({
            isOpenLogout: isOpenLogout
        })
    }


    render() {
        return (
            <>
                <Modal
                    backdrop="static"
                    centered
                    isOpen={this.state.isOpenLogout}
                    toggle={() => {
                        let isOpenLogout = !this.state.isOpenLogout
                        this.setState({
                            isOpenLogout: isOpenLogout
                        })
                    }}>
                    <ModalHeader
                        toggle={() => {
                            let isOpenLogout = !this.state.isOpenLogout
                            this.setState({
                                isOpenLogout: isOpenLogout
                            })
                        }}>
                        <div class="row text-center">
                            <div class="col-md-12 text-center ml-130px">
                                Logout
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-30px">
                            <p className="mb-30px">
                                Are you sure you want to logout?
                                 </p>
                            <div className="text-center ml-70px" >
                                <table>
                                    <tr>
                                        <td width="45%" class="text-center">
                                            <a class="btn btn-confirm" onClick={() => MyService.logoutForSession()}>Yes</a>
                                        </td>
                                        <td width="10%"> &nbsp;</td>
                                        <td width="45%" class="text-center white-color">
                                            <a class="btn btn-reject" onClick={(e) => {
                                                let isOpenLogout = !this.state.isOpenLogout
                                                this.setState({
                                                    isOpenLogout: isOpenLogout
                                                })
                                            }}>No</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                <nav className="navbar navbar-expand top-navbar-section">
                    <span onClick={() => {
                        window.location.href = "/"
                    }}>
                        <img className="logo-web" style={{cursor: "pointer", height: "70px", width: "80px"}} src="/static/images/EA_logo.PNG" alt="EA Logo" />
                    </span>
                    <ul class="navbar-nav ml-auto">
                        {
                            this.props.name == "dashboard"
                                ?
                                <li onClick={(e) => {
                                    this.props.history.push(`/`)
                                }} class="nav-item nav-item-dashboard" style={{paddingLeft: "10px"}} >
                                    <a class="nav-link nav-link-item font-semi active-item" style={{ paddingBottom: "20px" }}>Dashboard</a>
                                </li>
                                :
                                <li onClick={(e) => {
                                    this.props.history.push(`/`)
                                }} class="nav-item nav-item-dashboard" style={{paddingLeft: "10px"}}>
                                    <a class="nav-link nav-link-item font-semi" style={{ paddingBottom: "20px" }}>Dashboard</a>
                                </li>
                        }
                        {((this.props.role.indexOf("reports-viewer") > -1) && (this.props.partnerId == "PAP_SG" || this.props.partnerId == "PAP_MY" || 
                                    this.props.partnerId == "LIBERTY_MY" || this.props.partnerId == "PERODUA" || this.props.partnerId == "ZIMB" || this.props.partnerId == "ZTMB" ||
                                    this.props.partnerId == "VPCM"))?
                            <li class="nav-item dropdown" ref={this.props.setWrapperRefReport}>
                                <a class="nav-link" style={{cursor: "pointer", paddingTop: "24px", paddingBottom: "20px"}} onClick={(e) => this.props.hideDropDownReport()}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="partner-name" style={{fontFamily: "SourceSansPro-Regular", fontSize: "13px", color: "#212529", fontWeight: "600"}}>
                                                    Reporting
                                                </div>
                                            </td>
                                            <td width="55px"><img className="user-image" src="/static/images/Dropdown2@3x.png" alt="User Image" /></td>
                                        </tr>
                                    </table>
                                </a>
                                {/*  */}
                                <ul hidden={this.props.hideDropDownMenuReport} class="list-group dropdown-menu width-300">
                                    <li>
                                        <div>
                                            {(this.props.partnerId == "PAP_SG" || this.props.partnerId == "PAP_MY") ?
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/report`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Case Activity Report (AAR)
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                             : null
                                            }        
                                            {(this.props.partnerId == "LIBERTY_MY" || this.props.partnerId == "PERODUA") ? 
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/telephony-report`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Telephony Service Levels Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "LIBERTY_MY" || this.props.partnerId == "PERODUA") ?
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/rsa-report`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Case Summary Report (RSA)
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "PERODUA") ?
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/incidentLocation-report`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Incident Location Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "ZIMB" || this.props.partnerId == "ZTMB" || this.props.partnerId == "LIBERTY_MY" || this.props.partnerId == "VPCM") ?
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/incidentLocation-report-monthly`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Incident Location Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "ZIMB" || this.props.partnerId == "ZTMB" || this.props.partnerId == "VPCM") ?
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/rsa-report-monthly`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Case Summary Report (RSA)
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "ZIMB" || this.props.partnerId == "ZTMB" || this.props.partnerId == "VPCM" || this.props.partnerId == "PAP_MY" || this.props.partnerId == "PAP_SG") ? 
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/telephony-report-monthly`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Telephony Service Levels Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "ZIMB" || this.props.partnerId == "ZTMB" || this.props.partnerId == "LIBERTY_MY" || this.props.partnerId == "VPCM") ? 
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/towingDestination-report-monthly`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Towing Destination Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        </a>
                                              : null
                                            }
                                            {(this.props.partnerId == "PERODUA") ? 
                                                    <a class="dropdown-item">
                                                        <table>
                                                            <tr onClick={(e) => this.props.history.push(`/towingDestination-report`)}>
                                                                <td className="Source-SansPro-Regular font-size-14">
                                                                    <div style={{cursor: "pointer"}}>
                                                                        Towing Destination Report
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        </a>
                                              : null
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            :
                            null
                        }
                        {
                            this.props.name == "claims"
                                ?
                                <li onClick={(e) => {
                                    this.props.history.push(`/cases`)
                                }} class="nav-item nav-item-cases" >
                                    <a class="nav-link nav-link-item font-semi active-item" style={{ paddingBottom: "20px" }}>Cases</a>
                                </li>
                                :
                                <li onClick={(e) => {
                                    this.props.history.push(`/cases`)
                                }} class="nav-item nav-item-cases">
                                    <a class="nav-link nav-link-item font-semi" style={{ paddingBottom: "20px" }}>Cases</a>
                                </li>
                        }
                        {
                                this.props.role.indexOf("delegated administrator") > -1 ?
                                    <li class="nav-item nav-item-users">
                                        <a onClick={() => {
                                            window.open(MyService.redirectToAuth0(), '_blank');
                                        }} class="nav-link nav-link-item  font-semi" style={{ paddingBottom: "20px" }}>Users</a>
                                    </li>
                                    :
                                    null
                            
                        }
                        <li class="nav-item dropdown" ref={this.props.setWrapperRefLogOut}>
                            <a class="nav-link" style={{cursor: "pointer"}} onClick={(e) => this.props.hideDropDownLogout()}>
                                <table style={{ marginTop: "8px" }}>
                                    <tr>
                                        <td width="55px">
                                            <div class="avatar-circle">
                                                <span class="initials">
                                                    {
                                                        this.props.avatarLetter
                                                    }
                                                </span>
                                            </div>
                                        </td>
                                        <td >
                                            <span><b className="profile-nickname">
                                                {this.props.profile.nickname}</b>
                                            </span>
                                            <div className="partner-name">
                                                {
                                                    MyService.getClientDetailFromCode(this.props.partnerId).name
                                                }
                                            </div>
                                        </td>
                                        <td width="55px"><img className="user-image" src="/static/images/Dropdown2@3x.png" alt="User Image" /></td>
                                    </tr>
                                </table>
                            </a>
                            {/*  */}
                            <ul hidden={this.props.hideDropDownMenu} class="list-group dropdown-menu width-100">
                                <li>
                                    <div>
                                        <a href="#" class="dropdown-item" onClick={(e) => this.toggleModalLogout()}>
                                            <i class="fa fa-power-off text-muted mr-28px"></i><span className="font-size-14"> Logout</span>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </>
        )
    }
}


export default TopNavigation

