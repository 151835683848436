import React from 'react';
import Cookies from 'js-cookie';
import Layout from './layout';
import DatePicker from "react-datepicker";
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import MyService from "./services/my-service";
import Moment from 'moment';
import $ from "jquery";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'font-awesome/css/font-awesome.min.css';
import Chart from 'chart.js';
import TowingLocation from "./towingLocation";
import ServiceCenterPerformance from "./serviceCenterPerformance";
import BreadCrumbs from "./breadcrumbs";
import SlaReport from "./slaReport";
import TopNavigation from "./topnavigation";
const ExampleCustomInput = ({ value, onClick }) => (

   <input id="month" type="text" value={value} onClick={onClick} class="form-control calendar_input" style={{cursor: "pointer"}} />
);

class Dashboard extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         descAccident: false,
         descBreakdown: false,
         minDate: "",
         toggle: {},
         dashboardToggle: {
            cancelledCase: false,
            caseCategory: false,
            location: false,
            openCase: false,
            serviceCenterPerformance: false,
            serviceLevel: false,
            totalCase: false,
            unverifiedCase: false,
            verifiedCase: false,
         },
         name: "dashboard",
         avatarLetter: "",
         role: "",
         profile: {},
         hideDropDownClientName: true,
         hideDropDownMenu: true,
         hideDropDownMenuReport: true,
         oldText: "",
         xCoordinate: 0,
         yCoordinate: 0,
         show_bar_chart_full: false,
         reload: false,
         isloading: false,
         profile: {},
         claimsArray: [],
         dashboardCase: {},
         serviceLevel: {},
         caseCategory: {},
         totalCaseCategory: 0,
         location: [],
         locationOverseas: [],
         totalLocationValue: 0,
         totalLocationValueOversea: 0,
         serviceCenterPerformance: [],
         totalAccidentCase: 0,
         totalBreakdownCase: 0,
         thisMonthCallCurrentYear: {},
         untilNowCallCurrentYear: {},
         wholeYearCallCurrentYear: {},
         thisMonthCallLastYear: {},
         untilNowCallLastYear: {},
         wholeYearCallLastYear: {},
         targetPerformance: [],
         papeSize: 10,
         currentPage: 1,
         selectedMonth: {},
         yearStartToCurMonth: {},
         yearStartToEnd: {},
         pageCount: [
         ],
         partnerId: "",
         partnerId1: "",
         partnerIds: [],
         partnerIndex: 0,
         offset: 0,
         selectDate: new Date(),
         isOpenReport: false,
         isOpenLogout: false,
         options: {},
         optionsSla: {
            elements: {
               point: {
                  radius: 0
               }
            },
            tooltips: {
               enabled: true,
               intersect: false,
               backgroundColor: '#FFF',
               titleFontSize: 14,
               titleFontColor: '#9599B6',
               bodyFontColor: '#9599B6',
               bodyFontFamily: 'SourceSansPro-Regular',
               bodyFontSize: 12,
               displayColors: false
            },
            responsive: true,
            legend: {
               display: false
            },
            scales: {
               yAxes: [{
                  ticks: {
                     min: 0,
                     max: 100,
                     stepSize: 20,
                     fontFamily: "SourceSansPro-Bold",
                     beginAtZero: true,
                     callback: function (value, index, values) {
                        if (value > 0) {
                           return value + '%';
                        }
                     }
                  },
                  gridLines: {
                     display: true
                  }
               }],
               xAxes: [{
                  ticks: {
                     fontFamily: "SourceSansPro-Bold",
                  },
                  gridLines: {
                     display: true
                  },
                  barPercentage: 0.4
               }]
            }
         },
         serviceLevelTarget: [],
         dataNumberOfCall: {}
      }
      this.setWrapperRefClientName = this.setWrapperRefClientName.bind(this);
      this.setWrapperRefLogOut = this.setWrapperRefLogOut.bind(this);
      this.setWrapperRefReport = this.setWrapperRefReport.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
   }

   findIndex(arr, text) {
      let index = 0;
      for (let i = 0; i < arr.length; i++) {
         if (text == arr[i]) {
            index = i;
         }
      }
      return index;
   }

   setWrapperRefClientName(node) {
      this.wrapperRefClientName = node;
   }

   setWrapperRefLogOut(node) {
      this.wrapperRefLogOut = node;
   }
   setWrapperRefReport(node) {
      this.wrapperRefReport = node;
   }
   drawVerifiedCasesZero(verifiedCase, unverifiedCase) {

      var configVerifiedCase = {
         type: 'doughnut',
         data: {
            datasets: [{
               data: [
                  verifiedCase, unverifiedCase
               ],
               label: 'Dataset 1',
               backgroundColor: [],

            }],
            labels: [
               "Verified Cases",
               "Unverified Cases",
            ]
         },
         options: {
            tooltips: { enabled: false },
            legend: {
               display: false
            },
            circumference: 1 * Math.PI,
            rotation: 1 * Math.PI,
            cutoutPercentage: 75
         }
      };
      var ctx = document.getElementById("totalVerifiedCases").getContext("2d");
      if (window.barVerifiedCases != undefined)
         window.barVerifiedCases.destroy();
      window.barVerifiedCases = new Chart(ctx, configVerifiedCase);
   }

   drawVerifiedCases(verifiedCase, unverifiedCase) {
      var configVerifiedCase = {
         type: 'doughnut',
         data: {
            datasets: [{
               data: [
                  verifiedCase, unverifiedCase
               ],
               label: 'Dataset 1',
               backgroundColor: ['#48AFC7', '#F0F2F4'],

            }],
            labels: [
               "Verified Cases",
               "Unverified Cases",
            ]
         },
         options: {
            tooltips: {
               enabled: true,
               intersect: false,
               backgroundColor: '#FFF',
               titleFontSize: 14,
               titleFontColor: '#9599B6',
               bodyFontColor: '#9599B6',
               bodyFontFamily: 'SourceSansPro-Bold',
               bodyFontSize: 12,
               displayColors: false,
            },
            legend: {
               display: false
            },
            circumference: 1 * Math.PI,
            rotation: 1 * Math.PI,
            cutoutPercentage: 75
         }
      };
      var ctx = document.getElementById("totalVerifiedCases").getContext("2d");
      if (window.barVerifiedCases != undefined)
         window.barVerifiedCases.destroy();
      window.barVerifiedCases = new Chart(ctx, configVerifiedCase);
   }

   drawCaseCategoryZero(towingAccident, towingBreakdown, repairOnSite, vehicleReplacement, others, totalCase) {
      Chart.pluginService.register({
         beforeDraw: function (chart) {
            if (chart.config.options.plugin_one_attribute) {
               var width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.chart.ctx;
               ctx.restore();
               ctx.clearRect(0, 0, width, height);
               var fontSize = (height / 114).toFixed(2);
               ctx.font = "28px STIXGeneralBol";
               ctx.fillStyle = "#2B2D3D"
               ctx.textBaseline = "middle";
               var text = totalCase,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2;
               ctx.fillText(text, textX, textY);
               ctx.font = "14px SourceSansPro-SemiBold";
               ctx.fillStyle = "#9599B6"
               ctx.textBaseline = "middle";
               var text1 = "Total cases",
                  textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
                  textY1 = (height / 2) + 30;
               ctx.fillText(text1, textX1, textY1);
               ctx.save();
            }
         }
      });
      var configCaseCategory = {
         type: 'doughnut',
         data: {
            datasets: [{
               data: [
                  towingAccident, towingBreakdown, repairOnSite, vehicleReplacement, others
               ],
               label: 'Dataset 1',
               backgroundColor: [],
            }],
            labels: [
               "Towing Accident",
               "Towing Breakdown",
               "Enquiry",
               "Concierge",
               "Others"
            ]
         },
         options: {
            tooltips: { enabled: false },
            plugin_one_attribute: true,
            legend: {
               display: false
            },
            cutoutPercentage: 75
         }
      };
      var ctx = document.getElementById("caseCategory").getContext("2d");
      if (window.barCaseCategory != undefined) {
         window.barCaseCategory.destroy();
      }
      window.barCaseCategory = new Chart(ctx, configCaseCategory);
   }

   drawCaseCategory(towingAccident, towingBreakdown, repairOnSite, vehicleReplacement, others, totalCase) {
      Chart.pluginService.register({
         beforeDraw: function (chart) {
            if (chart.config.options.plugin_one_attribute) {
               var width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.chart.ctx;
               ctx.restore();
               ctx.clearRect(0, 0, width, height);
               var fontSize = (height / 114).toFixed(2);
               ctx.font = "28px STIXGeneralBol";
               ctx.fillStyle = "#2B2D3D"
               ctx.textBaseline = "middle";
               var text = totalCase,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2;
               ctx.fillText(text, textX, textY);
               ctx.font = "14px SourceSansPro-SemiBold";
               ctx.fillStyle = "#9599B6"
               ctx.textBaseline = "middle";
               var text1 = "Total cases",
                  textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
                  textY1 = (height / 2) + 30;
               ctx.fillText(text1, textX1, textY1);
               ctx.save();
            }
         }
      });
      var configCaseCategory = {
         type: 'doughnut',
         data: {
            datasets: [{
               data: [
                  towingAccident, towingBreakdown, repairOnSite, vehicleReplacement, others
               ],
               label: 'Dataset 1',
               backgroundColor: ["#F5D18F", "#00008F", "#60ADC3", "#2C7180", "#D79BAB"],
            }],
            labels: [
               "Towing Accident",
               "Towing Breakdown",
               "Enquiry",
               "Concierge",
               "Others"
            ]
         },
         options: {
            tooltips: {
               enabled: true,
               intersect: false,
               backgroundColor: '#FFF',
               titleFontSize: 14,
               titleFontColor: '#9599B6',
               bodyFontColor: '#9599B6',
               bodyFontFamily: 'SourceSansPro-Bold',
               bodyFontSize: 12,
               displayColors: false,
            },
            plugin_one_attribute: true,
            legend: {
               display: false
            },
            cutoutPercentage: 75
         }
      };
      var ctx = document.getElementById("caseCategory").getContext("2d");
      if (window.barCaseCategory != undefined) {
         window.barCaseCategory.destroy();
      }
      window.barCaseCategory = new Chart(ctx, configCaseCategory);
   }

   toggleModalReport() {
      let isOpenReport = !this.state.isOpenReport
      this.setState({
         isOpenReport: isOpenReport
      })
   }

   getDashboardData(month, year) {
      this.setState({ isloading: true })
      let url = `api/dashboard?month=${month}&year=${year}`;
      const options = MyService.getHeader("GET", this.state.partnerIndex, true);
      fetch(url, options)
         .then(
            async (response) => {
               let data = await response.json();
               this.checkForInvalidSession(data);
               let caseCategory = 0;
               let totalCategory = 0;
               caseCategory = data.data.caseCategory;
               totalCategory = caseCategory.others + caseCategory.inquiry + caseCategory.concierge + caseCategory.breakdown + caseCategory.accident;
               let dashboardCase = data.data.dashboardCase;
               if (dashboardCase.totalCase > 0) {
                  this.drawVerifiedCases((dashboardCase.totalCase - dashboardCase.unverifiedCase), dashboardCase.unverifiedCase);
               }
               else {
                  this.drawVerifiedCasesZero(1, 0);
               }
               if (totalCategory > 0) {
                  this.drawCaseCategory(caseCategory.accident, caseCategory.breakdown, caseCategory.inquiry, caseCategory.concierge, caseCategory.others, this.formatNumber(parseInt(totalCategory)));
                  this.state.show_bar_chart_full = true;
               }
               else {
                  this.state.show_bar_chart_full = false;
                  this.drawCaseCategoryZero(1, 1, 1, 1, 1, 0);
               }
               this.setState({
                  dashboardCase: dashboardCase,
                  serviceLevel: data.data.serviceLevel,
                  caseCategory: caseCategory,
                  totalCaseCategory: totalCategory,
                  isloading: false,
                  dashboardToggle: data.data.toggle
               })
            })
         .catch(error => {
            if (error.response) {
               console.log('Error', error.message);
            }
            this.setState({ isloading: false })
         });
   }

   getSLACall(month, year) {
      this.setState({ isloading: true })
      let url = `api/dashboard/serviceLevel/call?month=${month}&year=${year}`;
      const options = MyService.getHeader("GET", this.state.partnerIndex, true);
      fetch(url, options)
         .then(
            async (response) => {
               let data = await response.json()
               this.checkForInvalidSession(data);
               this.setState({
                  isloading: false,
                  thisMonthCallCurrentYear: data.data.currentYear.selectedMonth,
                  untilNowCallCurrentYear: data.data.currentYear.yearStartToCurMonth,
                  wholeYearCallCurrentYear: data.data.currentYear.yearStartToEnd,
                  thisMonthCallLastYear: data.data.lastYear.selectedMonth,
                  untilNowCallLastYear: data.data.lastYear.yearStartToCurMonth,
                  wholeYearCallLastYear: data.data.lastYear.yearStartToEnd,
                  toggle: data.data.toggle
               })
            })
         .catch(error => {
            console.log(error)
            if (error.response) {
               console.log('Error', error.message);
            }
            this.setState({ isloading: false })
         });
   }

   getSLAGraph(month, year) {
      this.setState({ isloading: true })
      let url = `api/dashboard/serviceLevel/graph?month=${month}&year=${year}`;
      const options = MyService.getHeader("GET", this.state.partnerIndex, true);

      fetch(url, options)
         .then(
            async (response) => {
               let data = await response.json()
               this.checkForInvalidSession(data);
               let SLAGraph = JSON.stringify(data);

               let data1 = data.data;
               let numberOfCallPerMonth = data1.callsOffered;
               let serviceLevelTarget = data1.callsAnswered;
               let totalCallPerMonth = 0;
               totalCallPerMonth = numberOfCallPerMonth.performances[0].value +
                  numberOfCallPerMonth.performances[1].value +
                  numberOfCallPerMonth.performances[2].value +
                  numberOfCallPerMonth.performances[3].value +
                  numberOfCallPerMonth.performances[4].value +
                  numberOfCallPerMonth.performances[5].value +
                  numberOfCallPerMonth.performances[6].value +
                  numberOfCallPerMonth.performances[7].value +
                  numberOfCallPerMonth.performances[8].value +
                  numberOfCallPerMonth.performances[9].value +
                  numberOfCallPerMonth.performances[10].value +
                  numberOfCallPerMonth.performances[11].value;

               let dataNumberOfCall = {}
               if (totalCallPerMonth == 0) {
                  let options = {}
                  options = {
                     tooltips: { enabled: false },
                     responsive: true,
                     legend: {
                        display: false
                     },
                     scales: {
                        yAxes: [{
                           ticks: {
                              fontFamily: "SourceSansPro-Bold",
                              beginAtZero: true,
                              display: false,
                              callback: function (value, index, values) {
                                 if (value > 0) {
                                    return Intl.NumberFormat().format((value / 1000)) + 'K';
                                 } else {
                                    return value;
                                 }
                              }
                           },
                           gridLines: {
                              display: true
                           }
                        }],
                        xAxes: [{
                           ticks: {
                              fontFamily: "SourceSansPro-Bold",
                           },
                           gridLines: {
                              display: true
                           },
                           barPercentage: 0.4
                        }]
                     }
                  }
                  this.state.options = options;
                  dataNumberOfCall = {
                     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                     datasets: [
                        {
                           label: "Number of Calls Offered Per Month",
                           borderColor: "#00008F",
                           fill: false,
                           data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        },
                     ]
                  }
               }
               else {
                  let options = {}
                  options = {
                     elements: {
                        point: {
                           radius: 0
                        }
                     },
                     tooltips: {
                        callbacks: {
                           label: function (tooltipItem, data) {

                              let value1 = tooltipItem.yLabel.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                              return "Number of Calls Offered Per Month:" + value1;
                           }
                        },
                        enabled: true,
                        intersect: false,
                        backgroundColor: '#FFF',
                        titleFontSize: 14,
                        titleFontColor: '#9599B6',
                        bodyFontColor: '#9599B6',
                        bodyFontFamily: 'SourceSansPro-Regular',
                        bodyFontSize: 12,
                        displayColors: false,
                     },
                     responsive: true,
                     legend: {
                        display: false
                     },
                     scales: {
                        yAxes: [{
                           ticks: {
                              fontFamily: "SourceSansPro-Bold",
                              beginAtZero: true,
                              callback: function (value, index, values) {
                                 if (value > 0) {
                                    return Intl.NumberFormat().format((value / 1000)) + 'K';
                                 } else {
                                    return value;
                                 }
                              }
                           },
                           gridLines: {
                              display: true
                           }
                        }],
                        xAxes: [{
                           ticks: {
                              fontFamily: "SourceSansPro-Bold",
                           },
                           gridLines: {
                              display: true
                           },
                           barPercentage: 0.4
                        }]
                     }
                  }
                  this.state.options = options
                  let labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                  let data = [];
                  let currentMonth = Moment(new Date()).format("M");
                  let currentYear = Moment(new Date()).format("YYYY");
                  if (currentYear == year) {
                     let startingPoint = 0; //null
                     let i = 0;
                     for (i = 0; i < currentMonth; i++) {
                        let value = numberOfCallPerMonth.performances[i].value; 
                        let toUseValue = 0; // null
                        if (startingPoint === null && value > 0) {
                           startingPoint = value;
                        }
                        if (value > 0 || startingPoint !== null) {
                           toUseValue = value;
                        }
                        console.log("Value is " + toUseValue + " for month " + i);
                        data.push(toUseValue);
                     }
                  }
                  else {
                     let startingPoint = 0; // null
                     let i = 0;
                     for (i = 0; i < 12; i++) {
                        let value = numberOfCallPerMonth.performances[i].value;
                        let toUseValue = 0; // null
                        if (startingPoint === null && value > 0) {
                           startingPoint = value;
                        }
                        if (value > 0 || startingPoint !== null) {
                           toUseValue = value;
                        }
                        console.log("Value is " + toUseValue + " for month " + i);
                        data.push(toUseValue);
                     }
                  }

                  dataNumberOfCall = {
                     labels: labels,
                     datasets: [
                        {
                           label: "Number of Calls Offered Per Month",
                           borderColor: "#00008F",
                           fill: false,
                           data: data
                        },
                     ]
                  }
               }
               let serviceLevelTarget1 = [
                  (serviceLevelTarget.performances[0].value).toFixed(1),
                  (serviceLevelTarget.performances[1].value).toFixed(1),
                  (serviceLevelTarget.performances[2].value).toFixed(1),
                  (serviceLevelTarget.performances[3].value).toFixed(1),
                  (serviceLevelTarget.performances[4].value).toFixed(1),
                  (serviceLevelTarget.performances[5].value).toFixed(1),
                  (serviceLevelTarget.performances[6].value).toFixed(1),
                  (serviceLevelTarget.performances[7].value).toFixed(1),
                  (serviceLevelTarget.performances[8].value).toFixed(1),
                  (serviceLevelTarget.performances[9].value).toFixed(1),
                  (serviceLevelTarget.performances[10].value).toFixed(1),
                  (serviceLevelTarget.performances[11].value).toFixed(1)
               ];
               let targetPerformance = [
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance,
                  serviceLevelTarget.targetPerformance
               ]
               this.setState({
                  dataNumberOfCall: dataNumberOfCall,
                  serviceLevelTarget: serviceLevelTarget1,
                  targetPerformance: targetPerformance,
                  selectedYear: year,
                  isloading: false
               });
               //               
            })
         .catch(error => {
            if (error.response) {
               console.log('Error', error.message);
            }
            this.setState({ isloading: false })
         });
   }

   getTowingLocation(month, year) {
      this.setState({ isloading: true })
      let url = `api/dashboard/towingLocation?month=${month}&year=${year}`;
      const options = MyService.getHeader("GET", this.state.partnerIndex, true);
      fetch(url, options)
         .then(
            async (response) => {
               let data = await response.json()
               this.checkForInvalidSession(data);
               let location = data.data.location;
               let locationOverseas = data.data.oversea;
               location = location.sort((a, b) => {
                  return (parseInt(b.locationValue) - parseInt(a.locationValue));
               })
               locationOverseas = locationOverseas.sort((a, b) => {
                  return (parseInt(b.locationValue) - parseInt(a.locationValue));
               })
               //locationOverseas = locationOverseas.reverse();

               let totalLocationValue = 0;
               let totalLocationValueOversea = 0;

               location.map((ev) => {
                  totalLocationValue = totalLocationValue + ev.locationValue
                  return (totalLocationValue);
               })
               locationOverseas.map((ev) => {
                  totalLocationValueOversea = totalLocationValueOversea + ev.locationValue
                  return (totalLocationValueOversea);
               })

               this.setState({
                  location: location,
                  locationOverseas: locationOverseas,
                  totalLocationValue: totalLocationValue,
                  totalLocationValueOversea: totalLocationValueOversea
               })

            })
         .catch(error => {
            console.log('Error', error.message);
            this.setState({ isloading: false })
         });
   }

   getServiceCenter(month, year) {
      let url = `api/dashboard/serviceCentre?month=${month}&year=${year}`;
      const options = MyService.getHeader("GET", this.state.partnerIndex, true);
      fetch(url, options)
         .then(
            async (response) => {
               let data = await response.json()
               this.checkForInvalidSession(data);
               let totalAccidentCase = 0;
               let totalBreakdownCase = 0;
               let serviceCenterPerformance = data.data.serviceCenterPerformance;

               serviceCenterPerformance.map((ev) => {
                  totalAccidentCase = totalAccidentCase + ev.accidentCase
                  totalBreakdownCase = totalBreakdownCase + ev.breakdownCase
                  return (totalAccidentCase, totalBreakdownCase);
               })
               this.setState({
                  serviceCenterPerformance: serviceCenterPerformance,
                  totalAccidentCase: totalAccidentCase,
                  totalBreakdownCase: totalBreakdownCase
               })
            })
         .catch(error => {
            console.log(error)
            if (error.response) {
               console.log('Error', error.message);
            }
         });
   }

   linkToCases(status) {
      let caseStatus = {
         status: status,
         selectDate: this.state.selectDate,
         partnerIndex: this.state.partnerIndex,
         partnerId: this.state.partnerId
      }
      window.localStorage.setItem("caseStatus", JSON.stringify(caseStatus));
      window.localStorage.removeItem("dataState")
      this.props.history.push(`/cases`)
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
   }

   handleClickOutside(event) {
      if (this.wrapperRefClientName && !this.wrapperRefClientName.contains(event.target)) {
         this.setState({
            hideDropDownClientName: true
         })
      }
      if (this.wrapperRefLogOut && !this.wrapperRefLogOut.contains(event.target)) {
         this.setState({
            hideDropDownMenu: true
         })
      }
      if (this.wrapperRefReport && !this.wrapperRefReport.contains(event.target)) {
            this.setState({
                hideDropDownMenuReport: true
            })
        }
   }

   formatNumber(num) {
      let value = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      if (value === "NaN") {
         return 0;
      }
      return value;
   }

   checkForInvalidSession(data) {
      if (data.message === "invalid_user_session" || data.error_code === "invalid_user_session" || data.error_code === "invalid_refresh_token") {
         MyService.logoutForSession();
      }
   }

   checkCaseDetailRedirect() {
      let caseDetailRedirectUrl = window.localStorage.getItem("caseDetailRedirectUrl");
      if (caseDetailRedirectUrl != null && caseDetailRedirectUrl != "") {
         let text = caseDetailRedirectUrl;
         console.log("Checking clipboard redirect:", "Found text in clipboard " + text);
         let parts = text.split("/");
         let len = parts.length;
         if (len > 2 && text.includes("/cases/")) {
            if (!isNaN(parts[len - 1])) {
               console.log("Checking clipboard redirect", "Found case detail path: " + `${parts[len - 2]}/${parts[len - 1]}`);
               this.props.history.push(`${parts[len - 2]}/${parts[len - 1]}`);
            }
         }
         else {
            this.props.history.push(`/`);
         }
         window.localStorage.setItem("caseDetailRedirectUrl", "");
      }
   }

    displayMultiLineAlert() {
       var newLine = "\r\n" + "\r\n"
                var newLineSpace = "\r\n"
                var message = ""
                message += newLine;
                message += "Effective 1st June 2023, Asia Assistance Network will be known as 𝐄𝐮𝐫𝐨𝐩 𝐀𝐬𝐬𝐢𝐬𝐭𝐚𝐧𝐜𝐞. As we unfold a new chapter, we look forward to introducing more innovative assistance services to serve your needs.";
                message += newLine;
                
                alert(message);       
       
    }

    showEADialog(){
                var q = new Date();
                var m = q.getMonth();
                var d = q.getDate();
                var y = q.getFullYear();
                var date = new Date(y, m, d);
                var estimatedEADate = new Date('2023-04-30');
                console.log("Date : " + date);
                console.log("EstimatedEADate : " + estimatedEADate);
                console.log("q : " + q);
                if (date > estimatedEADate)
                {
                  this.displayMultiLineAlert();
                }

    }

   componentDidMount() {
      //Adding Layout for EA Transition
      //this.showEADialog();
      this.checkCaseDetailRedirect();
      // requirement changed: minDate from 2018     
      let minDate = "";
      minDate = `2018-01-01`
      this.state.minDate = minDate;
      // Get profile data
      document.addEventListener('mousedown', this.handleClickOutside);
      const usercookie = Cookies.get('user');
      if (usercookie) {
         const user = JSON.parse(usercookie);
         let name = user.userInfo.name;
         let avatarLetter = name.substring(0, 1).toUpperCase();
         if (!user.userInfo["https://asia-assistance.com/partnerIds"] || user.userInfo["https://asia-assistance.com/partnerIds"] === null) {
            user.userInfo["https://asia-assistance.com/partnerIds"] = [user.userInfo["https://asia-assistance.com/partnerId"]];
         }
         this.setState({ reload: true, partnerIds: user.userInfo["https://asia-assistance.com/partnerIds"], avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
         let partner = {};
         partner = JSON.parse(window.localStorage.getItem("partner"));
         if (partner != null) {
            this.state.partnerIndex = partner.partnerIndex;
            this.state.partnerId = partner.partnerId
            this.state.partnerId1 = partner.partnerId1
         }
         else {
            if (user.userInfo["https://asia-assistance.com/partnerIds"].length > 1) {
               partner = {
                  partnerIndex: 0,
                  partnerId: user.userInfo["https://asia-assistance.com/partnerIds"][0],
                  partnerId1: user.userInfo["https://asia-assistance.com/partnerIds"][1]
               }
               this.state.partnerId = user.userInfo["https://asia-assistance.com/partnerIds"][0];
               this.state.partnerId1 = user.userInfo["https://asia-assistance.com/partnerIds"][1];
               this.state.partnerIndex = 0;
            }
            else {
               partner = {
                  partnerIndex: 0,
                  partnerId: user.userInfo["https://asia-assistance.com/partnerIds"][0],
                  partnerId1: ""
               }
               this.state.partnerId = user.userInfo["https://asia-assistance.com/partnerIds"][0]
               this.state.partnerId1 = "";
               this.state.partnerIndex = 0;
            }
            window.localStorage.setItem("partner", JSON.stringify(partner))
         }
         let caseStatus = {}
         caseStatus = JSON.parse(window.localStorage.getItem("caseStatus"));
         if (caseStatus != null) {
            this.state.selectDate = new Date(caseStatus.selectDate)
            let currentDate = new Date(caseStatus.selectDate);
            let currentMonth = Moment(currentDate).format("M");
            let currentYear = Moment(currentDate).format("YYYY");
            this.getDashboardData(currentMonth, currentYear);
            this.getTowingLocation(currentMonth, currentYear);
            this.getServiceCenter(currentMonth, currentYear);
         }
         else {
            let currentDate = this.state.selectDate;
            let currentMonth = Moment(currentDate).format("M");
            let currentYear = Moment(currentDate).format("YYYY");
            this.getDashboardData(currentMonth, currentYear);
            this.getTowingLocation(currentMonth, currentYear);
            this.getServiceCenter(currentMonth, currentYear);
         }
      }
      else {
         window.location.href = "/login"
      }
   }

   switchPartnerId() {
      let index = 0;
      index = this.findIndex(this.state.partnerIds, this.state.partnerId1)
      this.state.partnerIndex = index;
      this.state.hideDropDownMenu = true;
      let partner = {};
      if (index == 0) {
         partner = {
            partnerIndex: 0,
            partnerId: this.state.partnerIds[0],
            partnerId1: this.state.partnerIds[1]
         }
         this.state.partnerId = this.state.partnerIds[0];
         this.state.partnerId1 = this.state.partnerIds[1]
         this.state.partnerIndex = 0;
      }
      if (index == 1) {
         partner = {
            partnerIndex: 1,
            partnerId: this.state.partnerIds[1],
            partnerId1: this.state.partnerIds[0]
         }
         this.state.partnerId = this.state.partnerIds[1];
         this.state.partnerId1 = this.state.partnerIds[0]
         this.state.partnerIndex = 1;
      }
      window.localStorage.setItem("partner", JSON.stringify(partner));
      let currentDate = this.state.selectDate;
      let currentMonth = Moment(currentDate).format("M");
      let currentYear = Moment(currentDate).format("YYYY");
      this.getDashboardData(currentMonth, currentYear);
      this.getTowingLocation(currentMonth, currentYear);
      this.getServiceCenter(currentMonth, currentYear);
   }

   hideDropDownLogout() {
      if (this.state.hideDropDownMenu) {
         this.setState({ hideDropDownMenu: false })
      }
      else {
         this.setState({ hideDropDownMenu: true })
      }
   }
   
   hideDropDownReport() {
      if (this.state.hideDropDownMenuReport) {
         this.setState({ hideDropDownMenuReport: false })
      }
      else {
         this.setState({ hideDropDownMenuReport: true })
      }
   }
  
   hideDropDownClientName() {
      if (this.state.hideDropDownClientName) {
         this.setState({ hideDropDownClientName: false })
      }
      else {
         this.setState({ hideDropDownClientName: true })
      }

   }

   onMouseHandleClientName(id, action) {
      let element_id = document.getElementById(id)
      element_id.style.backgroundColor = action == "over" ? "#00008F" : "#FFFFFF";
      element_id.style.color = action == "over" ? "#FFFFFF" : "#2B2D3D";
   }

   onClickClientName(ev) {
      let index = 0;
      index = this.findIndex(this.state.partnerIds, ev)
      this.setState({ partnerId: ev, hideDropDownClientName: true, hideDropDownMenu: true, hideDropDownMenuReport: true })
      this.state.partnerIndex = index;
      let partner = {};
      let size = this.state.partnerIds.length;
      
      for(let i = 0; i < size; i++) {
        if (index == i) {
            partner = {
                partnerIndex: i,
                partnerId: this.state.partnerIds[i],
                partnerId1: this.state.partnerIds[1]
            }
            this.state.partnerId = this.state.partnerIds[i];
            this.state.partnerId1 = this.state.partnerIds[1];
            this.state.partnerIndex = i;
        }   
      }
      /**
      if (index == 0) {
         partner = {
            partnerIndex: 0,
            partnerId: this.state.partnerIds[0],
            partnerId1: this.state.partnerIds[1]
         }
         this.state.partnerId = this.state.partnerIds[0];
         this.state.partnerId1 = this.state.partnerIds[1]
         this.state.partnerIndex = 0;
      }
      if (index == 1) {
         partner = {
            partnerIndex: 1,
            partnerId: this.state.partnerIds[1],
            partnerId1: this.state.partnerIds[0]
         }
         this.state.partnerId = this.state.partnerIds[1];
         this.state.partnerId1 = this.state.partnerIds[0]
         this.state.partnerIndex = 1;
      }*/
      
      window.localStorage.setItem("partner", JSON.stringify(partner));
      //Reset data with new partnerId
      let currentDate = this.state.selectDate;
      let currentMonth = Moment(currentDate).format("M");
      let currentYear = Moment(currentDate).format("YYYY");
      this.getDashboardData(currentMonth, currentYear);
      this.getTowingLocation(currentMonth, currentYear);
      this.getServiceCenter(currentMonth, currentYear);
   }

   onChangeDatePicker(date) {
      {
         window.localStorage.removeItem("caseStatus");
         this.getDashboardData(Moment(date).format("M"), Moment(date).format("Y"));
         this.getTowingLocation(Moment(date).format("M"), Moment(date).format("Y"));
         this.getServiceCenter(Moment(date).format("M"), Moment(date).format("Y"));
         this.setState({ selectDate: date })
      }
   }

   onClickOpenReport() {
      let currentMonth = Moment(this.state.selectDate).format("M");
      let currentYear = Moment(this.state.selectDate).format("YYYY");
      this.getSLACall(currentMonth, currentYear)
      this.getSLAGraph(currentMonth, currentYear)
      this.setState({ isOpenReport: true })
   }

   toggleModalLogOut() {
      let isOpenLogout = !this.state.isOpenLogout
      this.setState({
         isOpenLogout: isOpenLogout
      })
   }

   render() {
      return (
         <div>
            {
               this.state.reload ?
                  <>
                     <Layout name="dashboard" title="Dashboard">
                        {/* SLA Report */}
                        <Modal
                           backdrop="static"
                           top
                           size="lg"
                           style={{ maxWidth: '1400px', width: '96%' }}
                           isOpen={this.state.isOpenReport}
                           toggle={() => this.toggleModalReport()}>
                           <ModalHeader
                              toggle={() => this.toggleModalReport()}>
                              <div className="modal-title sla-modal-title">Service Level</div>
                           </ModalHeader>
                           <SlaReport
                              selectedYear={this.state.selectedYear}
                              selectDate={this.state.selectDate}
                              serviceLevelTarget={this.state.serviceLevelTarget}
                              targetPerformance={this.state.targetPerformance}
                              partnerId={this.state.partnerId}
                              toggle={this.state.toggle}
                              wholeYearCallCurrentYear={this.state.wholeYearCallCurrentYear}
                              wholeYearCallLastYear={this.state.wholeYearCallLastYear}
                              untilNowCallCurrentYear={this.state.untilNowCallCurrentYear}
                              untilNowCallLastYear={this.state.untilNowCallLastYear}
                              thisMonthCallCurrentYear={this.state.thisMonthCallCurrentYear}
                              thisMonthCallLastYear={this.state.thisMonthCallLastYear}
                              wholeYearCallCurrentYear={this.state.wholeYearCallCurrentYear}
                              dataNumberOfCall={this.state.dataNumberOfCall}
                              options={this.state.options}
                              optionsSla={this.state.optionsSla}
                           />
                        </Modal>
                        {/* End Modal */}
                        <div className="fixed-top">
                           <TopNavigation
                              name="dashboard"
                              hidesubId={false}
                              profile={this.state.profile}
                              partnerIds={this.state.partnerIds}
                              partnerId={this.state.partnerId}
                              partnerId1={this.state.partnerId1}
                              history={this.props.history}
                              role={this.state.role}
                              avatarLetter={this.state.avatarLetter}
                              switchPartnerId={() => this.switchPartnerId()}
                              hideDropDownMenu={this.state.hideDropDownMenu}
                              hideDropDownMenuReport={this.state.hideDropDownMenuReport}
                              setWrapperRefLogOut={this.setWrapperRefLogOut}
                              setWrapperRefReport={this.setWrapperRefReport}
                              hideDropDownLogout={() => this.hideDropDownLogout()}
                              hideDropDownReport={() => this.hideDropDownReport()}
                           />
                        </div>
                        {/*  End Nav*/}
                        <BreadCrumbs name={this.state.name} subid={this.state.subid} history={this.props.history} />
                        {/*  */}
                        <div class="row dashboard-content-section">
                           <div class="col-md-12">
                              <div class="row">
                                 <div class="col-md-6" >
                                    <table className="mt-7px">
                                        <tr>
                                            <td rowspan="2">
                                                <span className="mr-5px">
                                                    {
                                                       this.state.partnerId == 'LIBERTY_MY' ?
                                                       
                                                        <img className="partnerid-logo" style={{ width: "150px" }} src={MyService.getClientDetailFromCode(this.state.partnerId).logo} />
                                                       : 
                                                        null
                                                       
                                                    }
                                                   
                                                    {
                                                       this.state.partnerId == 'PERODUA' ?
                                                       
                                                        <img className="partnerid-logo" style={{ height: "80px", width: "130px" }} src={MyService.getClientDetailFromCode(this.state.partnerId).logo} />
                                                       : 
                                                        null
                                                     
                                                    }
                                                    
                                                    {
                                                       (this.state.partnerId != 'LIBERTY_MY' && this.state.partnerId != 'PERODUA') ?
                                                       
                                                       <img className="partnerid-logo" src={MyService.getClientDetailFromCode(this.state.partnerId).logo} />  
                                                       : 
                                                       null
                                                       
                                                    }
                                                </span>
                                            </td>
                                            <td>
                                                <span className="partnerid-name">
                                                      {
                                                         MyService.getClientDetailFromCode(this.state.partnerId).name
                                                      }
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span ref={this.setWrapperRefClientName}>
                                                   
                                                   <span className="partnerid-program" onClick={() => this.hideDropDownClientName()}>
                                                      {
                                                         MyService.getClientDetailFromCode(this.state.partnerId).program
                                                      }
                                                   </span>
                                                   {
                                                      this.state.partnerIds.length > 1 ?
                                                         <>
                                                            <span className="ml-5px" onClick={() => this.hideDropDownClientName()}><img className="dropdown-image" src="/static/images/Dropdown2@3x.png" alt="User Image" /></span>
                                                            <ul hidden={this.state.hideDropDownClientName} class="list-group dropdown-menu" style={{ width: "50%", marginLeft: MyService.getClientDetailFromCode(this.state.partnerId).padding }}>
                                                               {
                                                                  this.state.partnerIds.map((ev, index) => {
                                                                     return (
                                                                        <li id={ev}
                                                                           onMouseOver={(e) => this.onMouseHandleClientName(ev, "over")}
                                                                           onMouseOut={(e) => this.onMouseHandleClientName(ev, "out")}
                                                                           onClick={() => this.onClickClientName(ev)} class="list-group-item font-semi black-font Source-SansPro-Regular font-size-12">
                                                                           {
                                                                              MyService.getClientDetailFromCode(ev).name
                                                                           }
                                                                        </li>
                                                                     );
                                                                  })
                                                               }
                                                            </ul>
                                                         </>
                                                         :
                                                         null
                                                   }
                                                </span>
                                            </td>
                                       </tr>
                                    </table>
                                 </div>
                                 <div class="col-md-2">
                                 </div>
                                 <div class="col-md-4 pt-10px">
                                    <div class="row mt-8px">
                                       <div class="col-md-12">
                                          <div className="d-flex justify-content-end">
                                             <div>
                                             <div class="input-group">
                                             <span className="show-title mr-20px">
                                                Show:
                                             </span>
                                             <DatePicker
                                                customInput={<ExampleCustomInput />}
                                                popperPlacement="top-start"
                                                id="month"
                                                selected={this.state.selectDate}
                                                onChange={(date) => this.onChangeDatePicker(date)}
                                                maxDate={new Date()}
                                                minDate={new Date(this.state.minDate)}
                                                dateFormat="MMM, yyyy"
                                                showMonthYearPicker
                                             />
                                             {
                                                this.state.isloading ?
                                                   <div class="input-group-prepend loading-container">
                                                      <button class="btn loading-button" >
                                                         <i class="fa fa-spinner fa-spin font-size-18" ></i>
                                                      </button>
                                                   </div>
                                                   :
                                                   <div onClick={() => {
                                                      $('#month').click()
                                                   }} class="input-group-prepend unloading-container">
                                                      <img className="calendar-image" src="/static/images/Calendar@3x.png" />
                                                   </div>
                                             }
                                          </div>

                                             </div>
                                           </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {
                           this.state.isLoading ?
                              <div class="row loader height-100"></div>
                              :
                              <>
                                 <div class="row widgets-content-section" style={{ marginTop: "-1px" }}>
                                    <div class="col-md-3">
                                       <div class="card shadow-none height-100px" id="TotalCases" onClick={(e) => this.linkToCases("ALL")}>
                                          <div class="card-body" style={{cursor: "pointer"}}>
                                             <div class="row">
                                                <div class="col-md-3 text-center">
                                                   <img className="widget-card" src="/static/images/Indicator_Total Cases@3x.png" />
                                                </div>
                                                <div class="col-md-9 text-left mt_minis_5px">
                                                   <div className="ml-40px">
                                                      <div className="widget-number">
                                                         {
                                                            this.formatNumber(parseInt(this.state.dashboardCase.totalCase))/*  this.state.dashboardCase.totalCase */
                                                         }
                                                      </div>
                                                      <div className="widget-title">
                                                         Total Cases
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="card shadow-none height-100px" id="OpenedCases" onClick={(e) => this.linkToCases("OPEN")}>
                                          <div class="card-body" style={{cursor: "pointer"}}>
                                             <div class="row">
                                                <div class="col-md-3 text-center">
                                                   <img className="widget-card" src="/static/images/Indicator_Opened Case@3x.png" />
                                                </div>
                                                <div class="col-md-9 text-left mt_minis_5px">
                                                   <div className="ml-40px">
                                                      <div className="widget-number">
                                                         {
                                                            this.formatNumber(parseInt(this.state.dashboardCase.openCase))
                                                         }
                                                      </div>
                                                      <div className="widget-title">
                                                         Opened Cases
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="card shadow-none height-100px" id="TotalUnverifiedCases" onClick={(e) => this.linkToCases("UNVERIFIED")}>
                                          <div class="card-body" style={{cursor: "pointer"}}>
                                             <div class="row">
                                                <div class="col-md-3 text-center">
                                                   <img className="widget-card" src="/static/images/Indicator_Unverified Cases@3x.png" />
                                                </div>
                                                <div class="col-md-9 text-left mt_minis_5px">
                                                   <div className="ml-40px">
                                                      <div className="widget-number">
                                                         {
                                                            this.formatNumber(parseInt(this.state.dashboardCase.unverifiedCase))
                                                         }
                                                      </div>
                                                      <div className="widget-title">
                                                         Unverified Cases
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="card shadow-none height-100px" id="CancelledCases" onClick={(e) => this.linkToCases("CANCELLED")} >
                                          <div class="card-body" style={{cursor: "pointer"}}>
                                             <div class="row">
                                                <div class="col-md-3 text-center">
                                                   <img className="widget-card" src="/static/images/Indicator_Cancelled cases@3x.png" />
                                                </div>
                                                <div class="col-md-9 text-left mt_minis_5px">
                                                   <div className="ml-40px">
                                                      <div className="widget-number">
                                                         {
                                                            this.formatNumber(parseInt(this.state.dashboardCase.cancelledCase))
                                                         }
                                                      </div>
                                                      <div className="widget-title">
                                                         Cancelled Cases
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="row cards-content-section">
                                    <div class="col-md-9">
                                       <div class="row">
                                          <div class="col-md-4">
                                             <div class="card cases-card">
                                                <div class="card-header card-header-background">
                                                   <div className="card-header-title">
                                                      Total Verified Cases
                                                   </div>
                                                </div>
                                                <div class="card-body vertical-align">
                                                   <div class="chart">
                                                      <canvas id="totalVerifiedCases" style={{ maxHeight: "170px" }} />
                                                   </div>
                                                   <div className="total-case">
                                                      {
                                                         this.state.dashboardCase.totalCase > 0 ?
                                                            <div>
                                                               {
                                                                  ((this.state.dashboardCase.totalCase - this.state.dashboardCase.unverifiedCase) * 100 / this.state.dashboardCase.totalCase).toFixed(1)
                                                               }%
                                                         </div>
                                                            :
                                                            <div> 0% </div>
                                                      }
                                                   </div>
                                                   <div className="verified-cases">
                                                      {
                                                         !this.state.dashboardCase || this.state.dashboardCase === "NaN" ? 0 : this.state.dashboardCase.totalCase - this.state.dashboardCase.unverifiedCase
                                                      } Verified cases
                                                   </div>
                                                </div>
                                             </div>
                                             <div class="card service-card" onClick={() => this.onClickOpenReport()}>
                                                <div class="card-header card-header-background">
                                                   <div class="row">
                                                      <div class="col-md-6 text-left">
                                                         <div className="card-header-title">
                                                            Service Level
                                                         </div>
                                                      </div>
                                                      <div class="col-md-6 text-right" >
                                                         <img src="/static/images/More_Fill.png" />
                                                      </div>
                                                   </div>
                                                </div>
                                                <div class="card-body">
                                                   <div class="row mt-0px">
                                                      <div class="col-md-4 text-center">
                                                         <img className="service-image mt-12px" src="/static/images/Service_gray@3x.png" />
                                                      </div>
                                                      <div class="col-md-8 text-left">
                                                         <div>
                                                            {
                                                               this.state.serviceLevel.serviceLevel > 0
                                                                  ?
                                                                  <>
                                                                     {
                                                                        this.state.serviceLevel.serviceLevel < 75 ?
                                                                           <span className="serviceLevel74">
                                                                              {

                                                                                 (this.state.serviceLevel.serviceLevel)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                     {
                                                                        this.state.serviceLevel.serviceLevel >= 75 && this.state.serviceLevel.serviceLevel <= 80 ?
                                                                           <span className="serviceLevel80">
                                                                              {
                                                                                 (this.state.serviceLevel.serviceLevel)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                     {
                                                                        this.state.serviceLevel.serviceLevel > 80 ?
                                                                           <span className="serviceLevel81">
                                                                              {
                                                                                 (this.state.serviceLevel.serviceLevel)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                  </>
                                                                  : (this.state.serviceLevel.totalCallOffered > 0 ? < span className="servicelevel">{this.state.serviceLevel.serviceLevel}%</span> :
                                                                     < span className="STIX-General-Bold font-size-28">0%</span>)
                                                            }
                                                         </div>
                                                         <div className="compliance-rate">
                                                            Compliance Rate
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="row mt-10px">
                                                      <div class="col-md-4 text-center">
                                                         <img className="service-image mt-12px" src="/static/images/CallAbandoned_gray@3x.png" />
                                                      </div>
                                                      <div class="col-md-8 text-left">
                                                         <div>
                                                            {
                                                               this.state.serviceLevel.abandonRates > 0 && this.state.serviceLevel.totalCallOffered > 0
                                                                  ?
                                                                  <>
                                                                     {
                                                                        this.state.serviceLevel.abandonRates < 5 ?
                                                                           <span className="abandonrates4">
                                                                              {

                                                                                 (this.state.serviceLevel.abandonRates)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                     {
                                                                        this.state.serviceLevel.abandonRates >= 5 && this.state.serviceLevel.abandonRates <= 7 ?
                                                                           <span className="abandonrates7">
                                                                              {
                                                                                 (this.state.serviceLevel.abandonRates)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                     {
                                                                        this.state.serviceLevel.abandonRates > 7 ?
                                                                           <span className="abandonrates8">
                                                                              {
                                                                                 (this.state.serviceLevel.abandonRates)
                                                                              }%
                                                                           </span>
                                                                           :
                                                                           null
                                                                     }
                                                                  </>
                                                                  : (this.state.serviceLevel.totalCallOffered > 0 ?
                                                                     < span className="abandonrates">{this.state.serviceLevel.abandonRates}%</span> : <span className="STIX-General-Bold font-size-28">0%</span>)
                                                            }
                                                         </div>
                                                         <div className="compliance-rate">
                                                            Abandon Rate
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <hr />
                                                   <div class="row">
                                                      <div class="col-md-8 text-center total-call-offer">
                                                         Total calls offered
                                                      </div>
                                                      <div class="col-md-4 text-left STIX-General-Bold font-size-22 black-font mt_minis_4px">
                                                         {
                                                            this.formatNumber(parseInt(this.state.serviceLevel.totalCallOffered))
                                                         }
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-md-8">
                                             <div class="card category-card">
                                                <div class="card-header card-header-background">
                                                   <div className="card-header-title">
                                                      Case Category
                                                   </div>
                                                </div>
                                                <div class="card-body">
                                                   <div class="chart">
                                                      <canvas className="category-chart" id="caseCategory" />
                                                   </div>
                                                   <table class="table no-border mt-40px">
                                                      <thead className="category_thead-background">
                                                         <tr className="category_tr" >
                                                            <th>
                                                               <nobr>
                                                                  <span>
                                                                     <span><div className="accident-indication">
                                                                     </div></span>
                                                                     <span className="top-vertical-align">Accident</span>
                                                                  </span>
                                                               </nobr>
                                                            </th>
                                                            <th>
                                                               <nobr>
                                                                  <span>
                                                                     <span><div className="breakdown-indication">
                                                                     </div>
                                                                     </span>
                                                                     <span className="top-vertical-align">Breakdown</span>
                                                                  </span>
                                                               </nobr>
                                                            </th>
                                                            <th>
                                                               <nobr>
                                                                  <span>
                                                                     <span> <div className="enquiry-indication">
                                                                     </div>
                                                                     </span>
                                                                     <span className="top-vertical-align"> Enquiry</span>
                                                                  </span>
                                                               </nobr>
                                                            </th>
                                                            <th>
                                                               <nobr>
                                                                  <span>
                                                                     <span> <div className="others-indication">
                                                                     </div>
                                                                     </span>
                                                                     <span className="top-vertical-align"> Others</span>
                                                                  </span>
                                                               </nobr>
                                                            </th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         {
                                                            this.state.isloading ?
                                                               null
                                                               :
                                                               <tr>
                                                                  <td>
                                                                     <div className="ml-28px">
                                                                        <div className="case-percent">
                                                                           {
                                                                              this.state.caseCategory.accident == 0 ?
                                                                                 <>
                                                                                    {
                                                                                       this.state.caseCategory.accident
                                                                                    }%
                                                                                 </>
                                                                                 :
                                                                                 <>
                                                                                    {
                                                                                       this.formatNumber((this.state.caseCategory.accident * 100 / this.state.totalCaseCategory).toFixed(1))
                                                                                    }%
                                                                                 </>
                                                                           }
                                                                        </div>
                                                                        <div className="case-number" >
                                                                           {
                                                                              this.formatNumber(parseInt(this.state.caseCategory.accident))
                                                                           } cases</div>
                                                                     </div>
                                                                  </td>
                                                                  <td>
                                                                     <div className="ml-28px">
                                                                        <div className="case-percent">
                                                                           {
                                                                              this.state.caseCategory.breakdown == 0 ?
                                                                                 <>
                                                                                    {
                                                                                       this.state.caseCategory.breakdown
                                                                                    }%
                                                                                 </>
                                                                                 :
                                                                                 <>
                                                                                    {
                                                                                       this.formatNumber((this.state.caseCategory.breakdown * 100 / this.state.totalCaseCategory).toFixed(1))
                                                                                    }%
                                                                                 </>
                                                                           }
                                                                        </div>
                                                                        <div className="case-number">
                                                                           {
                                                                              this.formatNumber(parseInt(this.state.caseCategory.breakdown))
                                                                           } cases</div>
                                                                     </div>
                                                                  </td>
                                                                  <td>
                                                                     <div className="ml-22px">
                                                                        <div className="case-percent">
                                                                           {
                                                                              this.state.caseCategory.inquiry == 0 ?
                                                                                 <>
                                                                                    {
                                                                                       this.state.caseCategory.inquiry
                                                                                    }%
                                                                                 </>
                                                                                 :
                                                                                 <>
                                                                                    {
                                                                                       this.formatNumber((this.state.caseCategory.inquiry * 100 / this.state.totalCaseCategory).toFixed(1))
                                                                                    }%
                                                                                 </>
                                                                           }
                                                                        </div>
                                                                        <div className="case-number" >
                                                                           {
                                                                              this.formatNumber(parseInt(this.state.caseCategory.inquiry))
                                                                           } cases</div>
                                                                     </div>
                                                                  </td>
                                                                  <td>
                                                                     <div className="ml-22px">
                                                                        <div className="case-percent">
                                                                           {
                                                                              this.state.caseCategory.others == 0 ?
                                                                                 <>
                                                                                    {
                                                                                       this.state.caseCategory.others
                                                                                    }%
                                                                                 </>
                                                                                 :
                                                                                 <>
                                                                                    {
                                                                                       this.formatNumber((this.state.caseCategory.others * 100 / this.state.totalCaseCategory).toFixed(1))
                                                                                    }%
                                                                                 </>
                                                                           }
                                                                        </div>
                                                                        <div className="case-number" >
                                                                           {
                                                                              this.formatNumber(parseInt(this.state.caseCategory.others))
                                                                           } cases</div>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                         }
                                                      </tbody>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       {
                                          this.state.dashboardToggle.serviceCenterPerformance ?
                                             <ServiceCenterPerformance serviceCenterPerformance={this.state.serviceCenterPerformance} />
                                             :
                                             null
                                       }
                                    </div>
                                    <div class="col-md-3">
                                       <div class="card offered-card" >
                                          <div class="card-header card-header-background">
                                             <div className="card-header-title">
                                                Total Services Offered
                                             </div>
                                          </div>
                                          <div class="card-body">
                                             <div class="row">
                                                <div class="col-md-12 text-center" >
                                                   <div className="total-services-number">
                                                      {this.formatNumber(parseInt(this.state.dashboardCase.totalServices))}
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       {/*  */}
                                       <div class="card location-card">
                                          <div class="card-header card-header-background">
                                             <div className="card-header-title">
                                                Case by Towing Location
                                             </div>
                                          </div>
                                          <div class="card-body">
                                             <TowingLocation location={this.state.location} locationOverseas={this.state.locationOverseas} totalLocationValue={this.state.totalLocationValue} totalLocationValueOversea={this.state.totalLocationValueOversea} />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>
                        }
                        {/*  */}
                     </Layout>
                  </>
                  :
                  null
            }
         </div>
      );
   }
}
export default withRouter(Dashboard)
