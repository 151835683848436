import React, {useState} from 'react';
import Cookies from 'js-cookie';
import Layout from './layout';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import MyService from "./services/my-service";
import ReactPaginate from 'react-paginate';
import $ from "jquery";
import 'font-awesome/css/font-awesome.min.css';
import BreadCrumbs from "./breadcrumbs";
import TopNavigation from "./topnavigation";
import "react-datepicker/dist/react-datepicker.css";
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';

const ExampleCustomInputMonth = ({ value, onClick }) => (

   <input id="monthInput" type="text" value={value} disabled onClick={onClick} class="form-control calendar_input" style={{cursor: "pointer", borderRightColor: "#CCCEDB" }} />
);

const ExampleCustomInputStart = ({ value, onClick }) => (
    
    <input id="monthStart" type="text" value={value} onClick={onClick} class="form-control calendar_input" placeholder="From Date" style={{ borderRightColor: "#CCCEDB", cursor: "pointer" }}/>
);

const ExampleCustomInputEnd = ({ value, onClick }) => (
    
    <input id="monthEnd" type="text" value={value} onClick={onClick} class="form-control calendar_input" placeholder="To Date" style={{ borderRightColor: "#CCCEDB", cursor: "pointer" }} />
);

const DatePickerRange = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [newStartDate, setNewStartDate] = useState(window.localStorage.getItem("prevMonthStart") === null ? startDate : new Date(window.localStorage.getItem("prevMonthStart")));
    const [newEndDate, setNewEndDate] = useState(window.localStorage.getItem("prevMonthEnd") === null ? endDate : new Date(window.localStorage.getItem("prevMonthEnd")));
    
    return (
        <div className="row">
            <div> 
                <DatePicker
                    customInput={<ExampleCustomInputStart />}
                    selected={newStartDate}
                    onChange={date => {setNewStartDate(date);setNewEndDate(date)}}
                    selectsStart
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={new Date("01/01/2018")}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabledKeyboardNavigation
                />
            </div>
            &nbsp;
            <div>
                <DatePicker
                    customInput={<ExampleCustomInputEnd />}
                    selected={newEndDate}
                    onChange={date => setNewEndDate(date)}
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={newStartDate}
                    maxDate={addYears(newStartDate, 1)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        </div>
    );
};

class TowingDestinationReports extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            claimsArray: [],
            minDate: "",
            name: "TowingDestinationReports",
            subid: "",
            avatarLetter: "",
            role: "",
            profile: {},
            isOpenLogout: false,
            currentPage: 0,
            rangeIndex: "",
            max_results: 20,
            pageCount: 0,
            id: this.props.id,
            total: "",
            pageCount: 0,
            hideDropDown: true,
            hideDropDownCase: true,
            hideDropDownModeType: true,
            hideDropDownMenu: true,
            hideDropDownMenuReport: true,
            offset: 0,
            selectDate: new Date(),
            selectMonth: new Date(),
            selectYear: new Date(),
            selectMode: "",
            cases: "",
            casesType: "",
            searchField: "",
            placeholder: "Enter Car Plate No.",
            placeholderCase: "All Cases",
            placeholderModeType: "Monthly",
            reload: false,
            loading: false,
            services: {},
            dataState: {},
            readOnlyCalendar: true,
            disableButton: true,
            displayDownload: false,
            disableGenerate: false,
            isDownload: false,
            dashboardCase: {},
            partnerId: "",
            partnerId1: "",
            partnerIds: [],
            partnerIndex: 0,
            selectStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            selectEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }
        this.setWrapperRefAllCase = this.setWrapperRefAllCase.bind(this);
        this.setWrapperRefModeType = this.setWrapperRefModeType.bind(this);
        this.setWrapperSearch = this.setWrapperSearch.bind(this);
        this.setWrapperRefLogOut = this.setWrapperRefLogOut.bind(this);
        this.setWrapperRefReport = this.setWrapperRefReport.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    findIndex(arr, text) {
        let index = 0;
        for (let i = 0; i < arr.length; i++) {
            if (text == arr[i]) {
                index = i;
            }
        }
        return index;
    }

    toggleModalLogOut() {
        let isOpenLogout = !this.state.isOpenLogout
        this.setState({
            isOpenLogout: isOpenLogout
        })
    }

    callCaseApi(start_index, currentPage, case_statuses, service_code) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        let opened_date_since = "";
        let opened_date_until = ""
        if (this.state.selectDate) {
            let selectDate = this.state.selectDate;
            opened_date_since = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "01"
            opened_date_until = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "31"
        }
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    callCaseApi1(opened_date_since, opened_date_until, start_index, currentPage, case_statuses, service_code) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    downloadBinary(response, filename) {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response]))
        a.download = filename
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }

    exportDataApi(case_statuses, service_code) {
        let url = ``;
        this.setState({ isDownload: true })
        let opened_date_since = "";
        let opened_date_until = ""
        if (this.state.selectDate) {
            let selectDate = this.state.selectDate;
            opened_date_since = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "01"
            opened_date_until = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "31"
        }
        url = `api/reports/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.blob()
                    this.checkForInvalidSession(data);
                    let filename = `Cases_Export_${opened_date_since}_${opened_date_until}.xlsx`
                    this.downloadBinary(data, filename)
                    this.setState({ isDownload: false })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ isDownload: false })
            });
    }
    getDashboardData(month, year) {
        let url = `api/dashboard?month=${month}&year=${year}`;
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let dashboardCase = data.data.dashboardCase;
                    this.setState({
                        dashboardCase: dashboardCase,
                    })
                })
            .catch(error => {
                console.log(error)
                if (error.response) {
                    console.log('Error', error.message);
                }
            });
    }

    checkForInvalidSession(data) {
        if (data.message === "invalid_user_session" || data.error_code === "invalid_user_session" || data.error_code === "invalid_refresh_token") {
            MyService.logoutForSession();
        }
    }

    componentDidMount() {
        let minDate = "";
        minDate = `2020-01-01`
        this.state.minDate = minDate;
        document.addEventListener('mousedown', this.handleClickOutside);
        const usercookie = Cookies.get('user');
        const user = JSON.parse(usercookie);
        let name = user.userInfo.name;
        let avatarLetter = name.substring(0, 1).toUpperCase();
        if (!user.userInfo["https://asia-assistance.com/partnerIds"] || user.userInfo["https://asia-assistance.com/partnerIds"] === null) {
            user.userInfo["https://asia-assistance.com/partnerIds"] = [user.userInfo["https://asia-assistance.com/partnerId"]];
        }
        this.setState({ partnerIds: user.userInfo["https://asia-assistance.com/partnerIds"], avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
        let dataState = {};
        dataState = JSON.parse(window.localStorage.getItem("dataState"));
        let partner = {};
        partner = JSON.parse(window.localStorage.getItem("partner"));
        if (partner != null) {
            this.state.partnerIndex = partner.partnerIndex;
            this.state.partnerId = partner.partnerId
            this.state.partnerId1 = partner.partnerId1
        }
        if (usercookie) {
            const user = JSON.parse(usercookie);
            let name = user.userInfo.name;
            let avatarLetter = name.substring(0, 1).toUpperCase();
            this.setState({ avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
            if (dataState != null) {
                this.setState({
                    reload: true,
                    currentPage: dataState.currentPage,
                    rangeIndex: dataState.rangeIndex,
                    total: dataState.total,
                    pageCount: dataState.pageCount,
                    claimsArray: dataState.claimsArray,
                    selectDate: new Date(dataState.selectDate),
                    cases: dataState.cases,
                    casesType: dataState.casesType,
                    searchField: dataState.searchField,
                    placeholder: dataState.placeholder,
                    placeholderCase: dataState.placeholderCase,
                    placeholderModeType: dataState.placeholderModeType,
                    dashboardCase: dataState.dashboardCase,
                });
                window.localStorage.removeItem("dataState");
            }
            else {
                window.localStorage.removeItem("selectedDaily");
                window.localStorage.removeItem("prevMonthStart");
                window.localStorage.removeItem("prevMonthEnd");
                let caseStatus = {}
                caseStatus = JSON.parse(window.localStorage.getItem("caseStatus"));
                if (caseStatus != null) {
                    let placeholderCase = "";
                    let cases = "";
                    if (caseStatus.status == "OPEN") {
                        placeholderCase = "Opened Cases"
                        cases = "OPEN"
                    } else if (caseStatus.status == "ALL") {
                        placeholderCase = "All Cases"
                        cases = ""
                    } else if (caseStatus.status == "UNVERIFIED") {
                        placeholderCase = "Unverified Cases"
                        cases = "UNVERIFIED"
                    } else if (caseStatus.status == "CANCELLED") {
                        placeholderCase = "Cancelled Cases"
                        cases = "CANCELLED"
                    }
                    else if (caseStatus.status == "CLOSED") {
                        placeholderCase = "Closed Cases"
                        cases = "CLOSED"
                    }
                    this.state.cases = cases;
                    this.state.selectDate = new Date(caseStatus.selectDate)
                    this.setState({ reload: true, placeholderCase: placeholderCase })
                    this.callCaseApiEnhanced(0, 1, caseStatus.status, "", true);
                    let currentDate = new Date(caseStatus.selectDate)
                    let currentMonth = Moment(currentDate).format("M");
                    let currentYear = Moment(currentDate).format("YYYY");
                    this.getDashboardData(currentMonth, currentYear)
                }
                else {
                    const user = JSON.parse(usercookie);
                    this.setState({ reload: true })
                    //this.callCaseApi(0, 1, "", "");
                    let currentDate = this.state.selectDate;
                    let currentMonth = Moment(currentDate).format("M");
                    let currentYear = Moment(currentDate).format("YYYY");
                    this.getDashboardData(currentMonth, currentYear)
                }
                
            }
        }
        else {
            window.location.href = "/login"
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRefAllCase(node) {
        this.wrapperRefAllCase = node;
    }

    setWrapperRefLogOut(node) {
        this.wrapperRefLogOut = node;
    }

    setWrapperRefReport(node) {
        this.wrapperRefReport = node;
    }

    setWrapperRefModeType(node) {
        this.wrapperRefAllModeType = node;
    }

    setWrapperSearch(node) {
        this.wrapperSearch = node;
    }

    setWrapperRefMenu(node) {
        this.wrapperRefMenu = node;
    }

    handleClickOutside(event) {
        
        if (this.wrapperRefAllCase && !this.wrapperRefAllCase.contains(event.target)) {
            this.setState({
                hideDropDownCase: true
            })
        }
        if (this.wrapperRefAllModeType && !this.wrapperRefAllModeType.contains(event.target)) {
            this.setState({
                hideDropDownModeType: true
            })
        }
        if (this.wrapperSearch && !this.wrapperSearch.contains(event.target)) {
            this.setState({
                hideDropDown: true
            })
        }
        if (this.wrapperRefLogOut && !this.wrapperRefLogOut.contains(event.target)) {
            this.setState({
                hideDropDownMenu: true
            })
        }
        if (this.wrapperRefReport && !this.wrapperRefReport.contains(event.target)) {
            this.setState({
                hideDropDownMenuReport: true
            })
        }
    }

    handlePageClick = (e) => {
        let selectedPage = parseInt(e.selected) + 1;
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApiEnhanced(selectedPage * this.state.max_results - this.state.max_results, selectedPage, case_statuses, service_code, false);
    };

    hideDropDownMenu() {
        {
            if (this.state.hideDropDown) {
                this.setState({ hideDropDown: false })
            }
            else {
                this.setState({ hideDropDown: true })
            }
        }
    }

    hideDropDownLogout() {
        if (this.state.hideDropDownMenu) {
            this.setState({ hideDropDownMenu: false })
        }
        else {
            this.setState({ hideDropDownMenu: true })
        }
    }

    hideDropDownReport() {
        if (this.state.hideDropDownMenuReport) {
            this.setState({ hideDropDownMenuReport: false })
        }
        else {
            this.setState({ hideDropDownMenuReport: true })
        }
    }

    switchPartnerId() {
        let index = 0;
        index = this.findIndex(this.state.partnerIds, this.state.partnerId1)
        this.state.partnerIndex = index;
        this.state.hideDropDownMenu = true;
        let partner = {};
        if (index == 0) {
            partner = {
                partnerIndex: 0,
                partnerId: this.state.partnerIds[0],
                partnerId1: this.state.partnerIds[1]
            }
            this.state.partnerId = this.state.partnerIds[0];
            this.state.partnerId1 = this.state.partnerIds[1]
            this.state.partnerIndex = 0;
        }
        if (index == 1) {
            partner = {
                partnerIndex: 1,
                partnerId: this.state.partnerIds[1],
                partnerId1: this.state.partnerIds[0]
            }
            this.state.partnerId = this.state.partnerIds[1];
            this.state.partnerId1 = this.state.partnerIds[0]
            this.state.partnerIndex = 1;
        }
        window.localStorage.setItem("partner", JSON.stringify(partner));
        let currentDate = this.state.selectDate;
        let currentMonth = Moment(currentDate).format("M");
        let currentYear = Moment(currentDate).format("YYYY");
        this.getDashboardData(currentMonth, currentYear);
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApi(0, 1, case_statuses, service_code)

    }

    onMouseHandle(id, action) {
        let element_id = document.getElementById(id)
        element_id.style.backgroundColor = action == "over" ? "#00008F" : "#FFFFFF";
        element_id.style.color = action == "over" ? "#FFFFFF" : "#2B2D3D";

    }

    onclickHandleCases(case_statuses, placeholderCase) {
        let service_code = this.state.casesType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, false)
        this.setState({ cases: case_statuses, hideDropDownCase: true, placeholderCase: placeholderCase })
    }

    onclickHandleModesType(itemValue, placeholderModeType) {
        this.setState({ hideDropDownModeType: true, placeholderModeType: placeholderModeType, selectMode: itemValue })
        
        if("Daily" == itemValue) {
            $('#monthInput').removeAttr("disabled");
            $('#monthInput').css("pointer-events", "");
            $('#yearInput').attr("disabled", "disabled");
            $('#yearInput').css("pointer-events", "none");
            $('#monthStart').attr("disabled", "disabled");
            $('#monthStart').css("pointer-events", "none");
            $('#monthEnd').attr("disabled", "disabled");
            $('#monthEnd').css("pointer-events", "none");
        } else if("Monthly" == itemValue) {
            $('#monthInput').attr("disabled", "disabled");
            $('#monthInput').css("pointer-events", "none");
            $('#yearInput').removeAttr("disabled");
            $('#yearInput').css("pointer-events", "");
            $('#monthStart').removeAttr("disabled");
            $('#monthStart').css("pointer-events", "");
            $('#monthEnd').removeAttr("disabled");
            $('#monthEnd').css("pointer-events", "");
            window.localStorage.setItem("selectedDaily", "false");
        }
        
        $('#monthStart').css('borderColor', '');
        $('#monthEnd').css('borderColor', '');
        $('#monthStart').css('borderRightColor', "#CCCEDB");
        $('#monthEnd').css('borderRightColor', "#CCCEDB");
    }

    onClickHandleSearch() {
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, false)
    }

    onKeyUpHandleSearch(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            let case_statuses = this.state.cases;
            let service_code = this.state.casesType;
            this.callCaseApiEnhanced(0, 1, case_statuses, service_code, false)
        }
    }

    hideDropDownCase() {
        if (this.state.hideDropDownCase) {
            this.setState({ hideDropDownCase: false })
        }
        else {
            this.setState({ hideDropDownCase: true })
        }
    }

    hideDropDownModeType() { 
        if (this.state.hideDropDownModeType) {
            this.setState({ hideDropDownModeType: false })
        }
        else {
            this.setState({ hideDropDownModeType: true })
        }

    }

    downloadFile() {
        let mode = this.state.selectMode == null || this.state.selectMode == "" ? "Monthly" : this.state.selectMode;
        let month = Moment(this.state.selectMonth).format("Y") + Moment(this.state.selectMonth).format("MM") + "01";
        let selectedYear = Moment(this.state.selectYear).format("Y");
        let pass = true;
        
        if(mode == "Monthly") {
            let start = $("#monthStart").val();
            let end = $("#monthEnd").val();

            start = start.split('/');
            end = end.split('/');

            let newStartDate = start[2] + '-' + start[1] + '-' + start[0];
            let newEndDate = end[2] + '-' + end[1] + '-' + end[0];

            let difference = Math.floor((Math.abs(new Date(newStartDate) - new Date(newEndDate)))/(1000*60*60*24));
            
            if((difference + 1) < 30) {
                pass = false;
                $('#monthStart').css('borderColor', 'red');
                $('#monthEnd').css('borderColor', 'red');
            } else {
                $('#monthStart').css('borderColor', '');
                $('#monthEnd').css('borderColor', '');
                $('#monthStart').css('borderRightColor', "#CCCEDB");
                $('#monthEnd').css('borderRightColor', "#CCCEDB");
            }
        }
        
        if(pass) {
            this.exportDataApiEnhanced(mode, month, selectedYear);
        }
    }

    onClickEachItem(ev) {
        {
            let checkCopyOrClick = window.getSelection().toString().length;
            if (checkCopyOrClick == 0) //If length==0 , it will click action else u
            {
                let dataState = {
                    dashboardCase: this.state.dashboardCase,
                    currentPage: this.state.currentPage,
                    rangeIndex: this.state.rangeIndex,
                    total: this.state.total,
                    pageCount: this.state.pageCount,
                    claimsArray: this.state.claimsArray,
                    selectDate: this.state.selectDate,
                    cases: this.state.cases,
                    casesType: this.state.casesType,
                    searchField: this.state.searchField,
                    placeholder: this.state.placeholder,
                    placeholderCase: this.state.placeholderCase,
                    placeholderModeType: this.state.placeholderModeType,
                    partnerIndex: this.state.partnerIndex,
                    partnerId: this.state.partnerId,
                }
                window.localStorage.setItem("dataState", JSON.stringify(dataState));
                this.props.history.push(`cases/${ev.case_id}`)
            }
        }
    }

    onChangeDatePicker(date) {
        this.setState({ selectDate: date, readOnlyCalendar: true })
        let opened_date_since = "";
        let opened_date_until = "";
        opened_date_since = Moment(date).format("Y") + Moment(date).format("MM") + "01"
        opened_date_until = Moment(date).format("Y") + Moment(date).format("MM") + "31"
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApi1(opened_date_since, opened_date_until, 0, 1, case_statuses, service_code)
        let currentMonth = Moment(date).format("M");
        let currentYear = Moment(date).format("YYYY");
        this.getDashboardData(currentMonth, currentYear)
    }

    onClickHandleSearchDateRange() {
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, false)
    }

    callCaseApiEnhanced(start_index, currentPage, case_statuses, service_code, fromOtherPage) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        let opened_date_since = "";
        let opened_date_until = "";
        if (this.state.selectDate) {
            let defaultStartDate = "";
            let defaultEndDate = "";
            let selectYear = this.state.selectDate.getFullYear();
            let selectMonth = this.state.selectDate.getMonth();
            
            if(fromOtherPage) {
                defaultStartDate = new Date(selectYear, selectMonth, 1);
                defaultEndDate = new Date(selectYear, selectMonth + 1, 0);
            } else {
                defaultStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                defaultEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
            }
            
            defaultStartDate = Moment(defaultStartDate).format("DD/MM/YYYY");
            defaultEndDate = Moment(defaultEndDate).format("DD/MM/YYYY");
            
            let start = $("#monthStart").val();
            let end = $("#monthEnd").val();
            
            
            if(start == null || end == null) {
                start = defaultStartDate;
                end = defaultEndDate;
            }
            
            start = start.split('/');
            end = end.split('/');
            
            let newStartDate = start[1] + '/' + start[0] + '/' + start[2];
            let newEndDate = end[1] + '/' + end[0] + '/' + end[2];
            
            window.localStorage.setItem("prevMonthStart", newStartDate);
            window.localStorage.setItem("prevMonthEnd", newEndDate);  
            opened_date_since = Moment(newStartDate).format("YYYYMMDD");
            opened_date_until = Moment(newEndDate).format("YYYYMMDD");
            
        }
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages, selectStartDate: $("#monthStart").val(), selectEndDate: $("#monthEnd").val() })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    exportDataApiEnhanced(mode, month, selectedYear) {
        let url = ``;
        this.setState({ isDownload: true })
        let opened_date_since = month;
        let opened_date_until = Moment(this.state.selectMonth).format("Y") + Moment(this.state.selectMonth).format("MM") + "31";
        let selectedMonth = Moment(this.state.selectMonth).format("M");
        selectedYear = ("Daily" == mode) ? Moment(this.state.selectMonth).format("Y") : selectedYear;
        
        if("Monthly" == mode) {
            let start = $("#monthStart").val();
            let end = $("#monthEnd").val();
            
            start = start.split('/');
            end = end.split('/');
            
            let newStartDate = start[1] + '/' + start[0] + '/' + start[2];
            let newEndDate = end[1] + '/' + end[0] + '/' + end[2];
            
            opened_date_since = Moment(newStartDate).format("YYYYMMDD");
            opened_date_until = Moment(newEndDate).format("YYYYMMDD");
            
        }
        
        url = `api/detailsReports/export?mode=${mode}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}&opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&reportType=towingDestination`;
        
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.blob();
                    this.checkForInvalidSession(data);
                    let partner = this.state.partnerId;
                    let filename = "";
                    if("Daily" == mode) {
                        if("PERODUA" == partner) {
                            filename = `TOWING_DESTINATION_REPORT_` + partner + `_${opened_date_since}_${opened_date_until}.pdf`;
                        } else {
                            filename = `TOWING_DESTINATION_REPORT_` + partner + `_${opened_date_since}.xlsx`;
                        }
                    } else {
                        if("LIBERTY_MY" == partner || "ZIMB" == partner || "ZTMB" == partner) {
                            filename = `TOWING_DESTINATION_REPORT_` + partner + `_${opened_date_since}.xlsx`;
                        } else {
                            filename = `TOWING_DESTINATION_REPORT_` + partner + `_${opened_date_since}_${opened_date_until}.pdf`;
                        }
                    }
                    this.downloadBinary(data, filename)
                    this.setState({ isDownload: false })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ isDownload: false })
            });
    }

    onChangeDatePickerYear(date) {
        this.setState({ selectYear: date })
    }

    onChangeDatePickerMonth(date) {
        this.setState({ selectMonth: date })
    }

    render() {
        return (
            <div>
                {
                    this.state.reload ?
                        <>
                            <Layout name="TowingDestinationReports" subid="" title="TowingDestinationReports" loading={this.state.loading}>
                                <div class="fixed-top">
                                    <TopNavigation
                                        name="TowingDestinationReports"
                                        hidesubId={false}
                                        profile={this.state.profile}
                                        partnerIds={this.state.partnerIds}
                                        partnerId={this.state.partnerId}
                                        partnerId1={this.state.partnerId1}
                                        history={this.props.history}
                                        role={this.state.role}
                                        avatarLetter={this.state.avatarLetter}
                                        switchPartnerId={() => this.switchPartnerId()}
                                        hideDropDownMenu={this.state.hideDropDownMenu}
                                        hideDropDownMenuReport={this.state.hideDropDownMenuReport}
                                        setWrapperRefLogOut={this.setWrapperRefLogOut}
                                        setWrapperRefReport={this.setWrapperRefReport}
                                        hideDropDownLogout={() => this.hideDropDownLogout()}
                                        hideDropDownReport={() => this.hideDropDownReport()}
                                    />
                                    <BreadCrumbs name={this.state.name} subid={this.state.subid} history={this.props.history} />
                                </div>
                                {/*  End Nav*/}
                                <div class="row mlr-20 mt-20px mb-20px">
                                    <div class="col-md-12">
                                        <div class="row justify-content-center">
                                        
                                            <div class="col-md-1.5 text-right">
                                                <div class="row" class="input-group" ref={this.setWrapperRefModeType}>
                                                    <div class="col-md-12 text-right">
                                                        <span class="input-group ml-80px" style={{marginLeft: "0px"}}>
                                                            <span className="show-title mr-20px">
                                                                Select Mode:
                                                            </span>
                                                           
                                                            <input type="text" onClick={() => this.hideDropDownModeType()} value={this.state.placeholderModeType} class="form-control allcase_input dropdown-icon" style={{cursor: "pointer"}} />
                                                                <ul hidden={this.state.hideDropDownModeType} class="list-group dropdown-menu width-100">
                                                                {
                                                                    MyService.getModeOptionsMonthly().map((item, index) => {
                                                                        return (
                                                                            <li id={item.id}
                                                                                onMouseOver={(e) => this.onMouseHandle(item.id, "over")}
                                                                                onMouseOut={(e) => this.onMouseHandle(item.id, "out")}
                                                                                onClick={() => this.onclickHandleModesType(item.value, item.name)
                                                                                } class="list-group-item list-case-item">{item.name}</li>
                                                                        );
                                                                    })
                                                                }
                                                                </ul>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            &nbsp;
                                            <div class="col-md-1.5 text-right">
                                                <div class="row" class="input-group">
                                                    <div class="col-md-12 text-right">
                                                        <span class="input-group ml-80px" style={{marginLeft: "0px"}}>
                                                            <span className="show-title mr-20px">
                                                                Select Month:
                                                            </span>
                                                            <DatePicker
                                                                customInput={<ExampleCustomInputMonth />}
                                                                popperPlacement="top-start"
                                                                id="month"
                                                                onChange={(date) => this.onChangeDatePickerMonth(date)}
                                                                selected={this.state.selectMonth}
                                                                maxDate={new Date()}
                                                                minDate={new Date("01/01/2018")}
                                                                dateFormat="MMM, yyyy"
                                                                showMonthYearPicker
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <div class="col-md-1.5 text-right">
                                                <div class="row" class="input-group">
                                                    <div class="col-md-12 text-right">
                                                        <span class="input-group ml-80px" style={{marginLeft: "0px"}}>
                                                            <span className="show-title mr-20px">
                                                                Select Year:
                                                            </span>
                                                            {<DatePickerRange />}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            &nbsp;
                                            <div class="col-md-1.5 text-left">
                                                {
                                                    this.state.isDownload ?
                                                        <a class="btn downloading-button">
                                                            <i class="fa fa-spinner fa-spin mr-20px font-size-18"></i>
                                                                Downloading....
                                                        </a>
                                                        :
                                                        <a onClick={(e) => this.downloadFile()} class="btn btn-export">
                                                            <img className="mr-20px" src="/static/images/Download_white@3x.png" height="15px" /><span className="white-color"> Download Report</span>
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <span className="show-title mr-20px" style={{color: "red"}}>**Date range must be minimum 1 month if the 'Monthly' mode option is selected</span>
                                        </div>
                                    </div>
                                </div>
                                        
                                <div class="row empty-section">
                                    <div class="col-md-12 text-center">
                                        <div className="br-22px">
                                            <div class="text-center case-main-infor">
                                                No cases to display.
                                            </div>
                                            <div class="text-center case-sub-info">
                                                The system may take some time to generate documents.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Layout>
                        </>
                        :
                        null
                }
            </div>
        );
    }
}
export default withRouter(TowingDestinationReports)