import React from 'react';
class TowingLocation extends React.Component {
    constructor(props) {
        super(props)
    }

    formatNumber(num) {
        let value = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (value === "NaN") {
            return 0;
        }
        return value;
    }
    render() {
        return (
            <>
                <table class="table no-border">
                    <thead className="location_thead-background">
                        <tr className="location_thead">
                            <th >TOWING LOCATION (FROM)</th>
                            <th >CASES</th>
                            <th width="5px"></th>
                        </tr>
                    </thead>
                </table>
                <div className="location-table-content">
                    <table class="table no-border mb-29px">
                        <tbody>
                            {
                                this.props.location.map((ev, index) => {
                                    return (
                                        <tr className="location_tr">
                                            <td width="100%" className="pl-12px">{ev.location}</td>
                                            <td className="pl-12px">
                                                {this.formatNumber(parseInt(ev.locationValue))}
                                            </td>
                                            <td style={{ color: "#00008F" }} >
                                                {
                                                    ev.locationValue == 0 ?
                                                        null
                                                        :
                                                        <div>
                                                            {(ev.locationValue * 100 / this.props.totalLocationValue).toFixed(1)}%
                                                        </div>
                                                }
                                            </td>
                                            <td width="15px">&nbsp;</td>
                                        </tr>
                                    );
                                })
                            }
                            {
                                this.props.locationOverseas.map((ev, index) => {
                                    return (
                                        <tr className="location_tr">
                                            <td width="100%" className="pl-12px">{ev.location}</td>
                                            <td className="pl-12px">
                                                {this.formatNumber(parseInt(ev.locationValue))}
                                            </td>
                                            <td style={{ color: "#00008F" }} >
                                                {
                                                    ev.locationValue == 0 ?
                                                        null
                                                        :
                                                        <div>
                                                            {(ev.locationValue * 100 / this.props.totalLocationValueOversea).toFixed(1)}%
                                                        </div>
                                                }
                                            </td>
                                            <td width="15px">&nbsp;</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}


export default TowingLocation

