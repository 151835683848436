import React, {useState} from 'react';
import Cookies from 'js-cookie';
import Layout from './layout';
import Moment from 'moment';
import DatePicker from "react-datepicker";
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import MyService from "./services/my-service";
import ReactPaginate from 'react-paginate';
import $ from "jquery";
import 'font-awesome/css/font-awesome.min.css';
import BreadCrumbs from "./breadcrumbs";
import TopNavigation from "./topnavigation";
import "react-datepicker/dist/react-datepicker.css";
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';

const ExampleCustomInput = ({ value, onClick }) => (

    <input id="month" type="text" value={value} onClick={onClick} class="form-control calendar_input" style={{cursor: "pointer"}}/>
);

const ExampleCustomInputStart = ({ value, onClick }) => (
    window.localStorage.getItem("selectedExcludeDateRange") != null ?
    <input id="monthStart" type="text" value={value} onClick={onClick} disabled class="form-control calendar_input" placeholder="From Date" style={{ borderRightColor: "#CCCEDB" }}/>
    :
    <input id="monthStart" type="text" value={value} onClick={onClick} class="form-control calendar_input" placeholder="From Date" style={{ borderRightColor: "#CCCEDB", cursor: "pointer" }}/>
);

const ExampleCustomInputEnd = ({ value, onClick }) => (
    window.localStorage.getItem("selectedExcludeDateRange") != null ?
    <input id="monthEnd" type="text" value={value} onClick={onClick} disabled class="form-control calendar_input" placeholder="To Date" style={{ borderRightColor: "#CCCEDB" }} />
    :
    <input id="monthEnd" type="text" value={value} onClick={onClick} class="form-control calendar_input" placeholder="To Date" style={{ borderRightColor: "#CCCEDB", cursor: "pointer" }} />
);

const DatePickerRange = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [newStartDate, setNewStartDate] = useState(window.localStorage.getItem("prevMonthStart") === null ? startDate : new Date(window.localStorage.getItem("prevMonthStart")));
    const [newEndDate, setNewEndDate] = useState(window.localStorage.getItem("prevMonthEnd") === null ? endDate : new Date(window.localStorage.getItem("prevMonthEnd")));
    
    return (
        <div className="row">
            <div> 
                <DatePicker
                    customInput={<ExampleCustomInputStart />}
                    selected={newStartDate}
                    onChange={date => {setNewStartDate(date);setNewEndDate(date)}}
                    selectsStart
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={new Date("01/01/2018")}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
            &nbsp;
            <div>
                <DatePicker
                    customInput={<ExampleCustomInputEnd />}
                    selected={newEndDate}
                    onChange={date => setNewEndDate(date)}
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={newStartDate}
                    maxDate={addYears(newStartDate, 2)}
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        </div>
    );
};

const DatePickerRangeOneMonth = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [newStartDate, setNewStartDate] = useState(window.localStorage.getItem("prevMonthStart") === null ? startDate : new Date(window.localStorage.getItem("prevMonthStart")));
    const [newEndDate, setNewEndDate] = useState(window.localStorage.getItem("prevMonthEnd") === null ? endDate : new Date(window.localStorage.getItem("prevMonthEnd")));
    
    return (
        <div className="row">
            <div> 
                <DatePicker
                    customInput={<ExampleCustomInputStart />}
                    selected={newStartDate}
                    onChange={date => {setNewStartDate(date);setNewEndDate(date)}}
                    selectsStart
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={new Date("01/01/2018")}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    disabledKeyboardNavigation
                />
            </div>
            &nbsp;
            <div>
                <DatePicker
                    customInput={<ExampleCustomInputEnd />}
                    selected={newEndDate}
                    onChange={date => setNewEndDate(date)}
                    startDate={newStartDate}
                    endDate={newEndDate}
                    minDate={newStartDate}
                    maxDate={addDays(newStartDate, 30)}
                    dateFormat="dd/MM/yyyy"
                />
            </div>
        </div>
    );
};

const CheckBoxExcludeDateRange = () => {
    const [initChecked, setInitChecked] = useState(false);
    const [checked, setChecked] = useState(window.localStorage.getItem("selectedExcludeDateRange") === null ? initChecked : "checked"); 
    
    const handleChange = () => {
        setChecked(!checked); 
        
        /** to disable datepicker selection */
        if(!checked) {
            $('#monthStart').css("pointer-events", "none");
            $('#monthEnd').css("pointer-events", "none");
            $('#monthStart').attr("disabled", "disabled");
            $('#monthEnd').attr("disabled", "disabled");
        } else {
            $('#monthStart').css("pointer-events", "");
            $('#monthEnd').css("pointer-events", "");
            $('#monthStart').removeAttr("disabled");
            $('#monthEnd').removeAttr("disabled");
            if(window.localStorage.getItem("selectedExcludeDateRange") != null) {
                window.localStorage.removeItem("selectedExcludeDateRange");
            }
        }
    };  
    
    return (
            <input type="checkbox" id="checkboxExcludeDate" checked={checked} onChange={handleChange} />
    );
};

class Cases extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            claimsArray: [],
            minDate: "",
            name: "claims",
            subid: "",
            avatarLetter: "",
            role: "",
            profile: {},
            isOpenLogout: false,
            currentPage: 0,
            rangeIndex: "",
            max_results: 20,
            pageCount: 0,
            id: this.props.id,
            total: "",
            pageCount: 0,
            hideDropDown: true,
            hideDropDownCase: true,
            hideDropDownCaseType: true,
            hideDropDownIncomingCallsType: true,
            hideDropDownMenu: true,
            hideDropDownMenuReport: true,
            offset: 0,
            selectDate: new Date(),
            cases: "",
            casesType: "",
            incomingCallsType: "",
            searchField: "",
            placeholder: "Enter Car Plate No.",
            placeholderCase: "All Cases",
            placeholderCaseType: "All Cases Type",
            placeholderIncomingCallType: "All Incoming Calls Type",
            reload: false,
            loading: false,
            services: {},
            dataState: {},
            readOnlyCalendar: true,
            disableButton: false,
            isDownload: false,
            dashboardCase: {},
            partnerId: "",
            partnerId1: "",
            partnerIds: [],
            partnerIndex: 0,
            selectStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            selectEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        }
        this.setWrapperRefAllCase = this.setWrapperRefAllCase.bind(this);
        this.setWrapperRefAllCaseType = this.setWrapperRefAllCaseType.bind(this);
        this.setWrapperRefAllIncomingCallsType = this.setWrapperRefAllIncomingCallsType.bind(this);
        this.setWrapperSearch = this.setWrapperSearch.bind(this);
        this.setWrapperRefLogOut = this.setWrapperRefLogOut.bind(this);
        this.setWrapperRefReport = this.setWrapperRefReport.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
    }

    findIndex(arr, text) {
        let index = 0;
        for (let i = 0; i < arr.length; i++) {
            if (text == arr[i]) {
                index = i;
            }
        }
        return index;
    }

    toggleModalLogOut() {
        let isOpenLogout = !this.state.isOpenLogout
        this.setState({
            isOpenLogout: isOpenLogout
        })
    }

    callCaseApi(start_index, currentPage, case_statuses, service_code) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        let opened_date_since = "";
        let opened_date_until = ""
        if (this.state.selectDate) {
            let selectDate = this.state.selectDate;
            opened_date_since = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "01"
            opened_date_until = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "31"
        }
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    callCaseApi1(opened_date_since, opened_date_until, start_index, currentPage, case_statuses, service_code) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    downloadBinary(response, filename) {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response]))
        a.download = filename
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
    }

    exportDataApi(case_statuses, service_code) {
        let url = ``;
        this.setState({ isDownload: true })
        let opened_date_since = "";
        let opened_date_until = ""
        if (this.state.selectDate) {
            let selectDate = this.state.selectDate;
            opened_date_since = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "01"
            opened_date_until = Moment(selectDate).format("Y") + Moment(selectDate).format("MM") + "31"
        }
        url = `api/reports/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            }
            else {
                url = url + `&policy_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.blob()
                    this.checkForInvalidSession(data);
                    let filename = `Cases_Export_${opened_date_since}_${opened_date_until}.xlsx`
                    this.downloadBinary(data, filename)
                    this.setState({ isDownload: false })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ isDownload: false })
            });
    }
    getDashboardData(month, year) {
        let url = `api/dashboard?month=${month}&year=${year}`;
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let dashboardCase = data.data.dashboardCase;
                    this.setState({
                        dashboardCase: dashboardCase,
                    })
                })
            .catch(error => {
                console.log(error)
                if (error.response) {
                    console.log('Error', error.message);
                }
            });
    }

    checkForInvalidSession(data) {
        if (data.message === "invalid_user_session" || data.error_code === "invalid_user_session" || data.error_code === "invalid_refresh_token") {
            MyService.logoutForSession();
        }
    }

    componentDidMount() {
        let minDate = "";
        minDate = `2018-01-01`
        this.state.minDate = minDate;
        document.addEventListener('mousedown', this.handleClickOutside);
        const usercookie = Cookies.get('user');
        const user = JSON.parse(usercookie);
        let name = user.userInfo.name;
        let avatarLetter = name.substring(0, 1).toUpperCase();
        if (!user.userInfo["https://asia-assistance.com/partnerIds"] || user.userInfo["https://asia-assistance.com/partnerIds"] === null) {
            user.userInfo["https://asia-assistance.com/partnerIds"] = [user.userInfo["https://asia-assistance.com/partnerId"]];
        }
        this.setState({ partnerIds: user.userInfo["https://asia-assistance.com/partnerIds"], avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
        let dataState = {};
        dataState = JSON.parse(window.localStorage.getItem("dataState"));
        let partner = {};
        partner = JSON.parse(window.localStorage.getItem("partner"));
        if (partner != null) {
            this.state.partnerIndex = partner.partnerIndex;
            this.state.partnerId = partner.partnerId
            this.state.partnerId1 = partner.partnerId1
        }
        if (usercookie) {
            const user = JSON.parse(usercookie);
            let name = user.userInfo.name;
            let avatarLetter = name.substring(0, 1).toUpperCase();
            this.setState({ avatarLetter: avatarLetter, profile: user.userInfo, role: user.userInfo["https://asia-assistance.com/partnerRole"] })
            if (dataState != null) {
                this.setState({
                    reload: true,
                    currentPage: dataState.currentPage,
                    rangeIndex: dataState.rangeIndex,
                    total: dataState.total,
                    pageCount: dataState.pageCount,
                    claimsArray: dataState.claimsArray,
                    selectDate: new Date(dataState.selectDate),
                    cases: dataState.cases,
                    casesType: dataState.casesType,
                    incomingCallsType: dataState.incomingCallsType,
                    searchField: dataState.searchField,
                    placeholder: dataState.placeholder,
                    placeholderCase: dataState.placeholderCase,
                    placeholderCaseType: dataState.placeholderCaseType,
                    placeholderIncomingCallType: dataState.placeholderIncomingCallType,
                    dashboardCase: dataState.dashboardCase,
                });
                window.localStorage.removeItem("dataState");
            }
            else {
                window.localStorage.removeItem("prevMonthStart");
                window.localStorage.removeItem("prevMonthEnd");
                let caseStatus = {}
                caseStatus = JSON.parse(window.localStorage.getItem("caseStatus"));
                if (caseStatus != null) {
                    let placeholderCase = "";
                    let cases = "";
                    if (caseStatus.status == "OPEN") {
                        placeholderCase = "Opened Cases"
                        cases = "OPEN"
                    } else if (caseStatus.status == "ALL") {
                        placeholderCase = "All Cases"
                        cases = ""
                    } else if (caseStatus.status == "UNVERIFIED") {
                        placeholderCase = "Unverified Cases"
                        cases = "UNVERIFIED"
                    } else if (caseStatus.status == "CANCELLED") {
                        placeholderCase = "Cancelled Cases"
                        cases = "CANCELLED"
                    }
                    else if (caseStatus.status == "CLOSED") {
                        placeholderCase = "Closed Cases"
                        cases = "CLOSED"
                    }
                    this.state.cases = cases;
                    this.state.selectDate = new Date(caseStatus.selectDate)
                    this.setState({ reload: true, placeholderCase: placeholderCase })
                    this.callCaseApiEnhanced(0, 1, caseStatus.status, "", "", true);
                    let currentDate = new Date(caseStatus.selectDate)
                    let currentMonth = Moment(currentDate).format("M");
                    let currentYear = Moment(currentDate).format("YYYY");
                    this.getDashboardData(currentMonth, currentYear)
                }
                else {
                    const user = JSON.parse(usercookie);
                    this.setState({ reload: true })
                    this.callCaseApi(0, 1, "", "");
                    let currentDate = this.state.selectDate;
                    let currentMonth = Moment(currentDate).format("M");
                    let currentYear = Moment(currentDate).format("YYYY");
                    this.getDashboardData(currentMonth, currentYear)
                }
            }
        }
        else {
            window.location.href = "/login"
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRefAllCase(node) {
        this.wrapperRefAllCase = node;
    }

    setWrapperRefLogOut(node) {
        this.wrapperRefLogOut = node;
    }

    setWrapperRefReport(node) {
        this.wrapperRefReport = node;
    }

    setWrapperRefAllCaseType(node) {
        this.wrapperRefAllCaseType = node;
    }
    
    setWrapperRefAllIncomingCallsType(node) {
        this.wrapperRefAllIncomingCallsType = node;
    }

    setWrapperSearch(node) {
        this.wrapperSearch = node;
    }

    setWrapperRefMenu(node) {
        this.wrapperRefMenu = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRefAllCase && !this.wrapperRefAllCase.contains(event.target)) {
            this.setState({
                hideDropDownCase: true
            })
        }
        if (this.wrapperRefAllCaseType && !this.wrapperRefAllCaseType.contains(event.target)) {
            this.setState({
                hideDropDownCaseType: true
            })
        }
        if (this.wrapperRefAllIncomingCallsType && !this.wrapperRefAllIncomingCallsType.contains(event.target)) {
            this.setState({
                hideDropDownIncomingCallsType: true
            })
        }
        if (this.wrapperSearch && !this.wrapperSearch.contains(event.target)) {
            this.setState({
                hideDropDown: true
            })
        }
        if (this.wrapperRefLogOut && !this.wrapperRefLogOut.contains(event.target)) {
            this.setState({
                hideDropDownMenu: true
            })
        }
        if (this.wrapperRefReport && !this.wrapperRefReport.contains(event.target)) {
            this.setState({
                hideDropDownMenuReport: true
            })
        }
    }

    handlePageClick = (e) => {
        let selectedPage = parseInt(e.selected) + 1;
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        let incoming_calls_type = this.state.incomingCallsType;
        this.callCaseApiEnhanced(selectedPage * this.state.max_results - this.state.max_results, selectedPage, case_statuses, service_code, incoming_calls_type, false);
    };

    hideDropDownMenu() {
        {
            if (this.state.hideDropDown) {
                this.setState({ hideDropDown: false })
            }
            else {
                this.setState({ hideDropDown: true })
            }
        }
    }

    hideDropDownLogout() {
        if (this.state.hideDropDownMenu) {
            this.setState({ hideDropDownMenu: false })
        }
        else {
            this.setState({ hideDropDownMenu: true })
        }
    }

    hideDropDownReport() {
        if (this.state.hideDropDownMenuReport) {
            this.setState({ hideDropDownMenuReport: false })
        }
        else {
            this.setState({ hideDropDownMenuReport: true })
        }
    }

    switchPartnerId() {
        let index = 0;
        index = this.findIndex(this.state.partnerIds, this.state.partnerId1)
        this.state.partnerIndex = index;
        this.state.hideDropDownMenu = true;
        let partner = {};
        if (index == 0) {
            partner = {
                partnerIndex: 0,
                partnerId: this.state.partnerIds[0],
                partnerId1: this.state.partnerIds[1]
            }
            this.state.partnerId = this.state.partnerIds[0];
            this.state.partnerId1 = this.state.partnerIds[1]
            this.state.partnerIndex = 0;
        }
        if (index == 1) {
            partner = {
                partnerIndex: 1,
                partnerId: this.state.partnerIds[1],
                partnerId1: this.state.partnerIds[0]
            }
            this.state.partnerId = this.state.partnerIds[1];
            this.state.partnerId1 = this.state.partnerIds[0]
            this.state.partnerIndex = 1;
        }
        window.localStorage.setItem("partner", JSON.stringify(partner));
        let currentDate = this.state.selectDate;
        let currentMonth = Moment(currentDate).format("M");
        let currentYear = Moment(currentDate).format("YYYY");
        this.getDashboardData(currentMonth, currentYear);
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApi(0, 1, case_statuses, service_code)

    }

    onMouseHandle(id, action) {
        let element_id = document.getElementById(id)
        element_id.style.backgroundColor = action == "over" ? "#00008F" : "#FFFFFF";
        element_id.style.color = action == "over" ? "#FFFFFF" : "#2B2D3D";

    }

    onclickHandleCases(case_statuses, placeholderCase) {
        let service_code = this.state.casesType;
        let incoming_calls_type = this.state.incomingCallsType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
        this.setState({ cases: case_statuses, hideDropDownCase: true, placeholderCase: placeholderCase })
    }

    onclickHandleCasesType(service_code, placeholderCaseType) {
        let case_statuses = this.state.cases;
        let incoming_calls_type = this.state.incomingCallsType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
        this.setState({ casesType: service_code, hideDropDownCaseType: true, placeholderCaseType: placeholderCaseType })
    }

    onclickHandleIncomingCallsType(incoming_calls_type, placeholderIncomingCallsType) {
        let service_code = this.state.casesType;
        let case_statuses = this.state.cases;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
        this.setState({ incomingCallsType: incoming_calls_type, hideDropDownIncomingCallsType: true, placeholderIncomingCallType: placeholderIncomingCallsType })
    }

    onClickHandleSearch() {
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        let incoming_calls_type = this.state.incomingCallsType;
        this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
    }

    onKeyUpHandleSearch(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            let case_statuses = this.state.cases;
            let service_code = this.state.casesType;
            let incoming_calls_type = this.state.incomingCallsType;
            this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
        }
    }

    hideDropDownCase() {
        if (this.state.hideDropDownCase) {
            this.setState({ hideDropDownCase: false })
        }
        else {
            this.setState({ hideDropDownCase: true })
        }
    }

    hideDropDownCaseType() {
        if (this.state.hideDropDownCaseType) {
            this.setState({ hideDropDownCaseType: false })
        }
        else {
            this.setState({ hideDropDownCaseType: true })
        }

    }
    
    hideDropDownIncomingCallsType() {
        if (this.state.hideDropDownIncomingCallsType) {
            this.setState({ hideDropDownIncomingCallsType: false })
        }
        else {
            this.setState({ hideDropDownIncomingCallsType: true })
        }

    }

    downloadFile() {
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        let incoming_calls_type = this.state.incomingCallsType;
        let pass = true;
        
        if($('#checkboxExcludeDate').is(':checked')) {
            let searchInput = $('#txtSearchInput').val().trim();
            if(searchInput === '') {
                $('#txtSearchInput').css('borderColor', 'red');
                pass = false;
            } else {
                $('#txtSearchInput').css('borderColor', '');
            }
        } else {
            $('#txtSearchInput').css('borderColor', '');
        }
        
        if(pass) {
            this.exportDataApiEnhanced(case_statuses, service_code, incoming_calls_type);
        }
    }

    onClickEachItem(ev) {
        {
            let checkCopyOrClick = window.getSelection().toString().length;
            if (checkCopyOrClick == 0) //If length==0 , it will click action else u
            {
                let dataState = {
                    dashboardCase: this.state.dashboardCase,
                    currentPage: this.state.currentPage,
                    rangeIndex: this.state.rangeIndex,
                    total: this.state.total,
                    pageCount: this.state.pageCount,
                    claimsArray: this.state.claimsArray,
                    selectDate: this.state.selectDate,
                    cases: this.state.cases,
                    casesType: this.state.casesType,
                    searchField: this.state.searchField,
                    placeholder: this.state.placeholder,
                    placeholderCase: this.state.placeholderCase,
                    placeholderCaseType: this.state.placeholderCaseType,
                    placeholderIncomingCallType: this.state.placeholderIncomingCallType,
                    partnerIndex: this.state.partnerIndex,
                    partnerId: this.state.partnerId,
                }
                window.localStorage.setItem("dataState", JSON.stringify(dataState));
                this.props.history.push(`cases/${ev.case_id}`)
            }
        }
    }

    onChangeDatePicker(date) {
        this.setState({ selectDate: date, readOnlyCalendar: true })
        let opened_date_since = "";
        let opened_date_until = "";
        opened_date_since = Moment(date).format("Y") + Moment(date).format("MM") + "01"
        opened_date_until = Moment(date).format("Y") + Moment(date).format("MM") + "31"
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        this.callCaseApi1(opened_date_since, opened_date_until, 0, 1, case_statuses, service_code)
        let currentMonth = Moment(date).format("M");
        let currentYear = Moment(date).format("YYYY");
        this.getDashboardData(currentMonth, currentYear)
    }

    onClickHandleSearchDateRange() {
        let case_statuses = this.state.cases;
        let service_code = this.state.casesType;
        let incoming_calls_type = this.state.incomingCallsType;
        let pass = true;
        
        if($('#checkboxExcludeDate').is(':checked')) {
            let searchInput = $('#txtSearchInput').val().trim();
            if(searchInput === '') {
                $('#txtSearchInput').css('borderColor', 'red');
                pass = false;
            } else {
                $('#txtSearchInput').css('borderColor', '');
            }
        } else {
            $('#txtSearchInput').css('borderColor', '');
        }
        
        if(pass) {
            this.callCaseApiEnhanced(0, 1, case_statuses, service_code, incoming_calls_type, false)
        }
    }

    callCaseApiEnhanced(start_index, currentPage, case_statuses, service_code, incoming_calls_type, fromOtherPage) {
        window.localStorage.removeItem("dataState");
        this.setState({ loading: true })
        let url = ``;
        let opened_date_since = "";
        let opened_date_until = "";
        if (this.state.selectDate) {
            let defaultStartDate = "";
            let defaultEndDate = "";
            let selectYear = this.state.selectDate.getFullYear();
            let selectMonth = this.state.selectDate.getMonth();
            
            if(fromOtherPage) {
                defaultStartDate = new Date(selectYear, selectMonth, 1);
                defaultEndDate = new Date(selectYear, selectMonth + 1, 0);
            } else {
                defaultStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                defaultEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
            }
            
            defaultStartDate = Moment(defaultStartDate).format("DD/MM/YYYY");
            defaultEndDate = Moment(defaultEndDate).format("DD/MM/YYYY");
            
            let start = $("#monthStart").val();
            let end = $("#monthEnd").val();
            
            if(start == null || end == null) {
                start = defaultStartDate;
                end = defaultEndDate;
            }
            
            let startOriginal = start;
            let endOriginal = end;
            
            if($('#checkboxExcludeDate').is(':checked')) {
                let todayDate = Moment(new Date()).format("DD/MM/YYYY");
                start = "01/01/2018";
                end = todayDate;
                window.localStorage.setItem("selectedExcludeDateRange", "excluded");
            } else {
                window.localStorage.removeItem("selectedExcludeDateRange");
            }
            
            start = start.split('/');
            end = end.split('/');
            
            let newStartDate = start[1] + '/' + start[0] + '/' + start[2];
            let newEndDate = end[1] + '/' + end[0] + '/' + end[2];
            
            startOriginal = startOriginal.split('/');
            endOriginal = endOriginal.split('/');
            let originalStartDate = startOriginal[1] + '/' + startOriginal[0] + '/' + startOriginal[2];
            let originalEndDate = endOriginal[1] + '/' + endOriginal[0] + '/' + endOriginal[2];
            
            window.localStorage.setItem("prevMonthStart", originalStartDate);
            window.localStorage.setItem("prevMonthEnd", originalEndDate);  
            opened_date_since = Moment(newStartDate).format("YYYYMMDD");
            opened_date_until = Moment(newEndDate).format("YYYYMMDD");
            
        }
        url = `api/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}&start_index=${start_index}&max_results=${this.state.max_results}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if(incoming_calls_type != "") {
            url = url + `&incoming_calls_type=${incoming_calls_type}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            } else if (this.state.placeholder == "Enter Policy No.") {
                url = url + `&policy_number=${this.state.searchField}`;
            } else {
                url = url + `&case_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.json()
                    this.checkForInvalidSession(data);
                    let contentrange = response.headers.get('content-range').split("/");
                    let total = contentrange[1];
                    let rangeIndex = "";
                    let arrIndex = contentrange[0].split("-")
                    if (parseInt(arrIndex[1]) > parseInt(total)) {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + total;
                    }
                    else {
                        rangeIndex = (parseInt(arrIndex[0]) + 1) + "-" + arrIndex[1];
                    }
                    const cases = JSON.stringify(data);
                    let totalPages = 0;
                    totalPages = Math.ceil(total / this.state.max_results);
                    this.setState({ rangeIndex: rangeIndex, loading: false, claimsArray: data, total: total, currentPage: parseInt(currentPage) - 1, pageCount: totalPages, selectStartDate: $("#monthStart").val(), selectEndDate: $("#monthEnd").val() })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ loading: false })
            });
    }

    exportDataApiEnhanced(case_statuses, service_code, incoming_calls_type) {
        let url = ``;
        this.setState({ isDownload: true })
        let opened_date_since = "";
        let opened_date_until = ""
        if (this.state.selectDate) {
            let start = $("#monthStart").val();
            let end = $("#monthEnd").val();
            
            if($('#checkboxExcludeDate').is(':checked')) {
                let todayDate = Moment(new Date()).format("DD/MM/YYYY");
                start = "01/01/2018";
                end = todayDate;
            }
            
            start = start.split('/');
            end = end.split('/');
            
            let newStartDate = start[1] + '/' + start[0] + '/' + start[2];
            let newEndDate = end[1] + '/' + end[0] + '/' + end[2];
            
            opened_date_since = Moment(newStartDate).format("YYYYMMDD");
            opened_date_until = Moment(newEndDate).format("YYYYMMDD");
        }
        url = `api/reports/cases?opened_date_since=${opened_date_since}&opened_date_until=${opened_date_until}`;
        if (case_statuses != "") {
            url = url + `&case_statuses=${case_statuses}`;
        }
        if (service_code != "") {
            url = url + `&service_code=${service_code}`;
        }
        if(incoming_calls_type != "") {
            url = url + `&incoming_calls_type=${incoming_calls_type}`;
        }
        if (this.state.searchField) {
            if (this.state.placeholder == "Enter Car Plate No.") {
                url = url + `&registration_number=${this.state.searchField}`;
            } else if (this.state.placeholder == "Enter Policy No.") {
                url = url + `&policy_number=${this.state.searchField}`;
            } else {
                url = url + `&case_number=${this.state.searchField}`;
            }
        }
        const options = MyService.getHeader("GET", this.state.partnerIndex, true);
        fetch(url, options)
            .then(
                async (response) => {
                    let data = await response.blob()
                    this.checkForInvalidSession(data);
                    let filename = `Cases_Export_${opened_date_since}_${opened_date_until}.xlsx`
                    this.downloadBinary(data, filename)
                    this.setState({ isDownload: false })
                })
            .catch(error => {
                if (error.response) {
                    console.log('Error', error.message);
                }
                this.setState({ isDownload: false })
            });
    }

    render() {
        return (
            <div>
                {
                    this.state.reload ?
                        <>
                            <Layout name="claims" subid="" title="Claims" loading={this.state.loading}>
                                <div class="fixed-top">
                                    <TopNavigation
                                        name="claims"
                                        hidesubId={false}
                                        profile={this.state.profile}
                                        partnerIds={this.state.partnerIds}
                                        partnerId={this.state.partnerId}
                                        partnerId1={this.state.partnerId1}
                                        history={this.props.history}
                                        role={this.state.role}
                                        avatarLetter={this.state.avatarLetter}
                                        switchPartnerId={() => this.switchPartnerId()}
                                        hideDropDownMenu={this.state.hideDropDownMenu}
                                        hideDropDownMenuReport={this.state.hideDropDownMenuReport}
                                        setWrapperRefLogOut={this.setWrapperRefLogOut}
                                        setWrapperRefReport={this.setWrapperRefReport}
                                        hideDropDownLogout={() => this.hideDropDownLogout()}
                                        hideDropDownReport={() => this.hideDropDownReport()}
                                    />
                                    <BreadCrumbs name={this.state.name} subid={this.state.subid} history={this.props.history} />
                                </div>
                                {/*  End Nav*/}
                                <div class="row mlr-20 mt-20px mb-20px">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-1.5">
                                                <div class="input-group" ref={this.setWrapperRefAllCase}>
                                                    <input type="text" onClick={() => this.hideDropDownCase()} value={this.state.placeholderCase} class="form-control allcase_input dropdown-icon" style={{cursor: "pointer"}} />
                                                    <ul hidden={this.state.hideDropDownCase} class="list-group dropdown-menu width-100">
                                                        {
                                                            MyService.getAllCases().map((item, index) => {
                                                                return (
                                                                    <li id={item.id}
                                                                        onMouseOver={(e) => this.onMouseHandle(item.id, "over")}
                                                                        onMouseOut={(e) => this.onMouseHandle(item.id, "out")}
                                                                        onClick={() => this.onclickHandleCases(item.value, item.name)
                                                                        } class="list-group-item list-case-item">{item.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <div class="col-md-1.5">
                                                <div class="input-group" ref={this.setWrapperRefAllCaseType} >
                                                    <input type="text" onClick={() => this.hideDropDownCaseType()} value={this.state.placeholderCaseType} class="form-control allcase_input dropdown-icon" style={{cursor: "pointer"}} />
                                                    <ul hidden={this.state.hideDropDownCaseType} class="list-group dropdown-menu width-100">
                                                        {
                                                            MyService.getAllCasesType().map((item, index) => {
                                                                return (
                                                                    <li id={item.id}
                                                                        onMouseOver={(e) => this.onMouseHandle(item.id, "over")}
                                                                        onMouseOut={(e) => this.onMouseHandle(item.id, "out")}
                                                                        onClick={() => this.onclickHandleCasesType(item.value, item.name)
                                                                        } class="list-group-item list-case-item">{item.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <div class="col-md-1.5">
                                                <div class="input-group" ref={this.setWrapperRefAllIncomingCallsType} >
                                                    <input type="text" onClick={() => this.hideDropDownIncomingCallsType()} value={this.state.placeholderIncomingCallType} class="form-control allcase_input dropdown-icon" style={{cursor: "pointer"}} />
                                                    <ul hidden={this.state.hideDropDownIncomingCallsType} class="list-group dropdown-menu width-100">
                                                        {
                                                            MyService.getAllIncomingCallsType().map((item, index) => {
                                                                return (
                                                                    <li id={item.id}
                                                                        onMouseOver={(e) => this.onMouseHandle(item.id, "over")}
                                                                        onMouseOut={(e) => this.onMouseHandle(item.id, "out")}
                                                                        onClick={() => this.onclickHandleIncomingCallsType(item.value, item.name)
                                                                        } class="list-group-item list-case-item">{item.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="input-group" ref={this.setWrapperSearch} >
                                                    <input id="txtSearchInput" onKeyUp={(e) => this.onKeyUpHandleSearch(e)}
                                                        onChange={(e) => this.setState({ searchField: e.target.value })} type="text" class="form-control search_input " placeholder={this.state.placeholder} value={this.state.searchField || ""} />
                                                            

                                                    <ul hidden={this.state.hideDropDown} class=" list-group dropdown-menu width-100">
                                                        {
                                                            MyService.getSearchOptions().map((item, index) => {
                                                                return (
                                                                    <li id={item.id}
                                                                        onMouseOver={(e) => this.onMouseHandle(item.id, "over")}
                                                                        onMouseOut={(e) => this.onMouseHandle(item.id, "out")}
                                                                        onClick={() => this.setState({ hideDropDown: true, placeholder: item.placeholder })} class="list-group-item list-case-item" >&nbsp;&nbsp;&nbsp;&nbsp;{item.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                    <div class="input-group-prepend" onClick={() => this.hideDropDownMenu()}>
                                                        <span class="input-group-text dropdown-icon-container"><i class="fa fa-angle-down font-size-18" style={{ color: "#919191" }}></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-1.5 text-right">
                                                <div class="row" class="input-group">
                                                    <div class="col-md-12 text-right">
                                                        <span class="input-group ml-80px" style={{marginLeft: "10px"}}>
                                                            <span className="show-title mr-20px">
                                                                Show:
                                                            </span>
                                                            {<DatePickerRange />}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="input-group-prepend">
                                                <span class="input-group ml-80px" style={{marginLeft: "20px"}}>
                                                    <span className="show-title mr-20px">
                                                    {<CheckBoxExcludeDateRange />} Exclude Date </span>
                                                </span>
                                                <a class="btn" style={{ paddingLeft: "0px", paddingTop: "0px" }} onClick={(e) => this.onClickHandleSearchDateRange()}>
                                                    <img src="/static/images/Search@3x.png" className="height-36" />
                                                </a>
                                            </div>
                                            <div class="col-md-1.5 text-left">
                                                {
                                                    this.state.isDownload ?
                                                        <a class="btn downloading-button" style={{width: "130px"}}>
                                                            <i class="fa fa-spinner fa-spin mr-20px font-size-18"></i>
                                                                Downloading....
                                                        </a>
                                                        :
                                                        <a onClick={(e) => this.downloadFile()} disabled={this.state.disableButton} class="btn btn-export" style={{width: "130px"}}>
                                                            <img className="mr-20px" src="/static/images/Download_white@3x.png" height="15px" /><span className="white-color">Export Data</span>
                                                        </a>
                                                }
                                            </div> 
                                        </div>
                                        <span className="show-title mr-20px" style={{color: "red"}}>**Please insert either Car Plate Number / VIN Number / Case Number if the Exclude Date option is selected</span>
                                    </div>
                                </div>
                                                                
                                <div class="d-flex case-widget-container mb-20px">
                                    <div class="p-2  mr-5 flex-fill">
                                        <nobr>
                                            <img className="case-widget" data-toggle="tooltip" data-placement="left" title="Opened cases" src="/static/images/AssetsAA2/CaseStatus_Open.png" />
                                            <span className="case-widget-title"> Open Case</span>
                                        </nobr>
                                    </div>
                                    <div class="p-2  mr-5 flex-fill">
                                        <nobr>
                                            <img className="case-widget" data-toggle="tooltip" data-placement="left" title="Unverified cases" src="/static/images/AssetsAA2/CaseStatus_Pending.png" />
                                            <span className="case-widget-title"> Unverified Case</span>
                                        </nobr>
                                    </div>
                                    <div class="p-2  mr-5 flex-fill">
                                        <nobr>
                                            <img className="case-widget" data-toggle="tooltip" data-placement="left" title="Cancelled cases" src="/static/images/AssetsAA2/CaseStatus_Canceled.png" />
                                            <span className="case-widget-title"> Cancelled Case</span>
                                        </nobr>
                                    </div>
                                    <div class="p-2  mr-5 flex-fill">
                                        <nobr>
                                            <img className="case-widget" data-toggle="tooltip" data-placement="left" title="Closed cases" src="/static/images/AssetsAA2/CaseStatus_Closed.png" />
                                            <span className="case-widget-title"> Closed Case</span>
                                        </nobr>
                                    </div>
                                </div>                                
                                {
                                    this.state.loading ?
                                        <div class="row" height="100vh">
                                            <div class="col-md-3">
                                            </div>
                                            <div class="col-md-6">
                                                <center>
                                                    <div class="spinner-border loading-section" role="status">
                                                    </div>
                                                </center>
                                            </div>
                                            <div class="col-md-3">
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {this.state.claimsArray.length > 0
                                                ?
                                                <>
                                                    <div class="row cases-section">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive p-3">
                                                                <table class="table table-hover case-table">
                                                                    <thead>
                                                                        <tr className="case-table-header" style={{cursor: "default"}}>
                                                                            <th width="3%"></th>
                                                                            <th width="3%"></th>
                                                                            <th width="12%"><nobr>DATE & TIME (TEST)</nobr></th>
                                                                            <th width="15%"><nobr>PRODUCT NAME</nobr></th>
                                                                            <th width="10%" align="center"><nobr>INCOMING REQUEST CHANNEL</nobr></th>
                                                                            <th width="12%"><nobr>POLICY NO./ VIN NO.</nobr></th>
                                                                            <th width="11%"><nobr>CAR PLATE NO.</nobr></th>
                                                                            <th width="17%"><nobr>CAR MODEL/MAKE</nobr></th>
                                                                            <th width="10%"><nobr>CATEGORY</nobr></th>
                                                                            <th width="15%"><nobr>NO. OF SERVICES PROVIDED</nobr></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.claimsArray.length > 0 ?
                                                                                this.state.claimsArray.map((ev, index) => {
                                                                                    return (
                                                                                        <tr onClick={() => this.onClickEachItem(ev)} className="case-table-item">
                                                                                            <td class="text-right">
                                                                                                <div className="mt-15px">{this.state.currentPage * this.state.max_results + (index + 1)}.</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    ev.status == "OPEN"
                                                                                                        ?
                                                                                                        <img className="case-icon" data-toggle="tooltip" data-placement="left" title="Opened cases" src="/static/images/AssetsAA2/CaseStatus_Open.png" />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    ev.status == "CLOSED"
                                                                                                        ?
                                                                                                        <img className="case-icon" data-toggle="tooltip" data-placement="left" title="Cancelled cases" src="/static/images/AssetsAA2/CaseStatus_Closed.png" />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    ev.status == "CANCELLED"
                                                                                                        ?
                                                                                                        <img className="case-icon" data-toggle="tooltip" data-placement="left" title="Cancelled cases" src="/static/images/AssetsAA2/CaseStatus_Canceled.png" />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {
                                                                                                    ev.status == "UNVERIFIED"
                                                                                                        ?
                                                                                                        <img className="case-icon" data-toggle="tooltip" data-placement="left" title="Unverified cases" src="/static/images/AssetsAA2/CaseStatus_Pending.png" />
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="case-table-cell">
                                                                                                    {Moment(ev.opened_at).format("DD MMM yyyy")}<br />{Moment(ev.opened_at).format("hh:mm a").toUpperCase()}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="case-table-cell">
                                                                                                    {ev.product_name ? ev.product_name.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="case-table-cell">
                                                                                                    {ev.eFcInTypeA ? ev.eFcInTypeA.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="case-table-cell">
                                                                                                    {ev.policy_number ? ev.policy_number.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="case-table-cell">
                                                                                                    {ev.vehicle.registration_number ? ev.vehicle.registration_number.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="ellipsis-cell case-table-cell">
                                                                                                    {ev.vehicle.model ? ev.vehicle.model.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="ellipsis-cell case-table-cell">
                                                                                                    {ev.cause_code ? ev.cause_code.toUpperCase() : "---"}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td align="center">
                                                                                                <div className="case-table-cell">
                                                                                                    {ev.numberOfServicesProvided}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                                :
                                                                                null
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div class="card-footer clearfix" style={{cursor: "default"}}>
                                                                    <ul class="pagination pagination-sm m-0 float-left">
                                                                        {
                                                                            this.state.total <= this.state.max_results ?
                                                                                <li class="page-item total-page">
                                                                                    1-{this.state.total} of {this.state.total} items
                                                                                </li>
                                                                                :
                                                                                <li class="page-item total-page">
                                                                                    {this.state.rangeIndex} of {this.state.total} items
                                                                                </li>
                                                                        }
                                                                    </ul>
                                                                    <ReactPaginate
                                                                        previousLabel={'<'}
                                                                        nextLabel={'>'}
                                                                        breakLabel={'...'}
                                                                        breakClassName={'break-me'}
                                                                        pageCount={this.state.pageCount}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={4}
                                                                        onPageChange={this.handlePageClick}
                                                                        forcePage={this.state.currentPage}
                                                                        containerClassName={'pagination pagination-sm m-0 float-right mr-3'}
                                                                        pageClassName={'page-item'}
                                                                        pageLinkClassName={'page-link no-border'}
                                                                        activeClassName={'active'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div class="row empty-section">
                                                    <div class="col-md-12 text-center">
                                                        <div className="br-22px">
                                                            <div class="text-center case-main-infor">
                                                                No cases to display.
                                                                </div>
                                                            <div class="text-center case-sub-info">
                                                                Try broadening your search filter or show cases for a different month to view other records.
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                }
                            </Layout>
                        </>
                        :
                        null
                }
            </div>
        );
    }
}
export default withRouter(Cases)
